import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
  Tooltip,
  Pagination
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  Edit,
  Search,
  Delete,
  Sort,
  FilterList,
  Download,
  Info,
  MyLocation,
  ArrowDropDown,
  Padding,
  QrCode as QrCodeIcon,
  OpenInNew,
  ViewList,
  Person,
  Group
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import axiosInstance from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { generateQuotePDF } from '../utils/pdfGenerator';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import UserChip from './UserChip';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

interface Service {
  name: string;
  description: string;
  fineText?: string;
}

interface Quote {
  quoteId: string;
  templateId: string;
  templateName: string;
  companyName: string;
  customerName: string;
  services: string[];
  serviceValues: string[];
  totalValue: number;
  status: 'Active' | 'Accepted';
  activatedTime: string;
  acceptedTime: string;
  location?: { latitude: number; longitude: number };
  phoneNumber?: string;
  qrUrl: string;
  activatedByUserName: string;
  activatedByUserId: string;
}

interface QuoteTemplate {
  name: string;
  introParagraph: string;
  customServices: Service[];
  predefinedServiceIds: string[];
  companyName: string;
  customization: {
    primaryColor: string;
    secondaryColor: string;
    fontFamily: string;
    layout: 'list' | 'grid';
    showLogo: boolean;
    showIntro: boolean;
  };
  landingPageIntro?: string;
  daysToExpire: number;
}

interface Customer {
  quoteId: string;
  addressLocation?: {
    latitude: number;
    longitude: number;
  };
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  signature: string;
}

interface CompanySettings {
  companyName: string;
  companyPhoneNumber: string;
  companyLogo: string;
}

interface UserData {
  userId: string;
  name: string;
  email: string;
  companyId: string;
  companyName: string;
  role: string;
}

interface UserColor {
  userId: string;
  userColor: string;
}

interface QuoteLocationInfo {
  location: { latitude: number; longitude: number };
  address?: string;
}

// Updated date filter options with "This Past Week" as default
const dateFilterOptions = [
  { label: 'This Past Week', value: 7 },
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 60 days', value: 60 },
  { label: 'Last 90 days', value: 90 },
  { label: 'Last 180 days', value: 180 },
  { label: 'Last Year', value: 365 },
  { label: 'All Time', value: null },
];

const viewStatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Viewed', value: 'viewed' },
  { label: 'Not Viewed', value: 'not_viewed' },
];

const Quotes: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [allQuotes, setAllQuotes] = useState<Quote[]>([]);
  const [filteredQuotes, setFilteredQuotes] = useState<Quote[]>([]);
  const [inputValue, setInputValue] = useState<string>(''); // Immediate input value
  const [searchTerm, setSearchTerm] = useState<string>(''); // Debounced search term
  const [sortBy, setSortBy] = useState<'date' | 'value'>('date');
  const [selectedQuote, setSelectedQuote] = useState<Quote | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [companySettings, setCompanySettings] = useState<CompanySettings | null>(null);
  // Set default view filter to "viewed" instead of "all"
  const [isViewedFilter, setIsViewedFilter] = useState<'all' | 'viewed' | 'not_viewed'>('viewed');

  const [quoteViewMode, setQuoteViewMode] = useState<'my-quotes' | 'all-cowboys'>('my-quotes');
  const [userColors, setUserColors] = useState<{ [key: string]: string }>({});
  // **Add state for predefinedServices**
  const [predefinedServices, setPredefinedServices] = useState<Service[]>([]);

  const [mapCenter, setMapCenter] = useState<[number, number]>([35.227085, -80.843124]); // Default to Charlotte, NC
  const [mapZoom, setMapZoom] = useState(12);

  const navigate = useNavigate();

  // Customer data cache to avoid multiple fetches
  const [customerDataCache, setCustomerDataCache] = useState<{ [key: string]: Customer }>({});

  const [quoteLocations, setQuoteLocations] = useState<{
    [key: string]: QuoteLocationInfo;
  }>({});

  // Date filter state: default preset is this past week (7 days)
  const [dateFilter, setDateFilter] = useState<number | null>(7);
  // State for custom date range: [startDate, endDate]
  const [customRange, setCustomRange] = useState<[Date | null, Date | null]>([null, null]);
  // Anchor for the date filter menu
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);

  // **Add state for View Status filter menu**
  const [viewStatusAnchorEl, setViewStatusAnchorEl] = useState<null | HTMLElement>(null);

  // New pagination states (pageSize now accepts a number or the string "all")
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number | 'all'>(10);
  const [totalPages, setTotalPages] = useState<number>(0);

  // State for Custom Range Dialog
  const [isCustomRangeDialogOpen, setIsCustomRangeDialogOpen] = useState<boolean>(false);

  // Reset current page to 1 when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab, dateFilter, customRange, isViewedFilter, quoteViewMode, pageSize]);

  // 1. Define the search handler
  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
    },
    []
  );

  // 2. Memoize the debounced function
  const debouncedSetSearchTerm = useCallback(
    debounce(handleSearchChange, 300),
    [handleSearchChange]
  );

  // 3. Cleanup the debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSetSearchTerm.cancel();
    };
  }, [debouncedSetSearchTerm]);

  useEffect(() => {
    const fetchCompanySettings = async () => {
      try {
        const response = await axiosInstance.get('/api/compSettings');
        setCompanySettings(response.data);
      } catch (error) {
        console.error('Error fetching company settings:', error);
        enqueueSnackbar('Failed to fetch company settings', { variant: 'error' });
      }
    };

    fetchCompanySettings();
  }, [enqueueSnackbar]);

  const handleDownloadPDF = async (quote: Quote) => {
    if (!quote || !companySettings) {
      enqueueSnackbar('Unable to download PDF. Missing quote or company data.', { variant: 'error' });
      return;
    }
    try {
      // Determine the viewport ratio
      const viewportRatio = window.devicePixelRatio || 1;

      // Fetch the quote template
      const templateResponse = await axiosInstance.get(`/api/quoteTemplates/${quote.templateId}`);
      const template: QuoteTemplate = templateResponse.data;

      // Fetch the customer data
      const customerData = await fetchCustomerData(quote.quoteId);

      if (!customerData || !customerData.signature) {
        enqueueSnackbar('Unable to download PDF. Customer signature is missing.', { variant: 'error' });
        return;
      }

      const companyLogoUrl = companySettings.companyLogo
        ? `${companySettings.companyLogo.replace(/^\//, '')}`
        : undefined;

      console.log('logo url -', companyLogoUrl);

      // **Fetch Predefined Services Within the Function**
      const predefinedServicesResponse = await axiosInstance.get('/api/predefinedServices');
      const predefinedServices: Service[] = predefinedServicesResponse.data;

      // **Pass predefinedServices to generateQuotePDF**
      const pdfBlob = await generateQuotePDF(quote, template, customerData, viewportRatio, companyLogoUrl, predefinedServices);
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `quote_${quote.quoteId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      enqueueSnackbar('PDF downloaded successfully', { variant: 'success' });
    } catch (error: any) {
      console.error('Error generating PDF:', error);
      enqueueSnackbar('Failed to generate PDF. Please try again.', { variant: 'error' });
    }
  };

  const handleViewQuote = (quote: Quote) => {
    navigate(`../internal/quote/${quote.quoteId}`);
  };

  // Fetch customer data by quoteId and cache it
  const fetchCustomerData = useCallback(
    async (quoteId: string): Promise<Customer | null> => {
      if (customerDataCache[quoteId]) {
        return customerDataCache[quoteId];
      }
      if (!companySettings) {
        console.error('Company settings not available');
        return null;
      }
      try {
        const response = await axiosInstance.get(
          `/api/customers/customerByQuote/${quoteId}?companyName=${encodeURIComponent(companySettings.companyName)}`
        );
        const customerData: Customer = response.data;
        setCustomerDataCache((prev) => ({ ...prev, [quoteId]: customerData }));
        return customerData;
      } catch (error) {
        console.error(`Error fetching customer data for quote ${quoteId}:`, error);
        return null;
      }
    },
    [customerDataCache, companySettings]
  );

  // Consolidated useEffect to fetch quotes when activeTab, dateFilter, customRange, isViewedFilter, pagination, or view mode changes
  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const userDataString = localStorage.getItem('userData');
        if (!userDataString) {
          enqueueSnackbar('User data not found', { variant: 'error' });
          return;
        }

        const userData: UserData = JSON.parse(userDataString);
        if (!userData.userId) {
          enqueueSnackbar('User ID not found', { variant: 'error' });
          return;
        }

        // Determine current status based on activeTab
        const currentStatus: 'Active' | 'Accepted' = activeTab === 0 ? 'Active' : 'Accepted';

        // Calculate date range based on the selected filter
        let startDate: string | null = null;
        let endDate: string | null = null;
        if (customRange[0] && customRange[1]) {
          startDate = dayjs(customRange[0]).format('YYYY-MM-DD');
          endDate = dayjs(customRange[1]).format('YYYY-MM-DD');
        } else if (dateFilter !== null) {
          startDate = dayjs().subtract(dateFilter, 'day').format('YYYY-MM-DD');
          endDate = dayjs().format('YYYY-MM-DD');
        }

        // Determine dateField based on status
        const dateField = currentStatus === 'Active' ? 'activatedTime' : 'acceptedTime';

        // Prepare query parameters
        const params: any = {
          viewMode: quoteViewMode,
          page: currentPage,
          limit: pageSize,
          status: currentStatus
        };
        if (startDate) {
          params.startDate = startDate;
          params.endDate = endDate;
          params.dateField = dateField;
        }
        // Include isViewed filter if not "all"
        if (isViewedFilter === 'viewed' || isViewedFilter === 'not_viewed') {
          params.isViewed = isViewedFilter;
        }

        // Fetch quotes with pagination and filters
        const quotesResponse = await axiosInstance.get(`/api/quotes/all/${userData.userId}`, {
          params,
        });

        // Expecting API to return { quotes: Quote[], totalPages: number }
        setAllQuotes(quotesResponse.data.quotes);
        setTotalPages(quotesResponse.data.totalPages);

        // Fetch user colors after getting quotes if in "all-cowboys" mode
        if (quoteViewMode === 'all-cowboys') {
          await fetchUserColors(quotesResponse.data.quotes);
        }
      } catch (error) {
        console.error('Error fetching quotes:', error);
        enqueueSnackbar('Failed to fetch quotes', { variant: 'error' });
      }
    };

    fetchQuotes();
  }, [activeTab, dateFilter, customRange, isViewedFilter, quoteViewMode, currentPage, pageSize, enqueueSnackbar]);

  useEffect(() => {
    filterAndSortQuotes();
  }, [allQuotes, searchTerm, sortBy]);

  // Fetch locations for quotes and update map center
  useEffect(() => {
    const fetchLocations = async () => {
      const locationsPromises = filteredQuotes.map(async (quote) => {
        let location = quote.location;
        let address = '';
        if (quote.status === 'Accepted') {
          const customer = await fetchCustomerData(quote.quoteId);
          if (customer?.addressLocation) {
            location = customer.addressLocation;
            address = `${customer.address}, ${customer.city}, ${customer.state} ${customer.zip}`;
          } else if (!location) {
            return null;
          }
        }
        return { quoteId: quote.quoteId, location, address };
      });

      const locationsArray = await Promise.all(locationsPromises);
      const locationsMap = locationsArray.reduce((acc, item) => {
        if (item && item.location) {
          acc[item.quoteId] = { location: item.location, address: item.address };
        }
        return acc;
      }, {} as { [key: string]: QuoteLocationInfo });

      setQuoteLocations(locationsMap);

      // Update map center
      const locations = Object.values(locationsMap).map((item) => item.location);
      if (locations.length > 0) {
        const avgLat = locations.reduce((sum, loc) => sum + loc.latitude, 0) / locations.length;
        const avgLng = locations.reduce((sum, loc) => sum + loc.longitude, 0) / locations.length;
        setMapCenter([avgLat, avgLng]);
        setMapZoom(12);
      }
    };

    fetchLocations();
  }, [filteredQuotes, fetchCustomerData]);

  // Custom icon for map markers
  // Updated to accept an optional userColors object so that when in "all-cowboys" mode the marker uses the user's color.
  const getCustomIcon = (quote: Quote, userColorsObj?: { [key: string]: string }) => {
    const userColor = userColorsObj && userColorsObj[quote.activatedByUserId] ? userColorsObj[quote.activatedByUserId] : '#666';
    const markerSvg = encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path fill="${userColor}" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"/>
        <circle cx="192" cy="192" r="96" fill="white" opacity="0.9"/>
      </svg>
    `);
    const iconUrl = `data:image/svg+xml;charset=utf-8,${markerSvg}`;
    return new L.Icon({
      iconUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
      shadowSize: [41, 41],
    });
  };

  const filterAndSortQuotes = () => {
    // Determine status based on activeTab
    const statusFilter = activeTab === 0 ? 'Active' : 'Accepted';

    // Filter quotes by status
    let filtered = allQuotes.filter((quote) => quote.status === statusFilter);

    // Apply search filtering
    if (searchTerm) {
      filtered = filtered.filter((quote) => {
        // Define search fields based on activeTab
        const searchField = activeTab === 0 ? quote.templateName : quote.customerName;

        const matchesSearchField = searchField
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ?? false;

        const matchesQuoteId = quote.quoteId
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ?? false;

        if (activeTab === 0 && !quote.templateName) {
          console.warn(`Quote with ID ${quote.quoteId} is missing templateName.`);
        }
        if (activeTab === 1 && !quote.customerName) {
          console.warn(`Quote with ID ${quote.quoteId} is missing customerName.`);
        }

        return matchesSearchField || matchesQuoteId;
      });
    }

    // Apply sorting
    filtered.sort((a, b) => {
      if (sortBy === 'date') {
        const timeA = activeTab === 0 ? a.activatedTime : a.acceptedTime;
        const timeB = activeTab === 0 ? b.activatedTime : b.acceptedTime;
        return new Date(timeB).getTime() - new Date(timeA).getTime();
      } else {
        return b.totalValue - a.totalValue;
      }
    });

    setFilteredQuotes(filtered);
  };

  // For unique user IDs, modify the fetchUserColors function:
  const fetchUserColors = async (quotes: Quote[]) => {
    try {
      const uniqueUserIds = Array.from(new Set(quotes.map(quote => quote.activatedByUserId)));
      const response = await axiosInstance.post('/api/users/colors', {
        userIds: uniqueUserIds
      });
      const colorMap = response.data.reduce((acc: { [key: string]: string }, user: UserColor) => {
        acc[user.userId] = user.userColor;
        return acc;
      }, {});
      setUserColors(colorMap);
    } catch (error) {
      console.error('Error fetching user colors:', error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSortChange = () => {
    setSortBy((prevSort) => (prevSort === 'date' ? 'value' : 'date'));
  };

  const handleEditQuote = (quote: Quote) => {
    console.log('Edit quote:', quote);
  };

  const handleDeleteQuote = (quote: Quote) => {
    setSelectedQuote(quote);
    setIsDeleteDialogOpen(true);
  };

  const confirmDeleteQuote = async () => {
    if (selectedQuote) {
      try {
        await axiosInstance.delete(`/api/quotes/${selectedQuote.quoteId}`);
        setAllQuotes(allQuotes.filter((q) => q.quoteId !== selectedQuote.quoteId));
        enqueueSnackbar('Quote deleted successfully', { variant: 'success' });
      } catch (error) {
        console.error('Error deleting quote:', error);
        enqueueSnackbar('Failed to delete quote', { variant: 'error' });
      }
    }
    setIsDeleteDialogOpen(false);
  };

  const handleCenterMapOnQuote = async (quote: Quote) => {
    let locationInfo = quoteLocations[quote.quoteId];
    if (!locationInfo) {
      if (quote.status === 'Accepted') {
        const customer = await fetchCustomerData(quote.quoteId);
        if (customer?.addressLocation) {
          locationInfo = {
            location: customer.addressLocation,
            address: `${customer.address}, ${customer.city}, ${customer.state} ${customer.zip}`,
          };
        } else if (quote.location) {
          locationInfo = { location: quote.location };
        } else {
          enqueueSnackbar('Location data not available for this quote', { variant: 'warning' });
          return;
        }
      } else {
        if (quote.location) {
          locationInfo = { location: quote.location };
        } else {
          enqueueSnackbar('Location data not available for this quote', { variant: 'warning' });
          return;
        }
      }
      setQuoteLocations((prev) => ({
        ...prev,
        [quote.quoteId]: locationInfo,
      }));
    }

    const { location } = locationInfo;
    setMapCenter([location.latitude, location.longitude]);
    setMapZoom(40);
  };

  // Handlers for Date Range Filter
  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleDateFilterChange = (value: number | null) => {
    setDateFilter(value);
    setCustomRange([null, null]);
    setFilterAnchorEl(null);
  };

  // Handlers for View Status Filter
  const handleViewStatusFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setViewStatusAnchorEl(event.currentTarget);
  };

  const handleViewStatusFilterClose = () => {
    setViewStatusAnchorEl(null);
  };

  const handleViewStatusFilterChange = (value: 'all' | 'viewed' | 'not_viewed') => {
    setIsViewedFilter(value);
    setViewStatusAnchorEl(null);
  };

  const handleViewCustomerQuote = (quoteId: string, companyName: string) => {
    window.open(`/quote/${quoteId}/${companyName}`, '_blank');
  };

  const renderQuoteItem = (quote: Quote) => (
    <ListItem
      key={quote.quoteId}
      divider
      sx={{
        py: 2,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
    >
      <ListItemText
        disableTypography
        primary={
          <Typography variant="subtitle1" fontWeight="bold">
            {quote.customerName}
          </Typography>
        }
        secondary={
          <Box>
            <Typography variant="body2" color="text.secondary">
              {quote.templateName} - {activeTab === 0 ? 'Active' : 'Accepted'} -{' '}
              {new Date(activeTab === 0 ? quote.activatedTime : quote.acceptedTime).toLocaleDateString()}
            </Typography>
            <Box mt={1} display="flex" gap={1} alignItems="center">
              {quoteViewMode === 'all-cowboys' && (
                <UserChip
                  userId={quote.activatedByUserId}
                  userName={quote.activatedByUserName}
                  userColor={userColors[quote.activatedByUserId] || '#666'}
                />
              )}
              <Chip label={`$${quote.totalValue.toFixed(2)}`} color="info" size="small" />
            </Box>
          </Box>
        }
      />
      <ListItemSecondaryAction>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            height: '100%',
            '& > *': {
              paddingTop: '30px',
              margin: '0 0px',
            },
          }}
        >
          <Box>
            {activeTab === 1 && (
              <>
                <IconButton size="small" onClick={() => handleViewCustomerQuote(quote.quoteId, quote.companyName)} sx={{ width: 30, mt: 4 }}>
                  <OpenInNew fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => handleCenterMapOnQuote(quote)} sx={{ width: 30, mt: 4 }}>
                  <MyLocation fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => handleDownloadPDF(quote)} sx={{ width: 30, mt: 4 }}>
                  <Download fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => handleViewQuote(quote)} sx={{ width: 30, mt: 4 }}>
                  <Info fontSize="small" />
                </IconButton>
              </>
            )}
            {activeTab === 0 && (
              <>
                <IconButton size="small" onClick={() => handleViewCustomerQuote(quote.quoteId, quote.companyName)} sx={{ width: 30 }}>
                  <OpenInNew fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => handleCenterMapOnQuote(quote)} sx={{ width: 30 }}>
                  <MyLocation fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => handleViewQuote(quote)} sx={{ width: 30 }}>
                  <Info fontSize="small" />
                </IconButton>
                <Tooltip
                  title={
                    <img
                      src={quote.qrUrl}
                      alt="QR Code"
                      style={{ width: '150px', height: '150px' }}
                    />
                  }
                  PopperProps={{
                    style: { zIndex: 10000 },
                  }}
                >
                  <IconButton size="small" sx={{ width: 30 }}>
                    <QrCodeIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );

  // Determine label for Date Filter button
  const selectedDateFilterLabel = (customRange[0] && customRange[1])
    ? `${dayjs(customRange[0]).format('MM/DD/YYYY')} - ${dayjs(customRange[1]).format('MM/DD/YYYY')}`
    : dateFilterOptions.find((option) => option.value === dateFilter)?.label;

  const selectedViewStatusLabel = viewStatusOptions.find(
    (option) => option.value === isViewedFilter
  )?.label;

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', bgcolor: 'background.default' }}>
      {/* Sidebar Container */}
      <Box
        sx={{
          width: '30%',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4">Quotes</Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: 200 }}
            onClick={() => setQuoteViewMode(prev => prev === 'my-quotes' ? 'all-cowboys' : 'my-quotes')}
            startIcon={quoteViewMode === 'my-quotes' ? <Person /> : <Group />}
          >
            {quoteViewMode === 'my-quotes' ? 'My Quotes' : 'All Cowboys'}
          </Button>
        </Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{
            mb: 2,
            minHeight: 'unset',
            '& .MuiTabs-flexContainer': { justifyContent: 'space-between' },
            '& .MuiTab-root': { minWidth: 'unset', padding: '8px 8px', fontSize: '1rem', minHeight: 'unset', flex: 1 },
            '& .MuiTabs-indicator': { backgroundColor: 'warning.main' },
          }}
          textColor="inherit"
        >
          <Tab
            label="Active"
            sx={(theme) => ({
              color: 'warning.main',
              '&.Mui-selected': { color: 'warning.main' },
              '&:hover': {
                color: alpha(theme.palette.common.white, 1),
                backgroundColor: alpha(theme.palette.warning.main, 0.8),
                cursor: 'pointer',
              },
            })}
          />
          <Tab
            label="Accepted"
            sx={(theme) => ({
              color: 'warning.main',
              '&.Mui-selected': { color: 'warning.main' },
              '&:hover': {
                color: alpha(theme.palette.common.white, 1),
                backgroundColor: alpha(theme.palette.warning.main, 0.8),
                cursor: 'pointer',
              },
            })}
          />
        </Tabs>
        <TextField
          placeholder="Search quotes..."
          variant="outlined"
          size="small"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            debouncedSetSearchTerm(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          fullWidth
          sx={{ mb: 2 }}
        />
        {/* Filters Container */}
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Button
            variant="contained"
            color="info"
            startIcon={<FilterList />}
            endIcon={<ArrowDropDown />}
            size="small"
            onClick={handleFilterClick}
          >
            {selectedDateFilterLabel}
          </Button>
          <Menu anchorEl={filterAnchorEl} open={Boolean(filterAnchorEl)} onClose={handleFilterClose}>
            {dateFilterOptions.map((option) => (
              <MenuItem
                key={option.label}
                selected={dateFilter === option.value && !customRange[0] && !customRange[1]}
                onClick={() => handleDateFilterChange(option.value)}
              >
                {option.label}
              </MenuItem>
            ))}
            <MenuItem
              key="Custom Range"
              onClick={() => {
                if (!customRange[0] || !customRange[1]) {
                  setCustomRange([dayjs().subtract(7, 'day').toDate(), new Date()]);
                }
                setIsCustomRangeDialogOpen(true);
                setFilterAnchorEl(null);
              }}
            >
              Custom Range
            </MenuItem>
          </Menu>
          <Button
            variant="contained"
            color="info"
            startIcon={<FilterList />}
            endIcon={<ArrowDropDown />}
            size="small"
            onClick={handleViewStatusFilterClick}
          >
            {`View Status: ${selectedViewStatusLabel}`}
          </Button>
          <Menu anchorEl={viewStatusAnchorEl} open={Boolean(viewStatusAnchorEl)} onClose={handleViewStatusFilterClose}>
            {viewStatusOptions.map((option) => (
              <MenuItem
                key={option.label}
                selected={isViewedFilter === option.value}
                onClick={() => handleViewStatusFilterChange(option.value as 'all' | 'viewed' | 'not_viewed')}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Button
          variant="contained"
          color="info"
          startIcon={<Sort />}
          onClick={handleSortChange}
          size="small"
          fullWidth
          sx={{ mb: 1 }}
        >
          Sort by {sortBy === 'date' ? 'Date' : 'Value'}
        </Button>
        {/* Page Size Selector */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <Typography variant="body2">Quotes per page:</Typography>
          <TextField
            select
            value={pageSize}
            onChange={(e) => {
              const value = e.target.value;
              if (value === 'all') {
                setPageSize('all');
              } else {
                setPageSize(parseInt(value, 10));
              }
              setCurrentPage(1);
            }}
            size="small"
            variant="outlined"
          >
            {[5, 10, 25, 50, 100, 'all'].map(option => (
              <MenuItem key={option} value={option}>
                {option === 'all' ? 'All' : option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={{ flex: 1, overflowY: 'auto' }}>
          <List>
            {filteredQuotes.length > 0 ? (
              filteredQuotes.map(renderQuoteItem)
            ) : (
              <ListItem>
                <ListItemText primary="No quotes found" />
              </ListItem>
            )}
          </List>
        </Box>
        {/* Pagination Controls */}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            color="secondary"
            size="small"
          />
        </Box>
        {/* View All Quotes Button */}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="warning"
            size="small"
            startIcon={<ViewList />}
            onClick={() => navigate('/dashboard/all-quotes')}
            fullWidth
          >
            View All Quotes
          </Button>
        </Box>
      </Box>

      {/* Map Container */}
      <Box sx={{ width: '70%', p: 2 }}>
        {Object.keys(quoteLocations).length > 0 ? (
          <MapContainer center={mapCenter} zoom={mapZoom} style={{ height: '100%', width: '100%' }}>
            <MapUpdater center={mapCenter} zoom={mapZoom} />
            <TileLayer url={theme.palette.mode === 'dark' ? 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png' : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'} attribution={theme.palette.mode === 'dark' ? '&copy; <a href="https://carto.com/attributions">CARTO</a> | Geocoding by <a href="https://locationiq.com/">LocationIQ</a>' : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Geocoding by <a href="https://locationiq.com/">LocationIQ</a>'} />
            {filteredQuotes.map((quote) => {
              const locationInfo = quoteLocations[quote.quoteId];
              if (!locationInfo) return null;
              const { location, address } = locationInfo;
              return (
                <Marker key={quote.quoteId} position={[location.latitude, location.longitude]} icon={getCustomIcon(quote, userColors)}>
                  <Popup>
                    <Typography variant="subtitle2">{quote.customerName}</Typography>
                    {quoteViewMode === 'all-cowboys' && (
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', my: 1 }}>
                        <UserChip
                          userId={quote.activatedByUserId}
                          userName={quote.activatedByUserName}
                          userColor={userColors[quote.activatedByUserId] || '#666'}
                        />
                      </Box>
                    )}
                    <Typography variant="body2">{quote.templateName}</Typography>
                    <Typography variant="body2">${quote.totalValue.toFixed(2)}</Typography>
                    {address && <Typography variant="body2">Address: {address}</Typography>}
                    <Button variant="contained" onClick={() => handleViewQuote(quote)} color="secondary" sx={{ mt: 2 }}>
                      View Quote
                    </Button>
                  </Popup>
                </Marker>
              );
            })}
          </MapContainer>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography>No location data available for current quotes</Typography>
          </Box>
        )}
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Delete Quote</DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDeleteQuote} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Custom Range Dialog */}
      <Dialog open={isCustomRangeDialogOpen} onClose={() => setIsCustomRangeDialogOpen(false)}>
        <DialogTitle>Custom Date Range</DialogTitle>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={customRange[0] ? dayjs(customRange[0]).format('YYYY-MM-DD') : ''}
            onChange={(e) => {
              const newStart = e.target.value ? new Date(e.target.value) : null;
              setCustomRange([newStart, customRange[1]]);
            }}
            sx={(theme) => ({
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: alpha(theme.palette.warning.main, 0.8),
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'warning.main',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'warning.main',
                '&.Mui-focused': {
                  color: 'warning.main',
                },
              },
            })}
          />
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={customRange[1] ? dayjs(customRange[1]).format('YYYY-MM-DD') : ''}
            onChange={(e) => {
              const newEnd = e.target.value ? new Date(e.target.value) : null;
              setCustomRange([customRange[0], newEnd]);
            }}
            sx={(theme) => ({
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: alpha(theme.palette.warning.main, 0.8),
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'warning.main',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'warning.main',
                '&.Mui-focused': {
                  color: 'warning.main',
                },
              },
            })}
          />
        </Box>
        <DialogActions>
          <Button onClick={() => { setCustomRange([null, null]); setIsCustomRangeDialogOpen(false); }} color='secondary' variant='outlined'>Cancel</Button>
          <Button onClick={() => { setIsCustomRangeDialogOpen(false); }} variant='contained' color='secondary'>Apply</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Quotes;

// MapUpdater component to update map center and zoom
const MapUpdater: React.FC<{ center: [number, number]; zoom: number }> = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);
  return null;
};

// Adjust marker icons based on user colors; updated to accept an optional userColors object.
const getCustomIcon = (quote: Quote, userColorsObj?: { [key: string]: string }) => {
  const userColor = userColorsObj && userColorsObj[quote.activatedByUserId] ? userColorsObj[quote.activatedByUserId] : '#666';
  const markerSvg = encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path fill="${userColor}" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"/>
        <circle cx="192" cy="192" r="96" fill="white" opacity="0.9"/>
      </svg>
    `);
  const iconUrl = `data:image/svg+xml;charset=utf-8,${markerSvg}`;
  return new L.Icon({
    iconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
  });
};