import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Button, Grid, Card, CardContent, IconButton, Modal,
  TextField, Tabs, Tab, Accordion, AccordionSummary, AccordionDetails,
  Switch, FormControlLabel, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Checkbox, Tooltip, Autocomplete, autocompleteClasses, ToggleButtonGroup, ToggleButton
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  ArrowBack, Edit, Language, Print, Add, InsertDriveFile, Close, Delete,
  ExpandMore, Palette, TextFields, ViewModule, Code, DisplaySettings,
  Visibility, OpenInNew, QrCode as QrCodeIcon, Info, VisibilityOff, InsertPhoto,
  ViewList
} from '@mui/icons-material';
import CustomDropdown from './CustomDropdown';
import DropdownMenu from './DropdownMenu';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { ColorLens } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { useRemainingQuotes } from '../RemainingQuotesContext';

interface Quote {
  quoteId: string;
  userId: string;
  templateId: string;
  services: string[];
  serviceValues: string[];
  location: string;
  time: Date;
  activatedTime?: Date | null;
  expirationTime?: Date | null;
  totalValue: number;
  status: 'Idle' | 'Active' | 'Accepted' | 'Rejected';
  qrUrl: string;
  companyName: string;
  isViewed: boolean;
  firstViewedAt?: string | null;
  activatedByUserId?: string;
  activatedByUserName?: string;
}

interface Service {
  _id?: string;
  name: string;
  displayName?: string;
  description: string;
  fineText?: string;
}

interface QuoteTemplate {
  templateId: string;
  name: string;
  introParagraph: string;
  customServices: Service[];
  predefinedServiceIds: string[];
  companyName: string;
  createdBy: string;
  qrUrl: string;
  createdAt: string;
  customization: {
    primaryColor: string;
    secondaryColor: string;
    fontFamily: string;
    layout: 'list' | 'grid';
    showLogo: boolean;
    showIntro: boolean;
    showTitle: boolean;
    showDescriptionOnHanger: boolean;
    showQuoteId: boolean;
    customCSS: string;
    sticker: string;
  };
  landingPageIntro?: string;
  daysToExpire: string;
}

interface CompanySettings {
  companyName: string;
  companyPhoneNumber: string;
  companyLogo: string;
}

const Templates: React.FC = () => {
  const [templates, setTemplates] = useState<QuoteTemplate[]>([]);
  const location = useLocation();
  const userDataString = localStorage.getItem('userData');
  const user = userDataString ? JSON.parse(userDataString) : null;
  const [selectedTemplate, setSelectedTemplate] = useState<QuoteTemplate | null>(
    location.state?.selectedTemplate || null
  );

  const { refreshRemainingQuotes } = useRemainingQuotes();
  const [editingTemplate, setEditingTemplate] = useState<QuoteTemplate | null>(null);
  const [companySettings, setCompanySettings] = useState<CompanySettings | null>(null);
  const [open, setOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [editingTab, setEditingTab] = useState(0);

  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [printCount, setPrintCount] = useState(1);
  const [maxQuotes, setMaxQuotes] = useState(100);

  const [viewQuotesOpen, setViewQuotesOpen] = useState(false);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [selectedQuotes, setSelectedQuotes] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [predefinedServices, setPredefinedServices] = useState<Service[]>([]);

  // New state for layout toggle
  const [selectedLayout, setSelectedLayout] = useState<'doorhanger' | 'card'>('doorhanger');

  useEffect(() => {
    fetchTemplates();
    fetchCompanySettings();
    fetchPredefinedServices();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axiosInstance.get('/api/quoteTemplates');
      const templatesWithCustomization = response.data.map((template: QuoteTemplate) => ({
        ...template,
        customization: template.customization || {
          primaryColor: '#000000',
          secondaryColor: '#f37121',
          fontFamily: 'Poppins',
          layout: 'list',
          showLogo: true,
          showIntro: false,
          showTitle: false,
          showDescriptionOnHanger: true,
          showQuoteId: true,
          customCSS: '',
          sticker: '',
        }
      }));
      setTemplates(templatesWithCustomization);
      if (selectedTemplate) {
        const updatedTemplate = templatesWithCustomization.find((t: QuoteTemplate) => t.templateId === selectedTemplate.templateId);
        if (updatedTemplate) {
          setSelectedTemplate(updatedTemplate);
        }
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const fetchPredefinedServices = async () => {
    try {
      const response = await axiosInstance.get('/api/predefinedServices');
      setPredefinedServices(response.data);
    } catch (error) {
      console.error('Error fetching predefined services:', error);
      enqueueSnackbar('Error fetching predefined services', { variant: 'error' });
    }
  };

  const fetchCompanySettings = async () => {
    try {
      const response = await axiosInstance.get('/api/compSettings');
      setCompanySettings(response.data);
    } catch (error) {
      console.error('Error fetching company settings:', error);
    }
  };

  const handleTemplateClick = (template: QuoteTemplate) => {
    setSelectedTemplate(template);
  };

  const handleBackClick = () => {
    setSelectedTemplate(null);
  };

  const handleQuotePage = () => {
    if (selectedTemplate) {
      navigate(`/quote-template/${selectedTemplate.templateId}`);
    } else {
      enqueueSnackbar('No template selected', { variant: 'error' });
    }
  };

  const handlePrintHangers = () => {
    setPrintModalOpen(true);
  };

  const handlePrintConfirm = async () => {
    try {
      const response = await axiosInstance.post('/api/quoteTemplates/generate-quotes', {
        templateId: selectedTemplate!.templateId,
        count: printCount,
        layout: selectedLayout // Pass the selected layout to the backend
      }, {
        responseType: 'blob'
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `${selectedLayout === 'doorhanger' ? 'door_hangers' : 'cards'}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setPrintModalOpen(false);
      enqueueSnackbar('Quotes generated successfully', { variant: 'success', autoHideDuration: 1750 });

      await refreshRemainingQuotes();
    } catch (error) {
      console.error('Error generating quotes:', error);
      enqueueSnackbar('Error generating quotes', { variant: 'error' });
    }
  };

  const handleViewQuotes = async () => {
    if (!selectedTemplate) return;

    try {
      let url = `/api/quotes/byTemplate/${selectedTemplate.templateId}`;
      if (user?.role === 'User') {
        url = `/api/quotes/byTemplateAndUser/${selectedTemplate.templateId}/${user.userId}`;
      }

      const response = await axiosInstance.get(url);
      setQuotes(response.data);
      setViewQuotesOpen(true);
    } catch (error) {
      console.error('Error fetching quotes:', error);
      enqueueSnackbar('Error fetching quotes', { variant: 'error' });
    }
  };

  const generateConcatenatedId = (quoteId: string): string => {
    const parts = quoteId.split('_');
    const userId = parts[2].slice(-2);
    const templateId = parts[3].slice(-2);
    const quoteNumber = parts[parts.length - 1];
    return `${userId}${templateId}${quoteNumber}`;
  };

  const handleReprintSelectedQuotes = async () => {
    try {
      if (selectedQuotes.length === 0) {
        enqueueSnackbar('No quotes selected for reprinting', { variant: 'warning' });
        return;
      }

      const response = await axiosInstance.post('/api/quoteTemplates/reprint-quotes', {
        quoteIds: selectedQuotes,
        templateId: selectedTemplate!.templateId
      }, {
        responseType: 'blob'
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'reprinted_quotes.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      enqueueSnackbar('Selected quotes reprinted successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error reprinting quotes:', error);
      enqueueSnackbar('Error reprinting quotes', { variant: 'error' });
    }
  };

  const handleCheckQuote = (quoteId: string) => {
    setSelectedQuotes(prev =>
      prev.includes(quoteId)
        ? prev.filter(id => id !== quoteId)
        : [...prev, quoteId]
    );
  };

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedQuotes(quotes.map(quote => quote.quoteId));
    } else {
      setSelectedQuotes([]);
    }
  };

  const handleDeleteSelectedQuotes = async () => {
    try {
      await axiosInstance.post('/api/quotes/deleteMultiple', { quoteIds: selectedQuotes });
      setQuotes(prev => prev.filter(quote => !selectedQuotes.includes(quote.quoteId)));
      setSelectedQuotes([]);
      enqueueSnackbar('Quotes deleted successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        style: { zIndex: 10000 }
      });
    } catch (error) {
      console.error('Error deleting quotes:', error);
      enqueueSnackbar('Error deleting quotes', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        style: { zIndex: 10000 }
      });
    }
  };

  const handleViewQuote = (quoteId: string, status: string) => {
    if (status !== 'Idle') {
      navigate(`../internal/quote/${quoteId}`);
    }
  };

  const handleViewCustomerQuote = (quoteId: string, companyName: string) => {
    window.open(`/quote/${quoteId}/${companyName}`, '_blank');
  };

  const [newTemplateModalOpen, setNewTemplateModalOpen] = useState(false);
  const [editTemplateModalOpen, setEditTemplateModalOpen] = useState(false);

  const handleOpenNewTemplateModal = () => {
    setEditingTemplate({
      templateId: '',
      name: '',
      introParagraph: '',
      customServices: [],
      predefinedServiceIds: [],
      companyName: '',
      createdBy: '',
      qrUrl: '',
      createdAt: '',
      customization: {
        primaryColor: '#052F46',
        secondaryColor: '#1E1F20',
        fontFamily: 'Roboto',
        layout: 'list',
        showLogo: true,
        showIntro: false,
        showTitle: false,
        showDescriptionOnHanger: true,
        showQuoteId: true,
        customCSS: '',
        sticker: '',
      },
      landingPageIntro: '',
      daysToExpire: '30',
    });
    setNewTemplateModalOpen(true);
  };

  const handleCloseNewTemplateModal = () => {
    setNewTemplateModalOpen(false);
    setEditingTemplate(null);
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const handleOpenEditTemplateModal = () => {
    if (selectedTemplate) {
      setEditingTemplate({
        ...JSON.parse(JSON.stringify(selectedTemplate)),
        customization: {
          primaryColor: selectedTemplate.customization?.primaryColor || '#000000',
          secondaryColor: selectedTemplate.customization?.secondaryColor || '#f37121',
          fontFamily: selectedTemplate.customization?.fontFamily || 'Roboto',
          layout: selectedTemplate.customization?.layout || 'list',
          showLogo: selectedTemplate.customization?.showLogo ?? true,
          showIntro: selectedTemplate.customization?.showIntro ?? false,
          showTitle: selectedTemplate.customization?.showTitle ?? false,
          showDescriptionOnHanger: selectedTemplate.customization?.showDescriptionOnHanger ?? true,
          showQuoteId: selectedTemplate.customization?.showQuoteId ?? true,
          customCSS: selectedTemplate.customization?.customCSS || '',
          sticker: selectedTemplate.customization?.sticker || '',
        },
      });
      setEditTemplateModalOpen(true);
    }
  };

  const handleCloseEditTemplateModal = () => {
    setEditTemplateModalOpen(false);
    setEditingTemplate(null);
  };

  const handleCustomizationChange = (field: string, value: any) => {
    if (editingTemplate) {
      setEditingTemplate({
        ...editingTemplate,
        customization: {
          ...editingTemplate.customization,
          [field]: field.includes('Color') ? value : value,
        },
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingTemplate) return;

    const templateData = {
      ...editingTemplate,
      customServices: editingTemplate.customServices,
      predefinedServiceIds: editingTemplate.predefinedServiceIds,
    };

    try {
      if (selectedTemplate) {
        const updatedTemplate = await axiosInstance.put(`/api/quoteTemplates/${selectedTemplate.templateId}`, templateData);
        setSelectedTemplate(updatedTemplate.data);
        enqueueSnackbar('Template updated successfully', { variant: 'success' });
      } else {
        const createdTemplate = await axiosInstance.post('/api/quoteTemplates/new', templateData);
        setSelectedTemplate(createdTemplate.data);
        enqueueSnackbar('New template created successfully', { variant: 'success' });
      }
      await fetchTemplates();
      setOpen(false);
      setEditingTemplate(null);
      handleCloseNewTemplateModal();
      handleCloseEditTemplateModal();
    } catch (error) {
      console.error('Error creating/updating template:', error);
      enqueueSnackbar('Error saving template', { variant: 'error' });
    }
  };

  const handleRemoveService = (index: number) => {
    if (editingTemplate) {
      const newServices = [...editingTemplate.customServices];
      newServices.splice(index, 1);
      setEditingTemplate({ ...editingTemplate, customServices: newServices });
    }
  };

  const handleServiceChange = (index: number, field: keyof Service, value: string) => {
    if (editingTemplate) {
      const newServices = [...editingTemplate.customServices];
      newServices[index] = { ...newServices[index], [field]: value };
      setEditingTemplate({ ...editingTemplate, customServices: newServices });
    }
  };

  const handleRemoveCustomService = (index: number) => {
    if (editingTemplate) {
      const newCustomServices = [...editingTemplate.customServices];
      newCustomServices.splice(index, 1);
      setEditingTemplate({ ...editingTemplate, customServices: newCustomServices });
    }
  };

  const handleCustomServiceChange = (index: number, field: keyof Service, value: string) => {
    if (editingTemplate) {
      const newCustomServices = [...editingTemplate.customServices];
      newCustomServices[index] = { ...newCustomServices[index], [field]: value };
      setEditingTemplate({ ...editingTemplate, customServices: newCustomServices });
    }
  };

  const handleAddCustomService = () => {
    if (editingTemplate) {
      setEditingTemplate({
        ...editingTemplate,
        customServices: [...editingTemplate.customServices, { name: '', description: '', fineText: '' }]
      });
    }
  };

  const handlePredefinedServicesChange = (event: React.SyntheticEvent, newValue: Service[]) => {
    if (editingTemplate) {
      setEditingTemplate({
        ...editingTemplate,
        predefinedServiceIds: newValue.map(service => service._id || '')
      });
    }
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedTemplate) return;

    try {
      await axiosInstance.delete(`/api/quoteTemplates/${selectedTemplate.templateId}`);
      enqueueSnackbar('Template deleted successfully', { variant: 'success' });
      setSelectedTemplate(null);
      fetchTemplates();
    } catch (error) {
      console.error('Error deleting template:', error);
      enqueueSnackbar('Error deleting template', { variant: 'error' });
    }
    setDeleteConfirmOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const calculateDaysUntilExpired = (activatedTime?: Date | null, expirationTime?: Date | null): string => {
    if (!activatedTime || !expirationTime) return 'N/A';

    const now = new Date();
    const expTime = new Date(expirationTime);
    const actTime = new Date(activatedTime);

    const referenceTime = actTime > now ? actTime : now;

    const diffTime = expTime.getTime() - referenceTime.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 0) {
      return `${diffDays} day${diffDays !== 1 ? 's' : ''} remaining`;
    } else if (diffDays === 0) {
      return 'Expires today';
    } else {
      return 'Expired';
    }
  };

  const renderSelectedTemplate = () => {
    // Calculate total services for overlay and button logic
    const totalServices = selectedTemplate!.customServices.length + selectedTemplate!.predefinedServiceIds.length;
    const previewWidth = selectedLayout === 'doorhanger' ? '3.5in' : '5.25in';
    const previewHeight = selectedLayout === 'doorhanger' ? '8in' : '4in';
    const previewMinWidth = selectedLayout === 'doorhanger' ? '3.5in' : '5.25in';

    return (
      <Box sx={{ padding: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Box sx={{ width: '48px' }}>
            <IconButton onClick={handleBackClick} sx={{width:40}}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>{selectedTemplate!.name}</Typography>
        </Box>
        <Grid container spacing={16} paddingTop={2}>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}></Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: previewMinWidth,
            paddingY: 2,
          }}>
            <ToggleButtonGroup
              value={selectedLayout}
              exclusive
              onChange={(event, newLayout) => {
                if (newLayout !== null) {
                  setSelectedLayout(newLayout);
                }
              }}
              aria-label="layout selection"
              sx={{ mb: 2 }}
            >
              <ToggleButton value="doorhanger" aria-label="doorhanger layout">
                <ViewList />
              </ToggleButton>
              <ToggleButton value="card" aria-label="card layout">
                <ViewModule />
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ position: 'relative' }}>
              <Card elevation={3} sx={{
                bgcolor: 'white',
                width: previewWidth,
                height: previewHeight,
                minWidth: previewWidth,
                minHeight: previewHeight,
                overflow: 'hidden',
                flexShrink: 0,
                margin: '0 auto',
              }}>
                <CardContent sx={{
                  width: '100%',
                  height: '100%',
                  padding: '0.25in',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  boxSizing: 'border-box',
                  overflow: 'auto',
                }}>
                  {selectedLayout === 'doorhanger' ? (
                    <>
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {companySettings?.companyLogo && selectedTemplate!.customization?.showLogo && (
                          <Box sx={{ mb: 2, maxWidth: '80%', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                              src={`${companySettings.companyLogo.replace(/^\//, '')}`}
                              alt="Company Logo"
                              style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                            />
                          </Box>
                        )}
                        {selectedTemplate!.customization?.showTitle && (
                          <Typography variant="h5" sx={{
                            color: selectedTemplate!.customization?.primaryColor,
                            fontFamily: selectedTemplate!.customization?.fontFamily,
                            fontWeight: 'bold',
                            mt: 1,
                            mb: 1,
                          }}>
                            {selectedTemplate!.name}
                          </Typography>
                        )}
                        <Typography variant="body2" sx={{
                          color: selectedTemplate!.customization?.secondaryColor,
                          fontFamily: selectedTemplate!.customization?.fontFamily,
                          textAlign: 'center',
                          mb: 2,
                        }}>
                          {selectedTemplate!.introParagraph}
                        </Typography>
                      </Box>
                      <Box sx={{ width: '100%', flexGrow: 1, overflowY: 'auto' }}>
                        <Grid container spacing={1}>
                          {selectedTemplate?.customServices?.map((service, index) => (
                            <Grid item xs={12} key={`custom-${index}`}>
                              <Box sx={{ mb: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box
                                    sx={{
                                      width: '12px',
                                      height: '12px',
                                      border: `1.5px solid ${selectedTemplate.customization?.primaryColor}`,
                                      mr: 1,
                                      flexShrink: 0,
                                    }}
                                  />
                                  <Typography variant="subtitle1" sx={{
                                    color: selectedTemplate.customization?.primaryColor,
                                    fontFamily: selectedTemplate.customization?.fontFamily,
                                    fontWeight: 'bold',
                                  }}>
                                    {service.name}
                                  </Typography>
                                </Box>
                                {selectedTemplate.customization?.showDescriptionOnHanger && (
                                  <Typography variant="body2" sx={{
                                    color: selectedTemplate.customization?.secondaryColor,
                                    fontFamily: selectedTemplate.customization?.fontFamily,
                                    pl: 3,
                                  }}>
                                    {service.description}
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                          ))}
                          {predefinedServices
                            .filter(service => selectedTemplate?.predefinedServiceIds?.includes(service._id || '') || false)
                            .map((service, index) => (
                              <Grid item xs={12} key={`predefined-${service._id}`}>
                                <Box sx={{ mb: 1 }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                      sx={{
                                        width: '12px',
                                        height: '12px',
                                        border: `1.5px solid ${selectedTemplate?.customization?.primaryColor}`,
                                        mr: 1,
                                        flexShrink: 0,
                                      }}
                                    />
                                    <Typography variant="subtitle1" sx={{
                                      color: selectedTemplate?.customization?.primaryColor,
                                      fontFamily: selectedTemplate?.customization?.fontFamily,
                                      fontWeight: 'bold',
                                    }}>
                                      {service.displayName ? service.displayName : service.name}
                                    </Typography>
                                  </Box>
                                  {selectedTemplate?.customization?.showDescriptionOnHanger && (
                                    <Typography variant="body2" sx={{
                                      color: selectedTemplate?.customization?.secondaryColor,
                                      fontFamily: selectedTemplate?.customization?.fontFamily,
                                      pl: 3,
                                    }}>
                                      {service.description}
                                    </Typography>
                                  )}
                                </Box>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                        {selectedTemplate!.customization?.sticker && (
                          <Box sx={{ mb: 0 }}>
                            <img
                              src={`/stickers/${selectedTemplate!.customization.sticker}`}
                              alt="Sticker"
                              style={{ width: '150px', height: 'auto' }}
                            />
                          </Box>
                        )}
                        {selectedTemplate!.qrUrl && (
                          <Box sx={{ mb: 0, mt: 0 }}>
                            <img
                              src={selectedTemplate!.qrUrl}
                              alt="QR Code"
                              style={{ width: '100px', height: '100px' }}
                            />
                          </Box>
                        )}
                        {selectedTemplate!.customization.showQuoteId && (
                          <Typography variant="body2" sx={{ color: selectedTemplate!.customization.secondaryColor, fontSize: 8, mt: 0, textAlign: 'center', mb: 1 }}>
                            Quote #: {generateConcatenatedId('dummy_quote_12_34_567')}
                          </Typography>
                        )}
                        <Typography variant="h6" sx={{
                          color: selectedTemplate!.customization.primaryColor,
                          fontFamily: selectedTemplate!.customization.fontFamily,
                          fontWeight: 'bold',
                        }}>
                          {companySettings?.companyPhoneNumber &&
                            companySettings.companyPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                  /* CARD LAYOUT RENDER */
                    <Grid container spacing={2}>
                    {/* Logo row */}
                    {selectedTemplate!.customization!.showLogo && companySettings?.companyLogo && (
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Box
                            sx={{
                              mb: 2,
                              maxWidth: '80%',
                              height: '50px', // Adjusted height for PDF match
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={`${companySettings.companyLogo.replace(/^\//, '')}`}
                              alt="Company Logo"
                              style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  
                    {/* Title row */}
                    {selectedTemplate!.customization!.showTitle && (
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          sx={{
                            color: selectedTemplate!.customization!.primaryColor,
                            fontFamily: selectedTemplate!.customization!.fontFamily,
                            fontWeight: 'bold',
                            mt: 1,
                            mb: 0,
                            fontSize: '16px', // Matches PDF title size
                            textAlign: 'center',
                          }}
                        >
                          {selectedTemplate!.name}
                        </Typography>
                      </Grid>
                    )}
                  
                    {/* Description row aligned left */}
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: selectedTemplate!.customization!.secondaryColor,
                          fontFamily: selectedTemplate!.customization!.fontFamily,
                          textAlign: 'left',
                          mb: 1,
                          fontSize: '11px', // PDF intro paragraph font size
                        }}
                      >
                        {selectedTemplate!.introParagraph}
                      </Typography>
                    </Grid>
                  
                    {/* Main content row: Services list & Right column */}
                    <Grid item xs={8}>
                      <Box sx={{ width: '100%', flexGrow: 1, overflowY: 'auto' }}>
                        <Grid container spacing={1}>
                          {selectedTemplate!.customServices!.map((service, index) => (
                            <Grid item xs={12} key={`custom-${index}`}>
                              <Box sx={{ mb: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box
                                    sx={{
                                      width: '7px',
                                      height: '7px',
                                      border: `1.5px solid ${selectedTemplate!.customization!.primaryColor}`,
                                      mr: 1,
                                      flexShrink: 0,
                                    }}
                                  />
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      color: selectedTemplate!.customization!.primaryColor,
                                      fontFamily: selectedTemplate!.customization!.fontFamily,
                                      fontWeight: 'bold',
                                      
                                      fontSize: '12px',
                                    }}
                                  >
                                    {service.name}
                                  </Typography>
                                </Box>
                                {selectedTemplate!.customization!.showDescriptionOnHanger && (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: selectedTemplate!.customization!.secondaryColor,
                                      fontFamily: selectedTemplate!.customization!.fontFamily,
                                      pl: 3,
                                      fontSize: '12px',
                                    }}
                                  >
                                    {service.description}
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                          ))}
                          {predefinedServices
                            .filter((service) =>
                              selectedTemplate!.predefinedServiceIds!.includes(service._id || '')
                            )
                            .map((service) => (
                              <Grid item xs={12} key={`predefined-${service._id}`}>
                                <Box sx={{ mb: 1 }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                      sx={{
                                        width: '7px',
                                        height: '7px',
                                        border: `1.5px solid ${selectedTemplate!.customization!.primaryColor}`,
                                        mr: 1,
                                        flexShrink: 0,
                                      }}
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        color: selectedTemplate!.customization!.primaryColor,
                                        fontFamily: selectedTemplate!.customization!.fontFamily,
                                        fontWeight: 'bold',
                                        fontSize: '11px',
                                      }}
                                    >
                                      {service.displayName ? service.displayName : service.name}
                                    </Typography>
                                  </Box>
                                  {selectedTemplate!.customization!.showDescriptionOnHanger && (
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: selectedTemplate!.customization!.secondaryColor,
                                        fontFamily: selectedTemplate!.customization!.fontFamily,
                                        pl: 3,
                                        fontSize: '10px',
                                      }}
                                    >
                                      {service.description}
                                    </Typography>
                                  )}
                                </Box>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    </Grid>
                  
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                          height: '100%',
                        }}
                      >
                        {/* Wrapper div to tweak bottom spacing */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mb: 2, gap: 0 }}>
  {selectedTemplate!.customization!.sticker && (
    <Box>
      <img
        src={`/stickers/${selectedTemplate!.customization!.sticker}`}
        alt="Sticker"
        style={{ width: '100px', height: 'auto',marginTop:'-40px' }}
      />
    </Box>
  )}
  {selectedTemplate!.customization!.showQuoteId && (
    <Typography
      variant="body2"
      sx={{
        color: selectedTemplate!.customization!.secondaryColor,
        fontSize: '8px',
        textAlign: 'left',
        mt: 0,
        mr:2 // Adjust the margin-top here for fine-tuning
      }}
    >
      Quote #: {generateConcatenatedId('dummy_quote_12_34_567')}
    </Typography>
  )}
  {selectedTemplate!.qrUrl && (
    <Box>
      <img
        src={selectedTemplate!.qrUrl}
        alt="QR Code"
        style={{ width: '100px', height: '100px' }}
      />
    </Box>
  )}
  
</Box>
                      </Box>
                    </Grid>
                  
                    {/* Phone number row */}
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            color: selectedTemplate!.customization!.primaryColor,
                            fontFamily: selectedTemplate!.customization!.fontFamily,
                            fontWeight: 'bold',
                          }}
                        >
                          {companySettings?.companyPhoneNumber &&
                            companySettings.companyPhoneNumber.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              '($1) $2-$3'
                            )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  )}
                </CardContent>
              </Card>
              {selectedLayout === 'card' && totalServices > 2 && (
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(255, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1,
                }}>
                  <Typography variant="h6" color="white">
                    Max of 2 services for card layout
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {user?.role !== 'User' && (
                <Button startIcon={<Edit />} variant="contained" onClick={handleOpenEditTemplateModal} sx={{
                  bgcolor: 'secondary.main',
                  '&:hover': { bgcolor: 'secondary.dark' },
                  maxWidth: 350,
                }}>
                  Edit Template
                </Button>
              )}
              <Button startIcon={<Language />} variant="contained" onClick={handleQuotePage} sx={{
                bgcolor: 'info.main',
                '&:hover': { bgcolor: 'info.dark' },
                maxWidth: 350,
              }}>
                Template Landing Page
              </Button>
              {user?.role !== 'User' && (
                <Tooltip title={selectedLayout === 'card' && totalServices > 2 ? 'Cannot generate PDF: Max of 2 services for card layout' : ''}>
                  <span>
                    <Button
                      startIcon={<Print />}
                      variant="contained"
                      onClick={handlePrintHangers}
                      disabled={selectedLayout === 'card' && totalServices > 2}
                      sx={{
                        bgcolor: selectedLayout === 'card' && totalServices > 2 ? 'grey.500' : 'info.main',
                        '&:hover': {
                          bgcolor: selectedLayout === 'card' && totalServices > 2 ? 'grey.700' : 'info.dark',
                        },
                        maxWidth: 350,
                      }}
                    >
                      Generate & Print
                    </Button>
                  </span>
                </Tooltip>
              )}
              <Button startIcon={<Visibility />} variant="contained" onClick={handleViewQuotes} sx={{
                bgcolor: 'info.main',
                '&:hover': { bgcolor: 'info.dark' },
                maxWidth: 350,
              }}>
                View Quotes
              </Button>
              {user?.role !== 'User' && (
                <Button
                  startIcon={<Delete />}
                  variant="contained"
                  onClick={handleDeleteClick}
                  sx={{ bgcolor: 'darkred', '&:hover': { bgcolor: 'red' }, maxWidth: 350 }}
                >
                  Delete Template
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderTemplateList = () => (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom align='center'>Templates</Typography>
      {user?.role !== 'User' && (
        <Button onClick={handleOpenNewTemplateModal} variant="contained" color='warning' startIcon={<Add />} sx={{ mb: 2 }}>
          Add Template
        </Button>
      )}
      <Grid container spacing={2}>
        {templates.map((template) => (
          <Grid item xs={6} sm={3} md={2} key={template.templateId}>
            <Card
              onClick={() => handleTemplateClick(template)}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 3,
                },
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <InsertDriveFile sx={{ fontSize: 40, color: 'warning.main', mb: 1 }} />
                <Typography variant="subtitle2" noWrap>{template.name || 'Unnamed Template'}</Typography>
                <Typography variant="caption" noWrap>
                  {template.createdAt ? new Date(template.createdAt).toLocaleDateString() : 'No date'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <>
      {selectedTemplate ? renderSelectedTemplate() : renderTemplateList()}
      {/* Modal for creating a new template */}
      <Modal
        open={newTemplateModalOpen}
        onClose={handleCloseNewTemplateModal}
        aria-labelledby="new-template-modal-title"
        aria-describedby="new-template-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          maxHeight: '80vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Add New Template
          </Typography>
          {editingTemplate && (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Template Name"
                value={editingTemplate.name}
                onChange={(e) => setEditingTemplate({ ...editingTemplate, name: e.target.value })}
                margin="normal"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                  },
                  '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                }}
              />
              <TextField
                fullWidth
                label="Paper Intro"
                value={editingTemplate.introParagraph}
                onChange={(e) => setEditingTemplate({ ...editingTemplate, introParagraph: e.target.value })}
                margin="normal"
                multiline
                rows={3}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                  },
                  '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                }}
              />
              <TextField
                fullWidth
                label="Landing Page Intro"
                value={editingTemplate.landingPageIntro || ''}
                onChange={(e) => setEditingTemplate({ ...editingTemplate, landingPageIntro: e.target.value })}
                margin="normal"
                multiline
                rows={3}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                  },
                  '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                }}
              />
              <CustomDropdown
                label="Quote Expiration"
                value={editingTemplate.daysToExpire}
                onChange={(value) => setEditingTemplate({ ...editingTemplate, daysToExpire: value })}
                options={[
                  { value: '30', label: '30 days' },
                  { value: '60', label: '60 days' },
                  { value: '90', label: '90 days' },
                  { value: '0', label: 'Does not expire' },
                ]}
                id="quote-expiration-select"
                labelColor='warning.main'
              />
              <Typography variant="subtitle1" gutterBottom>Custom Services</Typography>
              {editingTemplate.customServices.map((service, index) => (
                <Box key={index} sx={{ position: 'relative', mt: 2 }}>
                  {index > 0 && (
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveCustomService(index)}
                      sx={{ position: 'absolute', width: 20, height: 20, right: -10, top: -10 }}
                    >
                      <Close />
                    </IconButton>
                  )}
                  <TextField
                    fullWidth
                    label={`Service ${index + 1} Name`}
                    value={service.name}
                    onChange={(e) => handleCustomServiceChange(index, 'name', e.target.value)}
                    margin="normal"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                      },
                      '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                    }}
                  />
                  <TextField
                    fullWidth
                    label={`Service ${index + 1} Description`}
                    value={service.description}
                    onChange={(e) => handleCustomServiceChange(index, 'description', e.target.value)}
                    margin="normal"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                      },
                      '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                    }}
                  />
                  <TextField
                    fullWidth
                    label={`Service ${index + 1} Fine Text`}
                    value={service.fineText || ''}
                    onChange={(e) => handleCustomServiceChange(index, 'fineText', e.target.value)}
                    margin="normal"
                    multiline
                    rows={2}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                      },
                      '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                    }}
                  />
                </Box>
              ))}
              <Button
                onClick={handleAddCustomService}
                sx={{ mt: 1 }}
                color='info'
                variant="contained"
              >
                Add Custom Service
              </Button>
              <Box sx={{ mt: 2, mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom>Add Predefined Services:</Typography>
                <Autocomplete
                  multiple
                  id="predefined-services-select"
                  options={predefinedServices}
                  getOptionLabel={(option) => option.name}
                  value={predefinedServices.filter(service => editingTemplate.predefinedServiceIds.includes(service._id || ''))}
                  onChange={handlePredefinedServicesChange}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Select predefined services" />
                  )}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                    },
                    '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                  }}
                />
              </Box>
              <Button type="submit" color='warning' variant="contained" sx={{ mt: 2 }}>
                Create Template
              </Button>
            </form>
          )}
        </Box>
      </Modal>
      {/* Modal for editing/customizing an existing template */}
      <Modal
        open={editTemplateModalOpen}
        onClose={handleCloseEditTemplateModal}
        aria-labelledby="edit-template-modal-title"
        aria-describedby="edit-template-modal-description"
        sx={{ zIndex: 9998 }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: 800,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Edit Template
          </Typography>
          {editingTemplate && (
            <>
              <Tabs value={editingTab} onChange={(_, newValue) => setEditingTab(newValue)} sx={{
                mb: 2,
                minHeight: 'unset',
                '& .MuiTabs-flexContainer': { justifyContent: 'space-between' },
                '& .MuiTab-root': { minWidth: 'unset', padding: '8px 8px', fontSize: '1rem', minHeight: 'unset', flex: 1 },
                '& .MuiTabs-indicator': { backgroundColor: 'warning.main' },
              }} textColor="inherit">
                <Tab label="Quote" sx={(theme) => ({
                  color: 'warning.main',
                  '&.Mui-selected': { color: 'warning.main' },
                  '&:hover': {
                    color: alpha(theme.palette.common.white, 1),
                    backgroundColor: alpha(theme.palette.warning.main, 0.8),
                    cursor: 'pointer',
                  },
                })} />
                <Tab label="Customization" sx={(theme) => ({
                  color: 'warning.main',
                  '&.Mui-selected': { color: 'warning.main' },
                  '&:hover': {
                    color: alpha(theme.palette.common.white, 1),
                    backgroundColor: alpha(theme.palette.warning.main, 0.8),
                    cursor: 'pointer',
                  },
                })} />
              </Tabs>
              <form onSubmit={handleSubmit}>
                {editingTab === 0 && (
                  <>
                    <TextField
                      fullWidth
                      label="Template Name"
                      value={editingTemplate.name}
                      onChange={(e) => setEditingTemplate({ ...editingTemplate, name: e.target.value })}
                      margin="normal"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                      }}
                    />
                    <TextField
                      fullWidth
                      label="Paper Intro"
                      value={editingTemplate.introParagraph}
                      onChange={(e) => setEditingTemplate({ ...editingTemplate, introParagraph: e.target.value })}
                      margin="normal"
                      multiline
                      rows={3}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                      }}
                    />
                    <TextField
                      fullWidth
                      label="Landing Page Intro"
                      value={editingTemplate.landingPageIntro || ''}
                      onChange={(e) => setEditingTemplate({ ...editingTemplate, landingPageIntro: e.target.value })}
                      margin="normal"
                      multiline
                      rows={3}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                      }}
                    />
                    <CustomDropdown
                      label="Quote Expiration"
                      value={editingTemplate.daysToExpire}
                      onChange={(value) => setEditingTemplate({ ...editingTemplate, daysToExpire: value })}
                      options={[
                        { value: '30', label: '30 days' },
                        { value: '60', label: '60 days' },
                        { value: '90', label: '90 days' },
                        { value: '0', label: 'Does not expire' },
                      ]}
                      id="quote-expiration-select"
                      labelColor='warning.main'
                    />
                    <Typography variant="subtitle1" gutterBottom>Custom Services</Typography>
                    {editingTemplate.customServices.map((service, index) => (
                      <Box key={index} sx={{ position: 'relative', mt: 2 }}>
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveCustomService(index)}
                          sx={{ position: 'absolute', right: -10, top: -20, width: 30 }}
                        >
                          <Close />
                        </IconButton>
                        <TextField
                          fullWidth
                          label={`Service ${index + 1} Name`}
                          value={service.name}
                          onChange={(e) => handleCustomServiceChange(index, 'name', e.target.value)}
                          margin="normal"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                            },
                            '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                          }}
                        />
                        <TextField
                          fullWidth
                          label={`Service ${index + 1} Description`}
                          value={service.description}
                          onChange={(e) => handleCustomServiceChange(index, 'description', e.target.value)}
                          margin="normal"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                            },
                            '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                          }}
                        />
                        <TextField
                          fullWidth
                          label={`Service ${index + 1} Fine Text`}
                          value={service.fineText || ''}
                          onChange={(e) => handleCustomServiceChange(index, 'fineText', e.target.value)}
                          margin="normal"
                          multiline
                          rows={2}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                            },
                            '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                          }}
                        />
                      </Box>
                    ))}
                    <Button
                      onClick={handleAddCustomService}
                      sx={{ mt: 1 }}
                      color='info'
                      variant="contained"
                    >
                      Add Custom Service
                    </Button>
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>Add Predefined Services:</Typography>
                      <Autocomplete
                        multiple
                        id="predefined-services-select"
                        options={predefinedServices}
                        getOptionLabel={(option: Service) => option.name}
                        value={predefinedServices.filter(service => editingTemplate.predefinedServiceIds.includes(service._id || ''))}
                        onChange={handlePredefinedServicesChange}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Select predefined services" />
                        )}
                        slotProps={{
                          popper: { sx: { zIndex: 9999 } },
                          paper: { sx: { [`& .${autocompleteClasses.listbox}`]: { maxHeight: '200px' } } },
                        }}
                        componentsProps={{ paper: { elevation: 20 } }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                          },
                          '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                        }}
                      />
                    </Box>
                  </>
                )}
                {editingTab === 1 && (
                  <>
                    <Accordion defaultExpanded>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography><Palette /> Colors</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography gutterBottom>Primary Color</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <ColorLens sx={{ color: editingTemplate.customization.primaryColor, mr: 1 }} />
                              <input
                                type="color"
                                value={editingTemplate.customization.primaryColor}
                                onChange={(e) => handleCustomizationChange('primaryColor', e.target.value)}
                                style={{ width: '100%' }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography gutterBottom>Secondary Color</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <ColorLens sx={{ color: editingTemplate.customization.secondaryColor, mr: 1 }} />
                              <input
                                type="color"
                                value={editingTemplate.customization.secondaryColor}
                                onChange={(e) => handleCustomizationChange('secondaryColor', e.target.value)}
                                style={{ width: '100%' }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography><TextFields /> Typography</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomDropdown
                          label=""
                          value={editingTemplate.customization.fontFamily}
                          onChange={(value) => handleCustomizationChange('fontFamily', value)}
                          options={[
                            { value: 'Roboto', label: 'Roboto' },
                            { value: 'Open Sans', label: 'Open Sans' },
                            { value: 'Lato', label: 'Lato' },
                            { value: 'Montserrat', label: 'Montserrat' },
                          ]}
                          id="font-family-select"
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography><InsertPhoto sx={{ mr: 1 }} />Sticker</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          {['sticker-1.png', 'sticker-2.png', 'sticker-3.png', 'sticker-4.png', 'sticker-5.png', 'sticker-6.png', 'sticker-7.png', 'sticker-8.png'].map((sticker) => (
                            <Grid item xs={3} key={sticker}>
                              <Card
                                onClick={() => handleCustomizationChange('sticker', sticker)}
                                sx={{
                                  border: editingTemplate!.customization.sticker === sticker
                                    ? `2px solid ${editingTemplate!.customization.primaryColor}`
                                    : '1px solid transparent',
                                  cursor: 'pointer',
                                  height: 100,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <img
                                  src={`/stickers/${sticker}`}
                                  alt={sticker}
                                  style={{ maxWidth: '100%', maxHeight: '100%', transition: 'transform 0.3s' }}
                                  onMouseOver={(e) => { (e.currentTarget as HTMLImageElement).style.transform = 'scale(1.2)'; }}
                                  onMouseOut={(e) => { (e.currentTarget as HTMLImageElement).style.transform = 'scale(1)'; }}
                                />
                              </Card>
                            </Grid>
                          ))}
                          <Grid item xs={3}>
                            <Card
                              onClick={() => handleCustomizationChange('sticker', '')}
                              sx={{
                                border: editingTemplate!.customization.sticker === ''
                                  ? `2px solid ${editingTemplate!.customization.primaryColor}`
                                  : '1px solid transparent',
                                cursor: 'pointer',
                                height: 100,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <Typography variant="subtitle2">None</Typography>
                            </Card>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography><DisplaySettings /> Display Options</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={editingTemplate.customization.showLogo}
                              onChange={(e) => handleCustomizationChange('showLogo', e.target.checked)}
                            />
                          }
                          label="Company Logo (Doorhanger and Landing Page)"
                          sx={{
                            '& .MuiSwitch-switchBase': {
                              color: 'warning.main',
                              '&.Mui-checked': { color: 'warning.main', '& + .MuiSwitch-track': { backgroundColor: 'warning.main' } },
                            },
                            '& .MuiSwitch-track': { backgroundColor: 'grey.100' },
                            color: 'info.main'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={editingTemplate.customization.showTitle}
                              onChange={(e) => handleCustomizationChange('showTitle', e.target.checked)}
                            />
                          }
                          label="Template Title (Doorhanger and Landing Page)"
                          sx={{
                            '& .MuiSwitch-switchBase': {
                              color: 'warning.main',
                              '&.Mui-checked': { color: 'warning.main', '& + .MuiSwitch-track': { backgroundColor: 'warning.main' } },
                            },
                            '& .MuiSwitch-track': { backgroundColor: 'grey.100' },
                            color: 'info.main'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={editingTemplate.customization.showIntro}
                              onChange={(e) => handleCustomizationChange('showIntro', e.target.checked)}
                            />
                          }
                          label="Door Hanger Intro (Landing Page)"
                          sx={{
                            '& .MuiSwitch-switchBase': {
                              color: 'warning.main',
                              '&.Mui-checked': { color: 'warning.main', '& + .MuiSwitch-track': { backgroundColor: 'warning.main' } },
                            },
                            '& .MuiSwitch-track': { backgroundColor: 'grey.100' },
                            color: 'info.main'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={editingTemplate.customization.showDescriptionOnHanger}
                              onChange={(e) => handleCustomizationChange('showDescriptionOnHanger', e.target.checked)}
                            />
                          }
                          label="Service Descriptions (Door Hanger Only)"
                          sx={{
                            '& .MuiSwitch-switchBase': {
                              color: 'warning.main',
                              '&.Mui-checked': { color: 'warning.main', '& + .MuiSwitch-track': { backgroundColor: 'warning.main' } },
                            },
                            '& .MuiSwitch-track': { backgroundColor: 'grey.100' },
                            color: 'info.main'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={editingTemplate.customization.showQuoteId}
                              onChange={(e) => handleCustomizationChange('showQuoteId', e.target.checked)}
                            />
                          }
                          label="Show Quote ID (Doorhanger and Landing Page)"
                          sx={{
                            '& .MuiSwitch-switchBase': {
                              color: 'warning.main',
                              '&.Mui-checked': { color: 'warning.main', '& + .MuiSwitch-track': { backgroundColor: 'warning.main' } },
                            },
                            '& .MuiSwitch-track': { backgroundColor: 'grey.100' },
                            color: 'info.main'
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
                <Button type="submit" variant="contained" color='warning' sx={{ mt: 2 }}>
                  Update Template
                </Button>
              </form>
            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-confirm-modal-title"
        aria-describedby="delete-confirm-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="delete-confirm-modal-title" variant="h6" component="h2">
            Confirm Delete
          </Typography>
          <Typography id="delete-confirm-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this template? ALL QUOTES generated from this template will be DELETED, rendering the doorhangers useless. This action cannot be undone.
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleDeleteCancel} sx={{ mr: 1 }} variant="contained" color='info'>
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={printModalOpen}
        onClose={() => setPrintModalOpen(false)}
        aria-labelledby="print-modal-title"
        aria-describedby="print-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="print-modal-title" variant="h6" component="h2">
            Generate Quotes
          </Typography>
          <Typography id="print-modal-description" sx={{ mt: 2 }}>
            Select the number of quotes to generate:
          </Typography>
          <Slider
            value={printCount}
            onChange={(_, newValue) => setPrintCount(newValue as number)}
            aria-labelledby="print-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            color='warning'
            min={1}
            max={maxQuotes}
            sx={{ mt: 4 }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setPrintModalOpen(false)} color='warning' sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button onClick={handlePrintConfirm} variant="contained" color="warning">
              Generate PDF
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={viewQuotesOpen}
        onClose={() => setViewQuotesOpen(false)}
        aria-labelledby="view-quotes-modal-title"
        sx={{ zIndex: 9999 }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
        }}>
          {user?.role !== 'User' ? (
            <Typography id="view-quotes-modal-title" variant="h6" component="h2" gutterBottom>
              All Quotes for {selectedTemplate?.name}
            </Typography>
          ) : (
            <Typography id="view-quotes-modal-title" variant="h6" component="h2" gutterBottom>
              My Quotes for {selectedTemplate?.name}
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedQuotes.length === quotes.length}
                  indeterminate={selectedQuotes.length > 0 && selectedQuotes.length < quotes.length}
                  onChange={handleCheckAll}
                  sx={(theme) => ({ '&.Mui-checked': { color: 'warning.main' } })}
                />
              }
              label="Select All"
              sx={(theme) => ({ color: 'warning.main', '&.Mui-checked': { color: 'warning.main' } })}
            />
            <DropdownMenu
              options={[
                { label: 'Delete Selected Quotes', onClick: handleDeleteSelectedQuotes },
                { label: 'Reprint Selected Quotes', onClick: handleReprintSelectedQuotes }
              ]}
              buttonText="Actions"
              disabled={selectedQuotes.length === 0}
            />
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>#</TableCell>
                  <TableCell>Quote ID</TableCell>
                  <TableCell>Generated Date</TableCell>
                  {user?.role !== 'User' && <TableCell>Activated By</TableCell>}
                  <TableCell>Activated Time</TableCell>
                  <TableCell>Days Until Expired</TableCell>
                  <TableCell>Quote Status</TableCell>
                  <TableCell>View Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quotes.map((quote: Quote, index: number) => (
                  <TableRow key={quote.quoteId}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedQuotes.includes(quote.quoteId)}
                        onChange={() => handleCheckQuote(quote.quoteId)}
                        sx={(theme) => ({ '&.Mui-checked': { color: 'warning.main' } })}
                      />
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Tooltip title={`Full Quote ID: ${quote.quoteId}`} arrow PopperProps={{ style: { zIndex: 10000 } }}>
                        <span>{generateConcatenatedId(quote.quoteId)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{new Date(quote.time).toLocaleString()}</TableCell>
                    {user?.role !== 'User' && (
                      <TableCell>{quote.activatedByUserName || 'Not Activated'}</TableCell>
                    )}
                    <TableCell>
                      {quote.activatedTime ? new Date(quote.activatedTime).toLocaleDateString() : 'N/A'}
                    </TableCell>
                    <TableCell>{calculateDaysUntilExpired(quote.activatedTime, quote.expirationTime)}</TableCell>
                    <TableCell>{quote.status}</TableCell>
                    <TableCell>
                      {quote.isViewed ? (
                        <Tooltip
                          title={quote.firstViewedAt ? `First viewed: ${new Date(quote.firstViewedAt).toLocaleString()}` : 'View date not available'}
                          arrow
                          PopperProps={{ style: { zIndex: 10000 } }}
                        >
                          <Visibility color="success" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Not viewed yet">
                          <VisibilityOff color="disabled" />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={quote.status === 'Idle' ? 'Activate to view landing page' : 'View Customer Quote Page'} PopperProps={{ style: { zIndex: 10000 } }}>
                          <span>
                            <IconButton
                              onClick={() => handleViewCustomerQuote(quote.quoteId, quote.companyName)}
                              size="small"
                              disabled={quote.status === 'Idle'}
                              sx={{ width: 40, height: 40 }}
                            >
                              <OpenInNew />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title={quote.status === 'Idle' ? 'Cannot view idle quotes' : 'View Internal Quote'} PopperProps={{ style: { zIndex: 10000 } }}>
                          <span>
                            <IconButton
                              onClick={() => handleViewQuote(quote.quoteId, quote.status)}
                              disabled={quote.status === 'Idle'}
                              size="small"
                              sx={{ width: 40, height: 40 }}
                            >
                              <Info />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title={<img src={quote.qrUrl} alt="QR Code" style={{ width: '150px', height: '150px' }} />} PopperProps={{ style: { zIndex: 10000 } }}>
                          <IconButton size="small" sx={{ width: 40, height: 40 }}>
                            <QrCodeIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </>
  );
};

export default Templates;