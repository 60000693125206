import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Typography, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Grid, Divider, Tooltip, CircularProgress, Chip
} from '@mui/material';
import { Add, Delete, Edit, Info, ContentCopy } from '@mui/icons-material'; // Added ContentCopy
import adminAxiosInstance from '../utils/adminAxiosConfig';

interface ReplacementRule {
  original: string;
  replacement: string;
}

interface County {
  _id: string;
  state: string;
  county_name: string;
  search_url_template: string;
  replacement_rules: ReplacementRule[];
}

// Define all possible placeholders
const ALL_PLACEHOLDERS = [
  '{house_number}',
  '{road}',
  '{full_address}',
  '{city}',
  '{state}',
  '{state_abbr}',
  '{zip}',
  '{county}'
];

const CountyManager: React.FC = () => {
  const [counties, setCounties] = useState<County[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState<County | null>(null);

  // Form state
  const [state, setState] = useState('');
  const [countyName, setCountyName] = useState('');
  const [searchUrlTemplate, setSearchUrlTemplate] = useState('');
  const [replacementRules, setReplacementRules] = useState<ReplacementRule[]>([]);

  // Ref for Search URL Template text field to manage cursor position
  const templateRef = useRef<HTMLInputElement>(null);

  // Fetch all counties
  const fetchCounties = async () => {
    try {
      setLoading(true);
      const response = await adminAxiosInstance.get('/api/counties/admin');
      setCounties(response.data);
      setError(null);
    } catch (err: any) {
      console.error('Error fetching counties:', err);
      setError(err.response?.data?.message || 'Failed to fetch counties');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCounties();
  }, []);

  // Handle opening the dialog for a new county
  const handleAddNew = () => {
    setSelectedCounty(null);
    setState('');
    setCountyName('');
    setSearchUrlTemplate('');
    setReplacementRules([]);
    setOpenDialog(true);
  };

  // Handle opening the dialog for editing an existing county
  const handleEdit = (county: County) => {
    setSelectedCounty(county);
    setState(county.state);
    setCountyName(county.county_name);
    setSearchUrlTemplate(county.search_url_template);
    setReplacementRules([...county.replacement_rules]);
    setOpenDialog(true);
  };

  // Handle duplicating a county
  const handleDuplicate = (county: County) => {
    setState(county.state);
    setCountyName(`${county.county_name} (Copy)`); // Append "(Copy)" to distinguish it
    setSearchUrlTemplate(county.search_url_template);
    setReplacementRules([...county.replacement_rules]); // Copy the array
    setSelectedCounty(null); // Treat it as a new county
    setOpenDialog(true);
  };

  // Handle adding a new replacement rule
  const handleAddRule = () => {
    setReplacementRules([...replacementRules, { original: '', replacement: '' }]);
  };

  // Handle updating a replacement rule
  const handleRuleChange = (index: number, field: 'original' | 'replacement', value: string) => {
    const updatedRules = [...replacementRules];
    updatedRules[index][field] = value;
    setReplacementRules(updatedRules);
  };

  // Handle removing a replacement rule
  const handleRemoveRule = (index: number) => {
    const updatedRules = [...replacementRules];
    updatedRules.splice(index, 1);
    setReplacementRules(updatedRules);
  };

  // Handle inserting a placeholder when a bubble is clicked
  const handleInsertPlaceholder = (placeholder: string) => {
    if (templateRef.current) {
      const pos = templateRef.current.selectionStart || 0;
      const newValue = searchUrlTemplate.slice(0, pos) + placeholder + searchUrlTemplate.slice(pos);
      setSearchUrlTemplate(newValue);
      // Set cursor position after the inserted placeholder
      setTimeout(() => {
        templateRef.current?.setSelectionRange(pos + placeholder.length, pos + placeholder.length);
      }, 0);
    }
  };

  // Handle saving the county
  const handleSave = async () => {
    try {
      setSaving(true);
      const countyData = {
        state,
        county_name: countyName,
        search_url_template: searchUrlTemplate,
        replacement_rules: replacementRules.filter(
          rule => rule.original.trim() !== '' && rule.replacement.trim() !== ''
        )
      };

      if (selectedCounty) {
        await adminAxiosInstance.put(`/api/counties/admin/${selectedCounty._id}`, countyData);
      } else {
        await adminAxiosInstance.post('/api/counties/admin', countyData);
      }

      setOpenDialog(false);
      fetchCounties();
    } catch (err: any) {
      console.error('Error saving county:', err);
      setError(err.response?.data?.message || 'Failed to save county');
    } finally {
      setSaving(false);
    }
  };

  // Handle deleting a county
  const handleDelete = async (countyId: string) => {
    if (window.confirm('Are you sure you want to delete this county?')) {
      try {
        await adminAxiosInstance.delete(`/api/counties/admin/${countyId}`);
        fetchCounties();
      } catch (err: any) {
        console.error('Error deleting county:', err);
        setError(err.response?.data?.message || 'Failed to delete county');
      }
    }
  };

  // Compute used and available placeholders dynamically
  const usedPlaceholders = new Set(searchUrlTemplate.match(/{[a-z_]+}/g) || []);
  const availablePlaceholders = ALL_PLACEHOLDERS.filter(p => !usedPlaceholders.has(p));
  const hasPlaceholder = usedPlaceholders.size > 0;

  // Placeholder descriptions for the tooltip
  const placeholderHelp = `
    {house_number}: User-entered house number
    {road}: Street name from LocationIQ
    {full_address}: Combined house_number + road, after applying replacement rules
    {city}: City from LocationIQ
    {state}: Full state name from LocationIQ
    {state_abbr}: State abbreviation from LocationIQ
    {zip}: Postcode from LocationIQ
    {county}: County name from LocationIQ
  `;

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">County Management</Typography>
        <Button
          variant="contained"
          color="warning"
          sx={{ width: 300 }}
          startIcon={<Add />}
          onClick={handleAddNew}
        >
          Add New County
        </Button>
      </Box>

      {error && (
        <Box mb={3}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>County Name</TableCell>
              <TableCell>URL Template</TableCell>
              <TableCell>Replacement Rules</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress size={24} />
                </TableCell>
              </TableRow>
            ) : counties.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">No counties found</TableCell>
              </TableRow>
            ) : (
              counties.map((county) => (
                <TableRow key={county._id}>
                  <TableCell>{county.state}</TableCell>
                  <TableCell>{county.county_name}</TableCell>
                  <TableCell>
                    <Tooltip title={county.search_url_template}>
                      <Typography noWrap style={{ maxWidth: 250 }}>
                        {county.search_url_template}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {county.replacement_rules.length > 0 ? (
                      <Typography>{county.replacement_rules.length} rule(s)</Typography>
                    ) : (
                      <Typography color="textSecondary">No rules</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton sx={{width:40}} onClick={() => handleEdit(county)} size="small">
                      <Edit />
                    </IconButton>
                    <IconButton sx={{width:40}} onClick={() => handleDelete(county._id)} size="small">
                      <Delete />
                    </IconButton>
                    <IconButton sx={{width:40}} onClick={() => handleDuplicate(county)} size="small">
                      <ContentCopy />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add/Edit County Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>{selectedCounty ? 'Edit County' : 'Add New County'}</DialogTitle>
        <DialogContent>
          <Box my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                  placeholder="e.g. North Carolina"
                  margin="normal"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                    },
                    '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="County Name"
                  value={countyName}
                  onChange={(e) => setCountyName(e.target.value)}
                  required
                  placeholder="e.g. Mecklenburg County"
                  helperText="Enter exactly as returned by LocationIQ"
                  margin="normal"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                    },
                    '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Available Placeholders:
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                  {availablePlaceholders.map((placeholder) => (
                    <Chip
                      key={placeholder}
                      label={placeholder}
                      onClick={() => handleInsertPlaceholder(placeholder)}
                      color="warning"
                      variant="outlined"
                    />
                  ))}
                </Box>
                <TextField
                  fullWidth
                  label="Search URL Template"
                  value={searchUrlTemplate}
                  onChange={(e) => setSearchUrlTemplate(e.target.value)}
                  required
                  placeholder="e.g. http://property.spatialest.com/nc/mecklenburg/#/search/?term={full_address}"
                  margin="normal"
                  multiline
                  rows={2}
                  inputRef={templateRef}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                    },
                    '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                  }}
                />
                <Box display="flex" alignItems="center" mt={1}>
                  <Typography variant="caption" color="textSecondary">
                    Placeholder descriptions:
                  </Typography>
                  <Tooltip title={placeholderHelp}>
                    <IconButton size="small" sx={{ width: 20, ml: 2 }}>
                      <Info fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>

            <Box mt={4}>
              <Typography variant="h6">Replacement Rules</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                These rules are applied to {'{full_address}'} to match county website formatting requirements.
              </Typography>

              {replacementRules.map((rule, index) => (
                <Box key={index} mb={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        label="Original Text"
                        value={rule.original}
                        onChange={(e) => handleRuleChange(index, 'original', e.target.value)}
                        placeholder="e.g. Avenue"
                        size="small"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                          },
                          '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        label="Replacement"
                        value={rule.replacement}
                        onChange={(e) => handleRuleChange(index, 'replacement', e.target.value)}
                        placeholder="e.g. Ave"
                        size="small"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                          },
                          '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => handleRemoveRule(index)} color="error" size="small">
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <Button
                variant="outlined"
                startIcon={<Add />}
                color="warning"
                onClick={handleAddRule}
                sx={{ mt: 1 }}
              >
                Add Rule
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="warning"
            variant="contained"
            disabled={!state || !countyName || !searchUrlTemplate || !hasPlaceholder || saving}
            startIcon={saving ? <CircularProgress size={20} /> : null}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CountyManager;