import L from 'leaflet';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import { LatLngExpression, Icon } from 'leaflet';
import { useTheme, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserChip from './UserChip';
import 'leaflet/dist/leaflet.css';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const defaultIcon = new Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

interface Quote {
  quoteId: string;
  totalValue: number;
  location?: {
    latitude: number;
    longitude: number;
  };
  status: 'Active' | 'Accepted';
  templateName?: string;
  customerName?: string;
  activatedTime?: string;
  acceptedTime?: string;
  activatedByUserId: string;
  activatedByUserName: string;
}

interface QuoteMapProps {
  quotes: Quote[];
  center: LatLngExpression;
  zoom: number;
  onViewChange: (newCenter: LatLngExpression, newZoom: number) => void;
  userColors?: { [key: string]: string };
}

const mapStyles = {
  light: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  dark: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
};

// Listen to popup events and update popup state
const PopupListener = ({ onPopupOpenChange }: { onPopupOpenChange: (isOpen: boolean) => void }) => {
  useMapEvents({
    popupopen: () => onPopupOpenChange(true),
    popupclose: () => onPopupOpenChange(false),
  });
  return null;
};

// Close any open popup when clicking on the map outside of a popup element
const MapClickHandler = () => {
  const map = useMapEvents({
    click: (e) => {
      const target = e.originalEvent.target as HTMLElement;
      if (!target.closest('.leaflet-popup')) {
        map.closePopup();
      }
    },
  });
  return null;
};

// Update the map view only if no popup is open
const MapUpdater = ({ center, zoom, popupOpen }: { center: LatLngExpression; zoom: number; popupOpen: boolean }) => {
  const map = useMap();

  useEffect(() => {
    if (popupOpen) return;

    const currentCenter = map.getCenter();
    const currentZoom = map.getZoom();
    const targetCenter = L.latLng(center);
    const epsilon = 0.000001;

    if (
      Math.abs(currentCenter.lat - targetCenter.lat) > epsilon ||
      Math.abs(currentCenter.lng - targetCenter.lng) > epsilon ||
      currentZoom !== zoom
    ) {
      map.panTo(center);
      if (currentZoom !== zoom) {
        map.setZoom(zoom);
      }
    }
  }, [map, center, zoom, popupOpen]);

  return null;
};

// Only update the external view state when no popup is open
const MapEventHandler = ({
  onViewChange,
  popupOpen,
}: {
  onViewChange: (center: LatLngExpression, zoom: number) => void;
  popupOpen: boolean;
}) => {
  const map = useMap();
  useMapEvents({
    moveend: () => {
      if (popupOpen) return;
      const newCenter = map.getCenter();
      const newZoom = map.getZoom();
      onViewChange([newCenter.lat, newCenter.lng], newZoom);
    },
  });
  return null;
};

const getCustomIcon = (quote: Quote, userColors: { [key: string]: string }) => {
  const userColor = userColors?.[quote.activatedByUserId] || '#666';
  return L.divIcon({
    className: 'custom-icon',
    html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="25" height="41">
             <path fill="${userColor}" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"/>
             <circle cx="192" cy="192" r="96" fill="white" opacity="0.9"/>
           </svg>`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });
};

const QuoteMap: React.FC<QuoteMapProps> = React.memo(({ quotes, center, zoom, onViewChange, userColors = {} }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();

  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <MapContainer center={center} zoom={zoom} style={{ height: '500px', width: '100%' }}>
      <PopupListener onPopupOpenChange={setPopupOpen} />
      <MapClickHandler />
      <MapUpdater center={center} zoom={zoom} popupOpen={popupOpen} />
      <MapEventHandler onViewChange={onViewChange} popupOpen={popupOpen} />
      <TileLayer
        url={isDarkMode ? mapStyles.dark : mapStyles.light}
        attribution={
          isDarkMode
            ? '© <a href="https://carto.com/attributions">CARTO</a> | Geocoding by <a href="https://locationiq.com/">LocationIQ</a>'
            : '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }
      />
      {quotes.map((quote) => {
        if (quote.location && quote.location.latitude && quote.location.longitude) {
          return (
            <Marker
              key={quote.quoteId}
              position={[quote.location.latitude, quote.location.longitude]}
              icon={getCustomIcon(quote, userColors)}
            >
              <Popup autoClose={false} closeOnClick={false}>
                <Box
                  sx={{
                    color: isDarkMode ? '#FFFFFF' : '#000000',
                    backgroundColor: isDarkMode ? '#1E1F20' : '#FFFFFF',
                    p: 1,
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="subtitle2">{quote.customerName}</Typography>
                  <Typography variant="body2">{quote.templateName}</Typography>
                  <Typography variant="body2">${quote.totalValue.toFixed(2)}</Typography>
                  <UserChip
                    userId={quote.activatedByUserId}
                    userName={quote.activatedByUserName}
                    userColor={userColors[quote.activatedByUserId] || '#666'}
                    size="small"
                  />
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`../internal/quote/${quote.quoteId}`)}
                    color="warning"
                    sx={{ mt: 2 }}
                  >
                    View Quote
                  </Button>
                </Box>
              </Popup>
            </Marker>
          );
        }
        return null;
      })}
    </MapContainer>
  );
});

export default QuoteMap;