// Customers.tsx
import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Checkbox, CircularProgress, IconButton, TableSortLabel, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, Button, Tabs, Tab, TablePagination, TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FilterList } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../utils/axiosConfig';
import { useSnackbar } from 'notistack';
import dayjs, { Dayjs } from 'dayjs';
import CustomDropdown from './CustomDropdown';
import { useNavigate } from 'react-router-dom';
import { downloadCustomersCSV } from '../utils/customerExport';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { alpha } from '@mui/material/styles';

interface Customer {
  _id: string;
  quoteId: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  acceptedAt: string;
}

interface Quote {
  quoteId: string;
  status: string;
  acceptedTime: string;
}

interface UserData {
  userId: string;
  name: string;
  email: string;
  companyId: string;
  companyName: string;
  role: string;
}

type SortColumn = 'name' | 'address' | 'email' | 'phone' | 'acceptedAt';
type SortDirection = 'asc' | 'desc';

export interface ImportedCustomer {
  _id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  PhysicalAddress: string;
  PhysicalCity: string;
  PhysicalState: string;
  PhysicalZip: string;
  NameOnInvoice: string;
  BillingAddress: string;
  BillingCity: string;
  BillingState: string;
  BillingZip: string;
  Longitude: number;
  Latitude: number;
  HomePhone: string;
  CellPhone: string;
  ClientSince: string;
}

const ImportedCustomersSearch = ({ onSearch }: { onSearch: (searchTerm: string) => void }) => {
  const [searchInput, setSearchInput] = useState<string>('');
  console.log('[ImportedCustomersSearch] Mounted with initial searchInput:', searchInput);

  useEffect(() => {
    return () => {
      console.log('[ImportedCustomersSearch] Unmounted');
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        label="Search by Name"
        value={searchInput}
        onChange={(e) => {
          console.log('[ImportedCustomersSearch] onChange event, new value:', e.target.value);
          setSearchInput(e.target.value);
        }}
        variant="outlined"
        size="small"
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { borderColor: 'warning.main' },
          },
          '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          console.log('[ImportedCustomersSearch] Search button clicked with searchInput:', searchInput);
          onSearch(searchInput);
        }}
        sx={{ ml: 1, width: 150 }}
      >
        Search
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          console.log('[ImportedCustomersSearch] Reset button clicked, resetting search input');
          setSearchInput('');
          onSearch('');
        }}
        sx={{ ml: 1, width: 150 }}
      >
        Reset
      </Button>
    </Box>
  );
};

const Customers: React.FC = () => {
  console.log('[Customers] Component rendering');
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [actionValue, setActionValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState<{ column: SortColumn; direction: SortDirection }>({
    column: 'name',
    direction: 'asc'
  });

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [importedCustomers, setImportedCustomers] = useState<ImportedCustomer[]>([]);
  const [selectedImportedCustomers, setSelectedImportedCustomers] = useState<string[]>([]);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [importCsvFile, setImportCsvFile] = useState<File | null>(null);
  const [importCsvPreview, setImportCsvPreview] = useState<{ headers: string[]; sampleRows: string[][] } | null>(null);
  const [importMissingHeaders, setImportMissingHeaders] = useState<string[]>([]);
  const [importLoading, setImportLoading] = useState(false);

  const [editCustomer, setEditCustomer] = useState<ImportedCustomer | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [debouncedImportedSearch, setDebouncedImportedSearch] = useState<string>('');

  const [activeTab, setActiveTab] = useState<number>(0);
  const [regularPage, setRegularPage] = useState<number>(0);
  const [regularRowsPerPage, setRegularRowsPerPage] = useState<number>(5);
  const [importedPage, setImportedPage] = useState<number>(0);
  const [importedRowsPerPage, setImportedRowsPerPage] = useState<number>(5);

  const requiredImportHeaders = [
    "FirstName", "LastName", "Email", "PhysicalAddress", "PhysicalCity", "PhysicalState", "PhysicalZip",
    "NameOnInvoice", "BillingAddress", "BillingCity", "BillingState", "BillingZip",
    "Longitude", "Latitude", "HomePhone", "CellPhone", "ClientSince"
  ];

  const { getRootProps: getImportRootProps, getInputProps: getImportInputProps, isDragActive: isImportDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      console.log('[useDropzone] onDrop, acceptedFiles:', acceptedFiles);
      const file = acceptedFiles[0];
      if (file) {
        Papa.parse(file, {
          header: true,
          preview: 5,
          complete: (results) => {
            console.log('[useDropzone] CSV parse complete, results:', results);
            const headers = results.meta.fields || [];
            const missing = requiredImportHeaders.filter(h => !headers.includes(h));
            setImportMissingHeaders(missing);
            setImportCsvPreview({
              headers,
              sampleRows: results.data.slice(0, 5).map((row: any) =>
                requiredImportHeaders.map(h => row[h]?.toString() || '')
              )
            });
            if (missing.length > 0) {
              enqueueSnackbar(`Missing ${missing.length} required header(s)`, { variant: 'error' });
              setImportCsvFile(null);
            } else {
              setImportCsvFile(file);
              enqueueSnackbar('CSV format validated', { variant: 'success' });
            }
          },
          error: (error) => {
            console.error('[useDropzone] Error parsing CSV:', error);
            enqueueSnackbar('Error parsing CSV file', { variant: 'error' });
            setImportCsvFile(null);
          }
        });
      }
    },
    accept: {
      'text/csv': ['.csv']
    },
    multiple: false
  });

  const fetchCustomers = useCallback(async () => {
    console.log('[fetchCustomers] Starting fetch with startDate:', startDate, 'endDate:', endDate);
    try {
      setLoading(true);
      setError(null);

      const userDataString = localStorage.getItem('userData');
      console.log('[fetchCustomers] userDataString:', userDataString);
      if (!userDataString) {
        throw new Error('User data not found');
      }
      const userData: UserData = JSON.parse(userDataString);
      console.log('[fetchCustomers] Parsed userData:', userData);

      const quoteParams: any = {
        status: 'Accepted'
      };
      if (startDate) quoteParams.startDate = startDate.format('YYYY-MM-DD');
      if (endDate) quoteParams.endDate = endDate.format('YYYY-MM-DD');

      const quotesResponse = await axiosInstance.get(`/api/quotes/all/${userData.userId}`, { params: quoteParams });
      console.log('[fetchCustomers] quotesResponse:', quotesResponse.data);
      const acceptedQuotes: Quote[] = quotesResponse.data.quotes;

      if (acceptedQuotes.length === 0) {
        console.log('[fetchCustomers] No accepted quotes found');
        setCustomers([]);
        return;
      }

      const customerPromises = acceptedQuotes.map(quote => {
        console.log('[fetchCustomers] Fetching customer for quote:', quote);
        return axiosInstance.get(`/api/customers/customerByQuote/${quote.quoteId}?companyName=${encodeURIComponent(userData.companyName)}`);
      });

      const customerResults = await Promise.allSettled(customerPromises);
      console.log('[fetchCustomers] customerResults:', customerResults);

      const fetchedCustomers: Customer[] = customerResults
        .filter(result => result.status === 'fulfilled')
        .map((result) => {
          const data = (result as PromiseFulfilledResult<any>).value.data;
          console.log('[fetchCustomers] Fetched customer data:', data);
          return data;
        });

      setCustomers(fetchedCustomers);
      console.log('[fetchCustomers] Final fetchedCustomers:', fetchedCustomers);
    } catch (err) {
      console.error('[fetchCustomers] Error fetching customers:', err);
      setError('Failed to load customers. Please try again.');
      enqueueSnackbar('Error fetching customers', { variant: 'error' });
    } finally {
      console.log('[fetchCustomers] Finished');
      setLoading(false);
    }
  }, [startDate, endDate, enqueueSnackbar]);

  const fetchImportedCustomers = useCallback(async () => {
    console.log('[fetchImportedCustomers] Starting fetch');
    try {
      const userDataString = localStorage.getItem('userData');
      console.log('[fetchImportedCustomers] userDataString:', userDataString);
      if (!userDataString) throw new Error('User data not found');
      const userData: UserData = JSON.parse(userDataString);
      console.log('[fetchImportedCustomers] Parsed userData:', userData);
      const response = await axiosInstance.get(`/api/importedCustomers`, { params: { companyName: userData.companyName } });
      console.log('[fetchImportedCustomers] Response:', response.data);
      setImportedCustomers(response.data);
    } catch (err) {
      console.error('[fetchImportedCustomers] Error fetching imported customers:', err);
      enqueueSnackbar('Error fetching imported customers', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    console.log('[Customers] useEffect - calling fetchCustomers and fetchImportedCustomers');
    fetchCustomers();
    fetchImportedCustomers();
  }, [fetchCustomers, fetchImportedCustomers]);

  // Reset importedPage to 0 whenever debouncedImportedSearch changes
  useEffect(() => {
    console.log('[Customers] debouncedImportedSearch changed to:', debouncedImportedSearch);
    setImportedPage(0);
  }, [debouncedImportedSearch]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('[handleSelectAll] Event checked:', event.target.checked);
    if (event.target.checked) {
      setSelectedCustomers(customers.map(c => c._id));
    } else {
      setSelectedCustomers([]);
    }
  };

  const handleSelect = (id: string) => {
    console.log('[handleSelect] Toggling selection for id:', id);
    setSelectedCustomers(prev =>
      prev.includes(id) ? prev.filter(customerId => customerId !== id) : [...prev, id]
    );
  };

  const handleDeleteSelected = async () => {
    console.log('[handleDeleteSelected] Deleting selected customers:', selectedCustomers);
    try {
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) {
        throw new Error('User data not found');
      }
      const userData: UserData = JSON.parse(userDataString);

      await axiosInstance.post('/api/customers/deleteMultiple', { 
        customerIds: selectedCustomers,
        companyName: userData.companyName
      });
      console.log('[handleDeleteSelected] Deletion successful');
      enqueueSnackbar('Selected customers deleted successfully', { variant: 'success' });
      setSelectedCustomers([]);
      fetchCustomers();
    } catch (error) {
      console.error('[handleDeleteSelected] Error deleting customers:', error);
      enqueueSnackbar('Error deleting customers', { variant: 'error' });
    }
  };

  const handleActionChange = (value: string) => {
    console.log('[handleActionChange] Action changed to:', value);
    setActionValue(value);
    if (value === 'delete') {
      setIsDeleteDialogOpen(true);
    } else if (value === 'export') {
      const selectedCustomersData = selectedCustomers.length > 0
        ? customers.filter(c => selectedCustomers.includes(c._id))
        : customers;
      
      const filename = `customers_${dayjs().format('YYYY-MM-DD')}.csv`;
      console.log('[handleActionChange] Exporting customers, filename:', filename);
      downloadCustomersCSV(selectedCustomersData, filename);
      
      enqueueSnackbar('Customers exported successfully', { variant: 'success' });
    }
    setActionValue('');
  };

  const actionOptions = [
    { value: '', label: 'Action' },
    { value: 'delete', label: 'Delete Selected' },
    { value: 'export', label: 'Export to CSV' }
  ];

  const sortCustomers = (customers: Customer[], column: SortColumn, direction: SortDirection) => {
    console.log('[sortCustomers] Sorting customers by:', column, direction);
    return [...customers].sort((a, b) => {
      let aValue: string | number;
      let bValue: string | number;

      switch (column) {
        case 'name':
          aValue = `${a.firstName} ${a.lastName}`;
          bValue = `${b.firstName} ${b.lastName}`;
          break;
        case 'address':
          aValue = `${a.address}, ${a.city}, ${a.state} ${a.zip}`;
          bValue = `${b.address}, ${b.city}, ${b.state} ${b.zip}`;
          break;
        case 'email':
          aValue = a.email;
          bValue = b.email;
          break;
        case 'phone':
          aValue = a.phone;
          bValue = b.phone;
          break;
        case 'acceptedAt':
          aValue = new Date(a.acceptedAt).getTime();
          bValue = new Date(b.acceptedAt).getTime();
          break;
        default:
          aValue = '';
          bValue = '';
      }

      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (column: SortColumn) => {
    console.log('[handleSort] Sorting by column:', column);
    setSortConfig(prevConfig => ({
      column,
      direction: prevConfig.column === column && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const sortedCustomers = sortCustomers(customers, sortConfig.column, sortConfig.direction);
  console.log('[Customers] sortedCustomers length:', sortedCustomers.length);

  const handleCustomerClick = (quoteId: string) => {
    console.log('[handleCustomerClick] Navigating to quote:', quoteId);
    navigate(`../internal/quote/${quoteId}`);
  };

  const handleSelectAllImported = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('[handleSelectAllImported] Event checked:', event.target.checked);
    if (event.target.checked) {
      setSelectedImportedCustomers(importedCustomers.map(c => c._id));
    } else {
      setSelectedImportedCustomers([]);
    }
  };

  const handleSelectImported = (id: string) => {
    console.log('[handleSelectImported] Toggling imported selection for id:', id);
    setSelectedImportedCustomers(prev =>
      prev.includes(id) ? prev.filter(customerId => customerId !== id) : [...prev, id]
    );
  };

  const handleDeleteImportedCustomer = async (id: string) => {
    console.log('[handleDeleteImportedCustomer] Deleting imported customer with id:', id);
    try {
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) throw new Error('User data not found');
      const userData: UserData = JSON.parse(userDataString);
      await axiosInstance.delete(`/api/importedCustomers/${id}?companyName=${encodeURIComponent(userData.companyName)}`);
      console.log('[handleDeleteImportedCustomer] Deletion successful for id:', id);
      enqueueSnackbar('Imported customer deleted successfully', { variant: 'success' });
      fetchImportedCustomers();
    } catch (error) {
      console.error('[handleDeleteImportedCustomer] Error deleting imported customer:', error);
      enqueueSnackbar('Error deleting imported customer', { variant: 'error' });
    }
  };

  const handleDeleteImportedSelected = async () => {
    console.log('[handleDeleteImportedSelected] Deleting selected imported customers:', selectedImportedCustomers);
    try {
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) throw new Error('User data not found');
      const userData: UserData = JSON.parse(userDataString);
      await axiosInstance.post('/api/importedCustomers/deleteMultiple', {
        customerIds: selectedImportedCustomers,
        companyName: userData.companyName
      });
      console.log('[handleDeleteImportedSelected] Deletion successful for selected imported customers');
      enqueueSnackbar('Selected imported customers deleted successfully', { variant: 'success' });
      setSelectedImportedCustomers([]);
      fetchImportedCustomers();
    } catch (error) {
      console.error('[handleDeleteImportedSelected] Error deleting imported customers:', error);
      enqueueSnackbar('Error deleting imported customers', { variant: 'error' });
    }
  };

  const handleEditImportedCustomer = (customer: ImportedCustomer) => {
    console.log('[handleEditImportedCustomer] Editing customer:', customer);
    setEditCustomer(customer);
    setIsEditModalOpen(true);
  };

  const handleSaveEditCustomer = async () => {
    console.log('[handleSaveEditCustomer] Saving edited customer:', editCustomer);
    if (!editCustomer) return;
    try {
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) throw new Error('User data not found');
      const userData: UserData = JSON.parse(userDataString);
      await axiosInstance.put(
        `/api/importedCustomers/${editCustomer._id}?companyName=${encodeURIComponent(userData.companyName)}`,
        editCustomer
      );
      console.log('[handleSaveEditCustomer] Save successful for customer:', editCustomer._id);
      enqueueSnackbar('Imported customer updated successfully', { variant: 'success' });
      setIsEditModalOpen(false);
      setEditCustomer(null);
      fetchImportedCustomers();
    } catch (error) {
      console.error('[handleSaveEditCustomer] Error updating imported customer:', error);
      enqueueSnackbar('Error updating imported customer', { variant: 'error' });
    }
  };

  const handleImportSubmit = async () => {
    console.log('[handleImportSubmit] Submitting CSV import');
    if (!importCsvFile) {
      enqueueSnackbar('Please upload a valid CSV file', { variant: 'warning' });
      return;
    }
    try {
      setImportLoading(true);
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) throw new Error('User data not found');
      const userData: UserData = JSON.parse(userDataString);
      const formData = new FormData();
      formData.append('importCsv', importCsvFile);
      formData.append('companyName', userData.companyName);
      await axiosInstance.post('/api/importedCustomers/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('[handleImportSubmit] CSV import successful');
      enqueueSnackbar('Imported customers added successfully', { variant: 'success' });
      setIsImportModalOpen(false);
      setImportCsvFile(null);
      setImportCsvPreview(null);
      setImportMissingHeaders([]);
      fetchImportedCustomers();
    } catch (error: any) {
      console.error('[handleImportSubmit] Error importing customers:', error);
      enqueueSnackbar('Error importing customers', { variant: 'error' });
    } finally {
      setImportLoading(false);
    }
  };

  const handleRegularChangePage = (event: unknown, newPage: number) => {
    console.log('[handleRegularChangePage] Changing page to:', newPage);
    setRegularPage(newPage);
  };
  const handleRegularChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('[handleRegularChangeRowsPerPage] Changing rows per page to:', event.target.value);
    setRegularRowsPerPage(parseInt(event.target.value, 10));
    setRegularPage(0);
  };

  const handleImportedChangePage = (event: unknown, newPage: number) => {
    console.log('[handleImportedChangePage] Changing imported page to:', newPage);
    setImportedPage(newPage);
  };
  const handleImportedChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('[handleImportedChangeRowsPerPage] Changing imported rows per page to:', event.target.value);
    setImportedRowsPerPage(parseInt(event.target.value, 10));
    setImportedPage(0);
  };

  const paginatedRegularCustomers = sortedCustomers.slice(regularPage * regularRowsPerPage, regularPage * regularRowsPerPage + regularRowsPerPage);
  const filteredImportedCustomers = importedCustomers.filter((cust) =>
    (`${cust.FirstName} ${cust.LastName}`).toLowerCase().includes(debouncedImportedSearch.toLowerCase())
  );
  const paginatedImportedCustomers = filteredImportedCustomers.slice(importedPage * importedRowsPerPage, importedPage * importedRowsPerPage + importedRowsPerPage);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`customers-tabpanel-${index}`}
        aria-labelledby={`customers-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
      </div>
    );
  }

  if (error) {
    console.log('[Customers] Rendering error:', error);
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', bgcolor: 'background.default' }}>
      <Box sx={{ position: 'relative', width: '100%', p: 2, overflowY: 'auto' }}>
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'rgba(255,255,255,0.7)'
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Typography variant="h4" gutterBottom align="center">Customers</Typography>
        
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => {
            console.log('[Tabs] Changing activeTab to:', newValue);
            setActiveTab(newValue);
          }}
          sx={{ mb: 3, '& .MuiTabs-indicator': { backgroundColor: 'warning.main' } }}
          textColor="inherit"
        >
          <Tab label="CowboyQuote Customers"  
          sx={(theme) => ({
                  color: 'warning.main',
                  '&.Mui-selected': { color: 'warning.main' },
                  '&:hover': { color: alpha(theme.palette.common.white, 1), backgroundColor: alpha(theme.palette.warning.main, 0.8), cursor: 'pointer' },
                })} />
          <Tab label="Imported Customers"  sx={(theme) => ({
                  color: 'warning.main',
                  '&.Mui-selected': { color: 'warning.main' },
                  '&:hover': { color: alpha(theme.palette.common.white, 1), backgroundColor: alpha(theme.palette.warning.main, 0.8), cursor: 'pointer' },
                })}/>
        </Tabs>

        <TabPanel value={activeTab} index={0}>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <CustomDropdown
              label=""
              value={actionValue}
              onChange={handleActionChange}
              options={actionOptions}
              id="customer-actions"
            />
          </Box>
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                console.log('[DatePicker] Start Date changed to:', newValue);
                setStartDate(newValue);
              }}
              slotProps={{ textField: { size: 'small', 
                sx: {
                  mr: 1,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'grey.400' },
                    '&:hover fieldset': { borderColor: 'warning.light' },
                    '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                  },
                },
                InputLabelProps: { sx: { '&.Mui-focused': { color: 'warning.main' } } }
              }}}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => {
                console.log('[DatePicker] End Date changed to:', newValue);
                setEndDate(newValue);
              }}
              slotProps={{ textField: { size: 'small', 
                sx: {
                  mr: 1,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'grey.400' },
                    '&:hover fieldset': { borderColor: 'warning.light' },
                    '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                  },
                },
                InputLabelProps: { sx: { '&.Mui-focused': { color: 'warning.main' } } }
              }}}
            />
            <IconButton onClick={() => {
              console.log('[IconButton] Filter clicked, calling fetchCustomers');
              fetchCustomers();
            }} size="small" sx={{ ml: 1, width:40 }}>
              <FilterList />
            </IconButton>
          </Box>
          {sortedCustomers.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={selectedCustomers.length > 0 && selectedCustomers.length < customers.length}
                          checked={selectedCustomers.length === customers.length}
                          onChange={handleSelectAll}
                          sx={{ '&.Mui-checked': { color: 'warning.main' } }}
                        />
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.column === 'name'}
                          direction={sortConfig.column === 'name' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('name')}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.column === 'address'}
                          direction={sortConfig.column === 'address' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('address')}
                        >
                          Address
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.column === 'email'}
                          direction={sortConfig.column === 'email' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('email')}
                        >
                          Email
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.column === 'phone'}
                          direction={sortConfig.column === 'phone' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('phone')}
                        >
                          Phone
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.column === 'acceptedAt'}
                          direction={sortConfig.column === 'acceptedAt' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('acceptedAt')}
                        >
                          Accepted Date
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedRegularCustomers.map((customer) => (
                      <TableRow key={customer._id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedCustomers.includes(customer._id)}
                            onChange={() => handleSelect(customer._id)}
                            sx={{ '&.Mui-checked': { color: 'warning.main' } }}
                          />
                        </TableCell>
                        <TableCell 
                          onClick={() => handleCustomerClick(customer.quoteId)}
                          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                        >
                          {`${customer.firstName} ${customer.lastName}`}
                        </TableCell>
                        <TableCell>{`${customer.address}, ${customer.city}, ${customer.state} ${customer.zip}`}</TableCell>
                        <TableCell>{customer.email}</TableCell>
                        <TableCell>{customer.phone}</TableCell>
                        <TableCell>{new Date(customer.acceptedAt).toLocaleDateString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={sortedCustomers.length}
                page={regularPage}
                onPageChange={handleRegularChangePage}
                rowsPerPage={regularRowsPerPage}
                onRowsPerPageChange={handleRegularChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                sx={{
                  '& .MuiTablePagination-selectIcon': { color: 'warning.main' },
                  '& .MuiTablePagination-actions button': { color: 'warning.main', width: '40px' },
                  '& .MuiTablePagination-displayedRows': { color: 'warning.main' },
                }}
              />
            </>
          ) : (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" align="center">No Customers</Typography>
            </Box>
          )}
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Typography variant="h6" gutterBottom align="left">Imported Customers</Typography>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ImportedCustomersSearch onSearch={setDebouncedImportedSearch} />
            <Button variant="contained" color="warning" sx={{width:200}} onClick={() => {
              console.log('[Button] Import Customers clicked');
              setIsImportModalOpen(true);
            }}>
              Import Customers
            </Button>
          </Box>
          {filteredImportedCustomers.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={selectedImportedCustomers.length > 0 && selectedImportedCustomers.length < importedCustomers.length}
                          checked={selectedImportedCustomers.length === importedCustomers.length}
                          onChange={handleSelectAllImported}
                          sx={{ '&.Mui-checked': { color: 'warning.main' } }}
                        />
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Physical Address</TableCell>
                      <TableCell>Client Since</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedImportedCustomers.map((cust) => (
                      <TableRow key={cust._id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedImportedCustomers.includes(cust._id)}
                            onChange={() => handleSelectImported(cust._id)}
                            sx={{ '&.Mui-checked': { color: 'warning.main' } }}
                          />
                        </TableCell>
                        <TableCell>{`${cust.FirstName} ${cust.LastName}`}</TableCell>
                        <TableCell>{cust.Email}</TableCell>
                        <TableCell>{`${cust.PhysicalAddress}, ${cust.PhysicalCity}, ${cust.PhysicalState} ${cust.PhysicalZip}`}</TableCell>
                        <TableCell>{cust.ClientSince}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEditImportedCustomer(cust)} size="small" color="warning" sx={{width:40}}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteImportedCustomer(cust._id)} size="small" color="error" sx={{width:40}}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={filteredImportedCustomers.length}
                page={importedPage}
                onPageChange={handleImportedChangePage}
                rowsPerPage={importedRowsPerPage}
                onRowsPerPageChange={handleImportedChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                sx={{
                  '& .MuiTablePagination-selectIcon': { color: 'warning.main' },
                  '& .MuiTablePagination-actions button': { color: 'warning.main', width: '40px' },
                  '& .MuiTablePagination-displayedRows': { color: 'warning.main' },
                }}
              />
              {selectedImportedCustomers.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Button variant="contained" color="error" onClick={handleDeleteImportedSelected}>
                    Delete Selected
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" align="center">No Imported Customers</Typography>
            </Box>
          )}
        </TabPanel>

        <Dialog
          open={isDeleteDialogOpen}
          onClose={() => {
            console.log('[Dialog] Delete dialog closed');
            setIsDeleteDialogOpen(false);
          }}
          aria-labelledby="delete-confirmation-dialog-title"
          aria-describedby="delete-confirmation-dialog-description"
        >
          <DialogTitle id="delete-confirmation-dialog-title">Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-confirmation-dialog-description">
              Are you sure you want to delete the selected customers? This action will delete all their quotes and remove their data permanently.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              console.log('[Dialog] Cancel deletion');
              setIsDeleteDialogOpen(false);
            }} color="info" variant="contained" sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button onClick={() => {
              console.log('[Dialog] Confirm deletion');
              setIsDeleteDialogOpen(false);
              handleDeleteSelected();
            }} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isImportModalOpen} onClose={() => {
          console.log('[Dialog] Import modal closed');
          setIsImportModalOpen(false);
        }} maxWidth="md" fullWidth>
          <DialogTitle>Import Customers CSV</DialogTitle>
          <DialogContent>
            <Box
              {...getImportRootProps()}
              sx={{
                border: '2px dashed',
                borderColor: 'grey.400',
                borderRadius: 2,
                p: 4,
                textAlign: 'center',
                cursor: 'pointer'
              }}
            >
              <input {...getImportInputProps()} />
              <Typography variant="h6" gutterBottom>
                {isImportDragActive ? 'Drop CSV here' : 'Drag & Drop CSV or Click to Upload'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Required columns: {requiredImportHeaders.join(', ')}
              </Typography>
            </Box>
            {importCsvPreview && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2">CSV Preview</Typography>
                <Box sx={{ maxHeight: 200, overflow: 'auto', border: '1px solid grey', borderRadius: 1 }}>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        {importCsvPreview.headers.map((header, index) => (
                          <th
                            key={index}
                            style={{
                              padding: '8px',
                              borderBottom: '2px solid grey',
                              textAlign: 'left',
                              color: importMissingHeaders.includes(header) ? 'red' : 'inherit'
                            }}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {importCsvPreview.sampleRows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex} style={{ padding: '8px', borderBottom: '1px solid grey', fontSize: '0.875rem' }}>
                              {cell || 'empty'}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              console.log('[Dialog] Import modal cancelled');
              setIsImportModalOpen(false);
            }} variant="contained" color="error">
              Cancel
            </Button>
            <Button onClick={handleImportSubmit} variant="contained" color="warning" disabled={importLoading || importMissingHeaders.length > 0}>
              {importLoading ? <CircularProgress size={24} /> : 'Upload CSV'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isEditModalOpen}
          onClose={() => { 
            console.log('[Dialog] Edit modal closed');
            setIsEditModalOpen(false); 
            setEditCustomer(null); 
          }}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Edit Imported Customer</DialogTitle>
          <DialogContent>
            {editCustomer && (
              <>
                <TextField
                  label="First Name"
                  value={editCustomer.FirstName}
                  onChange={(e) => {
                    console.log('[EditModal] First Name changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, FirstName: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Last Name"
                  value={editCustomer.LastName}
                  onChange={(e) => {
                    console.log('[EditModal] Last Name changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, LastName: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Email"
                  value={editCustomer.Email}
                  onChange={(e) => {
                    console.log('[EditModal] Email changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, Email: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Physical Address"
                  value={editCustomer.PhysicalAddress}
                  onChange={(e) => {
                    console.log('[EditModal] Physical Address changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, PhysicalAddress: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Physical City"
                  value={editCustomer.PhysicalCity}
                  onChange={(e) => {
                    console.log('[EditModal] Physical City changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, PhysicalCity: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Physical State"
                  value={editCustomer.PhysicalState}
                  onChange={(e) => {
                    console.log('[EditModal] Physical State changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, PhysicalState: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Physical Zip"
                  value={editCustomer.PhysicalZip}
                  onChange={(e) => {
                    console.log('[EditModal] Physical Zip changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, PhysicalZip: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Name On Invoice"
                  value={editCustomer.NameOnInvoice}
                  onChange={(e) => {
                    console.log('[EditModal] Name On Invoice changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, NameOnInvoice: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Billing Address"
                  value={editCustomer.BillingAddress}
                  onChange={(e) => {
                    console.log('[EditModal] Billing Address changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, BillingAddress: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Billing City"
                  value={editCustomer.BillingCity}
                  onChange={(e) => {
                    console.log('[EditModal] Billing City changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, BillingCity: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Billing State"
                  value={editCustomer.BillingState}
                  onChange={(e) => {
                    console.log('[EditModal] Billing State changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, BillingState: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Billing Zip"
                  value={editCustomer.BillingZip}
                  onChange={(e) => {
                    console.log('[EditModal] Billing Zip changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, BillingZip: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Longitude"
                  type="number"
                  value={editCustomer.Longitude}
                  onChange={(e) => {
                    console.log('[EditModal] Longitude changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, Longitude: parseFloat(e.target.value) });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Latitude"
                  type="number"
                  value={editCustomer.Latitude}
                  onChange={(e) => {
                    console.log('[EditModal] Latitude changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, Latitude: parseFloat(e.target.value) });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Home Phone"
                  value={editCustomer.HomePhone}
                  onChange={(e) => {
                    console.log('[EditModal] Home Phone changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, HomePhone: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Cell Phone"
                  value={editCustomer.CellPhone}
                  onChange={(e) => {
                    console.log('[EditModal] Cell Phone changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, CellPhone: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Client Since"
                  value={editCustomer.ClientSince}
                  onChange={(e) => {
                    console.log('[EditModal] Client Since changed to:', e.target.value);
                    setEditCustomer({ ...editCustomer, ClientSince: e.target.value });
                  }}
                  fullWidth
                  margin="normal"
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              console.log('[EditModal] Cancel editing');
              setIsEditModalOpen(false);
              setEditCustomer(null);
            }} variant="contained" color="error">
              Cancel
            </Button>
            <Button onClick={handleSaveEditCustomer} variant="contained" color="warning">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Customers;