import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable as FullCalendarDraggable } from '@fullcalendar/interaction';
import axiosInstance from '../utils/axiosConfig';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
} from 'react-beautiful-dnd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { debounce } from 'lodash';

// MUI imports
import { useTheme } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditIcon from '@mui/icons-material/Edit';
// NEW: Import icons for completed/reactivate actions
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';

// Import Leaflet and React-Leaflet for the project location map
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

interface Customer {
  _id: string;
  FirstName: string;
  LastName: string;
  Email?: string;
  PhysicalAddress?: string;
  PhysicalCity?: string;
  PhysicalState?: string;
  PhysicalZip?: string;
  NameOnInvoice?: string;
  BillingAddress?: string;
  BillingCity?: string;
  BillingState?: string;
  BillingZip?: string;
  Longitude?: number;
  Latitude?: number;
  HomePhone?: string;
  CellPhone?: string;
  ClientSince?: string;
}

interface ScheduledProject {
  _id: string;
  customerId: Customer;
  date: string;
  details: string;
  description: string;
  type: 'Big' | 'Small';
  companyName: string;
  isWaitingList: boolean;
  // NEW: Added isCompleted field
  isCompleted: boolean;
}

interface ProjectSchedulerProps {
  sidebarExpanded: boolean;
}

interface Event {
  title: string;
  start: string;
  id: string;
  extendedProps: {
    type: 'Big' | 'Small';
    // NEW: Include isCompleted so that FullCalendar can adjust styling
    isCompleted: boolean;
  };
}

const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};
const quillFormats = ['bold', 'italic', 'underline', 'list', 'bullet'];

interface ViewToggleProps {
  view: 'calendar' | 'kanban';
  setView: (view: 'calendar' | 'kanban') => void;
}

const ViewToggle: React.FC<ViewToggleProps> = ({ view, setView }) => {
  return (
    <div className="toggle-switch">
      <div className="toggle-option" onClick={() => setView('calendar')} style={{ alignContent: 'center' }}>
        <CalendarTodayIcon sx={{ color: view === 'calendar' ? 'white' : '#666', width: 20, mt: 0.4, pr: 0.3 }} />
      </div>
      <div className="toggle-option" onClick={() => setView('kanban')}>
        <ViewModuleIcon sx={{ color: view === 'kanban' ? 'white' : '#666', width: 35, mt: 0.4, pl: 2 }} />
      </div>
      <div className={`toggle-slider ${view === 'kanban' ? 'kanban' : ''}`}></div>
    </div>
  );
};

const parseLocalDate = (dateStr: string): Date => {
  const dateOnly = dateStr.split('T')[0];
  const [year, month, day] = dateOnly.split('-').map(Number);
  return new Date(year, month - 1, day);
};

const formatLocalDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

const getWeekStart = (date: Date): Date => {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day;
  return new Date(d.getFullYear(), d.getMonth(), diff);
};

interface KanbanViewProps {
  projects: ScheduledProject[];
  currentMonth: Date;
  onAddProject: (date: string) => void;
  onEventClick: (arg: { event: { id: string } }) => void;
  selectedProjectId?: string;
}

const KanbanView: React.FC<KanbanViewProps> = ({
  projects,
  currentMonth,
  onAddProject,
  onEventClick,
  selectedProjectId,
}) => {
  const today = new Date();
  const firstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  const lastDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
  const firstWeekStart = getWeekStart(firstDay);
  const weeks: Date[] = [];
  let weekStart = new Date(firstWeekStart);
  while (weekStart <= lastDay) {
    weeks.push(new Date(weekStart));
    weekStart = new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000);
  }

  const projectsByWeek: { [key: string]: ScheduledProject[] } = {};
  weeks.forEach((weekStart) => {
    const weekEnd = new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000);
    const projectsInWeek = projects.filter((project) => {
      if (project.isWaitingList) return false;
      const projectDate = parseLocalDate(project.date);
      return projectDate >= weekStart && projectDate < weekEnd;
    });
    projectsByWeek[formatLocalDate(weekStart)] = projectsInWeek;
  });

  return (
    <div style={{ overflowX: 'auto', display: 'flex' }}>
      {weeks.map((weekStart) => {
        const weekKey = formatLocalDate(weekStart);
        const projectsInWeek = projectsByWeek[weekKey] || [];
        const weekEnd = new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000);
        const isCurrentWeek = today >= weekStart && today < weekEnd;
        const totalHours = projectsInWeek.reduce((sum, project) => {
          const hours = parseFloat(project.details);
          return sum + (isNaN(hours) ? 0 : hours);
        }, 0);
        return (
          <Droppable key={weekKey} droppableId={weekKey}>
            {(provided: DroppableProvided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="week-column"
                style={{
                  flex: '0 0 310px',
                  margin: '0 10px',
                  padding: '10px',
                  border: isCurrentWeek ? '3px solid #f37121' : '1px solid #ccc',
                }}
              >
                <h3>Week of {weekStart.toDateString()}</h3>
                <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                  Total Hours: {totalHours}
                </div>
                {projectsInWeek.map((project, index) => (
                  <Draggable key={project._id} draggableId={project._id} index={index}>
                    {(provided: DraggableProvided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`project-chip ${
                          project.isCompleted
                            ? 'completed-project'
                            : project.type === 'Big'
                            ? 'big-job'
                            : 'small-job'
                        }`}
                        onClick={() => onEventClick({ event: { id: project._id } })}
                        style={{
                          ...provided.draggableProps.style,
                          opacity:
                            selectedProjectId && selectedProjectId !== project._id ? 0.4 : 1,
                          // Override style for completed projects
                          backgroundColor: project.isCompleted
                            ? '#A9A9A9'
                            : project.type === 'Big'
                            ? 'rgb(171, 95, 95)'
                            : 'rgb(102, 133, 102)',
                          color: project.isCompleted ? 'black' : '#fff',
                        }}
                      >
                        {project.customerId.FirstName} {project.customerId.LastName} ({project.details})
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <button
                  onClick={() => onAddProject(weekKey)}
                  style={{
                    marginTop: '10px',
                    padding: '10px 10px',
                    cursor: 'pointer',
                    backgroundColor: '#C6623A',
                  }}
                >
                  +
                </button>
              </div>
            )}
          </Droppable>
        );
      })}
    </div>
  );
};

// New helper component to update the map center when coordinates change.
const RecenterAutomatically: React.FC<{ lat: number; lng: number; zoom: number }> = ({ lat, lng, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng], zoom);
  }, [lat, lng, zoom, map]);
  return null;
};

const ProjectScheduler: React.FC<ProjectSchedulerProps> = ({ sidebarExpanded }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const [projects, setProjects] = useState<ScheduledProject[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [clickedDate, setClickedDate] = useState<string | null>(null);
  const [isWaitingListProject, setIsWaitingListProject] = useState(false);
  const [formData, setFormData] = useState<{
    customerId: string;
    details: string;
    description: string;
    type: 'Big' | 'Small';
    projectId?: string;
  }>({ customerId: '', details: '', description: '', type: 'Big', projectId: undefined });
  const [view, setView] = useState<'calendar' | 'kanban'>('calendar');
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [customerSearch, setCustomerSearch] = useState<string>('');
  const [waitingListActive, setWaitingListActive] = useState(false);
  const calendarRef = useRef<FullCalendar>(null);
  const waitingListRef = useRef<HTMLDivElement>(null);
  const [waitingListSortField, setWaitingListSortField] = useState<'hours' | 'name' | 'jobSize'>('name');
  const [waitingListSortOrder, setWaitingListSortOrder] = useState<'asc' | 'desc'>('asc');

  const [selectedProject, setSelectedProject] = useState<ScheduledProject | null>(null);
  const [projectPanelActive, setProjectPanelActive] = useState(false);

  // NEW CUSTOMER MODAL STATES & ADDRESS AUTOCOMPLETE
  const [isNewCustomerModalOpen, setIsNewCustomerModalOpen] = useState<boolean>(false);
  const [newCustomerForm, setNewCustomerForm] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    PhysicalAddress: '',
    PhysicalCity: '',
    PhysicalState: '',
    PhysicalZip: '',
    NameOnInvoice: '',
    BillingAddress: '',
    BillingCity: '',
    BillingState: '',
    BillingZip: '',
    HomePhone: '',
    CellPhone: '',
    ClientSince: '',
    Longitude: 0,
    Latitude: 0,
  });
  const [billingDiff, setBillingDiff] = useState<boolean>(false);
  const [newCustomerAddressInput, setNewCustomerAddressInput] = useState<string>('');
  const [newCustomerAddressSuggestions, setNewCustomerAddressSuggestions] = useState<any[]>([]);
  const [newCustomerAddressLoading, setNewCustomerAddressLoading] = useState<boolean>(false);

  // NEW: State for the confirmation modal when marking as completed
  const [showCompletionConfirmModal, setShowCompletionConfirmModal] = useState<boolean>(false);

  const debouncedFetchCustomerAddressSuggestions = useRef(
    debounce(async (input: string) => {
      if (input.length < 3) {
        setNewCustomerAddressSuggestions([]);
        return;
      }
      setNewCustomerAddressLoading(true);
      try {
        const response = await axiosInstance.get('/api/locationiq/search', { params: { q: input } });
        setNewCustomerAddressSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching address suggestions for new customer:', error);
      } finally {
        setNewCustomerAddressLoading(false);
      }
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedFetchCustomerAddressSuggestions.cancel();
    };
  }, [debouncedFetchCustomerAddressSuggestions]);

  // Removed manual dark mode state in favor of MUI theme
  useEffect(() => {
    if (view === 'kanban') {
      setCurrentMonth(new Date());
    }
  }, [view]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataString = localStorage.getItem('userData');
        if (!userDataString) throw new Error('User data not found');
        const userData = JSON.parse(userDataString);
        const companyName = userData.companyName;
        const projectsResponse = await axiosInstance.get<ScheduledProject[]>(
          `/api/scheduledProjects?companyName=${companyName}`
        );
        setProjects(projectsResponse.data);
        const customersResponse = await axiosInstance.get<Customer[]>(
          `/api/importedCustomers?companyName=${companyName}`
        );
        setCustomers(customersResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  // Updated events mapping to include isCompleted
  const events: Event[] = projects
    .filter((project) => !project.isWaitingList)
    .map((project) => ({
      title: `${project.customerId.FirstName} ${project.customerId.LastName} (${project.details})`,
      start: project.date,
      id: project._id,
      extendedProps: { type: project.type, isCompleted: project.isCompleted },
    }));

  const waitingListProjects = projects.filter((p) => p.isWaitingList);
  const sortedWaitingListProjects = [...waitingListProjects].sort((a, b) => {
    let comparison = 0;
    switch (waitingListSortField) {
      case 'hours': {
        const aHours = parseFloat(a.details) || 0;
        const bHours = parseFloat(b.details) || 0;
        comparison = aHours - bHours;
        break;
      }
      case 'name': {
        const aName = `${a.customerId.FirstName} ${a.customerId.LastName}`.toLowerCase();
        const bName = `${b.customerId.FirstName} ${b.customerId.LastName}`.toLowerCase();
        comparison = aName < bName ? -1 : aName > bName ? 1 : 0;
        break;
      }
      case 'jobSize': {
        const aType = a.type.toLowerCase();
        const bType = b.type.toLowerCase();
        comparison = aType < bType ? -1 : aType > bType ? 1 : 0;
        break;
      }
      default:
        comparison = 0;
    }
    return waitingListSortOrder === 'asc' ? comparison : -comparison;
  });

  const handleDateClick = (arg: { dateStr: string }) => {
    setClickedDate(arg.dateStr);
    setSelectedDate(arg.dateStr);
    setFormData({ customerId: '', details: '', description: '', type: 'Big', projectId: undefined });
    setCustomerSearch('');
    setIsWaitingListProject(false);
    setIsFormOpen(true);
  };

  const handleEventClick = (arg: { event: { id: string } }) => {
    setClickedDate(null);
    const project = projects.find((p) => p._id === arg.event.id);
    if (project) {
      setSelectedProject(project);
      setTimeout(() => {
        setProjectPanelActive(true);
        setTimeout(() => {
          if (calendarRef.current) {
            calendarRef.current.getApi().updateSize();
          }
        }, 350);
      }, 0);
    }
  };

  const handleEventDrop = (arg: any) => {
    const project = projects.find((p) => p._id === arg.event.id);
    if (!project) return;
    const newDate = arg.event.startStr;
    handleUpdateProjectDate(project._id, newDate, false);
  };

  const handleEventDragStop = (arg: { event: any; el: HTMLElement; jsEvent: MouseEvent }) => {
    if (waitingListRef.current) {
      const rect = waitingListRef.current.getBoundingClientRect();
      const { clientX, clientY } = arg.jsEvent;
      if (clientX >= rect.left && clientX <= rect.right && clientY >= rect.top && clientY <= rect.bottom) {
        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          const fcEvent = calendarApi.getEventById(arg.event.id);
          if (fcEvent) {
            fcEvent.remove();
          }
        }
        handleUpdateProjectDate(arg.event.id, '', true);
        setProjects((prev) =>
          prev.map((p) =>
            p._id === arg.event.id ? { ...p, isWaitingList: true, date: '' } : p
          )
        );
      }
    }
  };

  const handleUpdateProjectDate = async (
    projectId: string,
    newDate: string,
    waitingList: boolean = false
  ) => {
    const previousProject = projects.find((p) => p._id === projectId);
    try {
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) throw new Error('User data not found');
      const userData = JSON.parse(userDataString);
      const companyName = userData.companyName;
      const project = projects.find((p) => p._id === projectId);
      if (!project) throw new Error('Project not found');
      const payload: any = {
        customerId: project.customerId._id,
        details: project.details,
        description: project.description,
        type: project.type,
        companyName,
        isWaitingList: waitingList,
      };
      payload.date = waitingList ? '' : newDate;
      const response = await axiosInstance.put<ScheduledProject>(
        `/api/scheduledProjects/${projectId}?companyName=${companyName}`,
        payload
      );
      setProjects((prev) =>
        prev.map((p) => (p._id === projectId ? response.data : p))
      );
    } catch (error) {
      console.error('Error updating project date:', error);
      if (previousProject) {
        setProjects((prev) =>
          prev.map((p) => (p._id === projectId ? previousProject : p))
        );
      }
    }
  };

  // NEW: Handler to toggle the isCompleted flag using the new route
  const handleToggleCompleted = async () => {
    if (!selectedProject) return;
    try {
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) throw new Error('User data not found');
      const userData = JSON.parse(userDataString);
      const companyName = userData.companyName;
      const newStatus = !selectedProject.isCompleted;
      const response = await axiosInstance.put<ScheduledProject>(
        `/api/scheduledProjects/${selectedProject._id}/completed?companyName=${companyName}`,
        { isCompleted: newStatus }
      );
      setProjects((prev) =>
        prev.map((p) => (p._id === selectedProject._id ? response.data : p))
      );
      setSelectedProject(response.data);
    } catch (error) {
      console.error('Error toggling project completion:', error);
    }
  };

  // NEW: Helper function to close the project panel and update the calendar size.
  const closeProjectPanel = () => {
    setProjectPanelActive(false);
    setTimeout(() => {
      setSelectedProject(null);
      if (calendarRef.current) {
        calendarRef.current.getApi().updateSize();
      }
    }, 300);
  };

  // NEW: Handler for confirming project completion from the confirmation modal.
  const handleCompletionConfirm = async () => {
    await handleToggleCompleted();
    setShowCompletionConfirmModal(false);
    closeProjectPanel();
  };

  const handleDragEndWrapper = (result: DropResult) => {
    if (!result.destination) return;
    const sourceId = result.source.droppableId;
    const destinationId = result.destination.droppableId;
    const projectId = result.draggableId;
    const project = projects.find((p) => p._id === projectId);
    if (!project) return;
    if (sourceId === 'waitingList' && destinationId !== 'waitingList') {
      handleUpdateProjectDate(projectId, destinationId, false);
    } else if (sourceId !== 'waitingList' && destinationId === 'waitingList') {
      handleUpdateProjectDate(projectId, '', true);
    } else if (sourceId !== 'waitingList' && destinationId !== 'waitingList') {
      const sourceWeekDate = parseLocalDate(sourceId);
      const projectDate = parseLocalDate(project.date);
      const offsetDaysNumber = Math.round((projectDate.getTime() - sourceWeekDate.getTime()) / (24 * 60 * 60 * 1000));
      const destinationWeekDate = parseLocalDate(destinationId);
      destinationWeekDate.setDate(destinationWeekDate.getDate() + offsetDaysNumber);
      const newDate = formatLocalDate(destinationWeekDate);
      handleUpdateProjectDate(projectId, newDate, false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) throw new Error('User data not found');
      const userData = JSON.parse(userDataString);
      const companyName = userData.companyName;
      const payload: any = {
        customerId: formData.customerId,
        details: formData.details,
        description: formData.description,
        type: formData.type,
        companyName,
        isWaitingList: isWaitingListProject,
      };
      if (!isWaitingListProject) payload.date = selectedDate;
      if (formData.projectId) {
        const response = await axiosInstance.put<ScheduledProject>(
          `/api/scheduledProjects/${formData.projectId}?companyName=${companyName}`,
          payload
        );
        setProjects((prev) =>
          prev.map((p) => (p._id === response.data._id ? response.data : p))
        );
        if (selectedProject && selectedProject._id === response.data._id) {
          setSelectedProject(response.data);
        }
      } else {
        const response = await axiosInstance.post<ScheduledProject>(
          '/api/scheduledProjects',
          payload
        );
        setProjects((prev) => [...prev, response.data]);
      }
      setIsFormOpen(false);
      setIsWaitingListProject(false);
    } catch (error) {
      console.error('Error saving project:', error);
    }
  };

  const handleDelete = async () => {
    try {
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) throw new Error('User data not found');
      const userData = JSON.parse(userDataString);
      const companyName = userData.companyName;
      if (!formData.projectId) throw new Error('Project ID is missing');
      await axiosInstance.delete(
        `/api/scheduledProjects/${formData.projectId}?companyName=${companyName}`
      );
      setProjects((prev) => prev.filter((p) => p._id !== formData.projectId));
      setIsFormOpen(false);
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const handleAddProject = (date: string) => {
    setClickedDate(date);
    setSelectedDate(date);
    setFormData({ customerId: '', details: '', description: '', type: 'Big', projectId: undefined });
    setCustomerSearch('');
    setIsWaitingListProject(false);
    setIsFormOpen(true);
  };

  // Modified handleOpenEditFromPanel so that if editing a waiting list project,
  // the form does not require a date.
  const handleOpenEditFromPanel = () => {
    if (selectedProject) {
      setFormData({
        customerId: selectedProject.customerId._id,
        details: selectedProject.details,
        description: selectedProject.description,
        type: selectedProject.type,
        projectId: selectedProject._id,
      });
      setSelectedDate(selectedProject.date ? selectedProject.date.split('T')[0] : '');
      setCustomerSearch(`${selectedProject.customerId.FirstName} ${selectedProject.customerId.LastName}`);
      setIsWaitingListProject(selectedProject.isWaitingList);
      setIsFormOpen(true);
    }
  };

  const filteredCustomers = customers.filter((customer) =>
    `${customer.FirstName} ${customer.LastName}`.toLowerCase().includes(customerSearch.toLowerCase())
  );

  const handlePrev = () => {
    const newDate = new Date(currentMonth);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentMonth(newDate);
    if (view === 'calendar' && calendarRef.current) {
      calendarRef.current.getApi().prev();
    }
  };

  const handleNext = () => {
    const newDate = new Date(currentMonth);
    newDate.setMonth(newDate.getMonth() + 1);
    setCurrentMonth(newDate);
    if (view === 'calendar' && calendarRef.current) {
      calendarRef.current.getApi().next();
    }
  };

  const handleToday = () => {
    setCurrentMonth(new Date());
    if (view === 'calendar' && calendarRef.current) {
      calendarRef.current.getApi().today();
    }
  };

  useEffect(() => {
    if (projectPanelActive && calendarRef.current) {
      setTimeout(() => {
        calendarRef.current?.getApi().updateSize();
      }, 350);
    }
  }, [projectPanelActive]);

  useEffect(() => {
    const waitingListEl = document.getElementById('waiting-list-draggable');
    if (waitingListEl) {
      new FullCalendarDraggable(waitingListEl, {
        itemSelector: '.project-chip',
        eventData: function (el) {
          return {
            id: el.getAttribute('data-id'),
            title: el.innerText,
          };
        },
      });
    }
  }, [waitingListActive]);

  // NEW CUSTOMER MODAL SUBMIT HANDLER
  const handleNewCustomerSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // If billing address is not different, copy physical address fields
      const customerDataToSubmit = { ...newCustomerForm };
      if (!billingDiff) {
        customerDataToSubmit.BillingAddress = customerDataToSubmit.PhysicalAddress;
        customerDataToSubmit.BillingCity = customerDataToSubmit.PhysicalCity;
        customerDataToSubmit.BillingState = customerDataToSubmit.PhysicalState;
        customerDataToSubmit.BillingZip = customerDataToSubmit.PhysicalZip;
      }
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) throw new Error('User data not found');
      const userData = JSON.parse(userDataString);
      const companyName = userData.companyName;
      const response = await axiosInstance.post(
        `/api/importedCustomers?companyName=${companyName}`,
        customerDataToSubmit
      );
      // Add the new customer to the customers list
      setCustomers((prev) => [...prev, response.data]);
      // Set the customer in the project form to the newly created customer
      setFormData({ ...formData, customerId: response.data._id });
      setCustomerSearch(`${response.data.FirstName} ${response.data.LastName}`);
      setIsNewCustomerModalOpen(false);
      // Reset new customer form
      setNewCustomerForm({
        FirstName: '',
        LastName: '',
        Email: '',
        PhysicalAddress: '',
        PhysicalCity: '',
        PhysicalState: '',
        PhysicalZip: '',
        NameOnInvoice: '',
        BillingAddress: '',
        BillingCity: '',
        BillingState: '',
        BillingZip: '',
        HomePhone: '',
        CellPhone: '',
        ClientSince: '',
        Longitude: 0,
        Latitude: 0,
      });
      setNewCustomerAddressInput('');
      setBillingDiff(false);
    } catch (error) {
      console.error('Error creating new customer:', error);
    }
  };

  return (
    <>
      <div>
        <div
          className="custom-header"
          style={{
            marginLeft: waitingListActive ? '400px' : '0',
            transition: 'margin-left 0.3s ease 0.1s, opacity 0.3s ease 0.1s',
            opacity: waitingListActive ? 1 : 0.8,
          }}
        >
          <ViewToggle view={view} setView={setView} />
          <div className="navigation">
            <button onClick={handlePrev}>
              <ChevronLeftIcon />
            </button>
            <button onClick={handleToday} style={{ fontFamily: 'Poppins', fontWeight: '600' }}>
              Today
            </button>
            <button onClick={handleNext}>
              <ChevronRightIcon />
            </button>
          </div>
          <div className="waiting-list-toggle" style={{ marginTop: '10px', cursor: 'pointer' }}>
            {!waitingListActive && (
              <AccessTimeIcon
                fontSize="large"
                sx={{ paddingLeft: 1, paddingBottom: 1, color: isDark ? '#CBCBCB' : '#FF8651' }}
                onClick={() => {
                  setWaitingListActive(true);
                  setTimeout(() => {
                    if (calendarRef.current) {
                      calendarRef.current.getApi().updateSize();
                    }
                  }, 350);
                }}
              />
            )}
          </div>
        </div>
        <h2
          style={{
            textAlign: 'center',
            marginBottom: '12px',
            marginLeft: waitingListActive ? '400px' : '0',
            marginRight: projectPanelActive ? '400px' : '0',
            width: waitingListActive || projectPanelActive
              ? `calc(100% - ${(waitingListActive ? 400 : 0) + (projectPanelActive ? 400 : 0)}px)`
              : '100%',
          }}
        >
          {currentMonth.toLocaleDateString('default', { month: 'long', year: 'numeric' })}
        </h2>
        <div
          style={{
            transition: 'margin 0.3s ease, width 0.3s ease',
            marginLeft: waitingListActive ? '400px' : '0',
            marginRight: projectPanelActive ? '400px' : '0',
            width: waitingListActive || projectPanelActive
              ? `calc(100% - ${(waitingListActive ? 400 : 0) + (projectPanelActive ? 400 : 0)}px)`
              : '100%',
          }}
        >
          {view === 'calendar' ? (
            <div className={isDark ? 'dark-calendar' : ''}>
              <FullCalendar
                key={`fullcalendar-${selectedProject ? selectedProject._id : 'none'}`}
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                dateClick={handleDateClick}
                timeZone="local"
                eventClick={handleEventClick}
                eventDrop={handleEventDrop}
                eventDragStop={handleEventDragStop}
                editable={true}
                displayEventTime={false}
                height="auto"
                headerToolbar={false}
                initialDate={currentMonth}
                droppable={true}
                dragRevertDuration={0}
                drop={(info) => {
                  const projectId = info.draggedEl.getAttribute('data-id');
                  if (projectId) {
                    setProjects((prev) =>
                      prev.map((p) =>
                        p._id === projectId
                          ? { ...p, date: info.dateStr, isWaitingList: false }
                          : p
                      )
                    );
                    handleUpdateProjectDate(projectId, info.dateStr, false);
                  }
                }}
                // NEW: Override event class names if a project is completed
                eventClassNames={(arg) =>
                  arg.event.extendedProps.isCompleted
                    ? 'completed-project'
                    : arg.event.extendedProps.type === 'Big'
                    ? 'big-job'
                    : 'small-job'
                }
                eventDidMount={(info) => {
                  if (selectedProject && info.event.id !== selectedProject._id) {
                    info.el.style.opacity = '0.4';
                  } else {
                    info.el.style.opacity = '1';
                  }
                }}
                datesSet={(arg) => {
                  if (calendarRef.current) {
                    const calendarApi = calendarRef.current.getApi();
                    setCurrentMonth(calendarApi.getDate());
                  }
                }}
              />
            </div>
          ) : (
            <DragDropContext onDragEnd={handleDragEndWrapper}>
              <div style={{ display: 'flex' }}>
                <KanbanView
                  projects={projects}
                  currentMonth={currentMonth}
                  onAddProject={handleAddProject}
                  onEventClick={handleEventClick}
                  selectedProjectId={selectedProject ? selectedProject._id : undefined}
                />
              </div>
            </DragDropContext>
          )}
        </div>

        {/* Waiting List Panel */}
        <div
          ref={waitingListRef}
          id="waiting-list-draggable"
          className="waiting-list-panel"
          style={{
            position: 'fixed',
            top: 0,
            left: sidebarExpanded ? '220px' : '60px',
            width: '400px',
            height: '100%',
            background: isDark ? '#2c2c2d' : '#f9f9f9',
            boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
            zIndex: 100,
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            transform: waitingListActive ? 'translateX(0)' : 'translateX(-100%)',
            opacity: waitingListActive ? 1 : 0,
            pointerEvents: waitingListActive ? 'auto' : 'none',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
          }}
        >
          <div style={{ position: 'relative' }}>
            <button
              onClick={() => {
                setWaitingListActive(false);
                setTimeout(() => {
                  if (calendarRef.current) {
                    calendarRef.current.getApi().updateSize();
                  }
                }, 300);
              }}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                width: 30,
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                color: isDark ? '#e0e0e0' : '#000',
              }}
              aria-label="Close Waiting List"
            >
              <ChevronLeftIcon />
            </button>
            <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>Waiting List</h3>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              marginBottom: '10px',
              justifyContent: 'flex-start',
            }}
          >
            <label
              htmlFor="waitingListSort"
              style={{
                color: isDark ? 'white' : 'black',
                whiteSpace: 'nowrap',
                fontSize: '10px',
                fontFamily: 'poppins',
              }}
            >
              Sort by:
            </label>
            <select
              id="waitingListSort"
              value={waitingListSortField}
              onChange={(e) =>
                setWaitingListSortField(e.target.value as 'hours' | 'name' | 'jobSize')
              }
              style={{
                paddingBottom: '4px',
                borderRadius: '4px',
                minWidth: '20px',
                fontSize: '10px',
                fontFamily: 'Poppins',
                border: isDark ? '0px solid white' : '0px solid darkgray',
                background: isDark ? '#2c2c2d' : '#f9f9f9',
                color: isDark ? 'white' : 'black',
              }}
            >
              <option value="name">Name</option>
              <option value="hours">Hours</option>
              <option value="jobSize">Job Size</option>
            </select>
            <button
              onClick={() =>
                setWaitingListSortOrder(waitingListSortOrder === 'asc' ? 'desc' : 'asc')
              }
              style={{
                background: 'transparent',
                border: 'none',
                paddingLeft: '0px',
                width: 20,
                cursor: 'pointer',
              }}
            >
              {waitingListSortOrder === 'asc' ? (
                <ArrowDownwardIcon fontSize="small" />
              ) : (
                <ArrowUpwardIcon fontSize="small" />
              )}
            </button>
          </div>
          <div style={{ flex: 1, overflowY: 'auto', marginBottom: '10px' }}>
            {view === 'kanban' ? (
              <DragDropContext onDragEnd={handleDragEndWrapper}>
                <Droppable droppableId="waitingList">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} style={{ minHeight: '50px' }}>
                      {sortedWaitingListProjects.map((project, index) => (
                        <Draggable key={project._id} draggableId={project._id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              data-id={project._id}
                              className={`project-chip ${
                                project.isCompleted
                                  ? 'completed-project'
                                  : project.type === 'Big'
                                  ? 'big-job'
                                  : 'small-job'
                              }`}
                              onClick={() => handleEventClick({ event: { id: project._id } })}
                              style={{
                                ...provided.draggableProps.style,
                                opacity: selectedProject && selectedProject._id !== project._id ? 0.4 : 1,
                                backgroundColor: project.isCompleted
                                  ? '#A9A9A9'
                                  : project.type === 'Big'
                                  ? 'rgb(171, 95, 95)'
                                  : 'rgb(102, 133, 102)',
                                color: project.isCompleted ? 'black' : '#fff',
                              }}
                            >
                              {project.customerId.FirstName} {project.customerId.LastName} ({project.details})
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              sortedWaitingListProjects.map((project) => (
                <div
                  key={project._id}
                  data-id={project._id}
                  className={`project-chip ${
                    project.isCompleted
                      ? 'completed-project'
                      : project.type === 'Big'
                      ? 'big-job'
                      : 'small-job'
                  }`}
                  style={{
                    marginBottom: '5px',
                    cursor: 'pointer',
                    opacity: selectedProject && selectedProject._id !== project._id ? 0.4 : 1,
                    backgroundColor: project.isCompleted
                      ? '#A9A9A9'
                      : project.type === 'Big'
                      ? 'rgb(171, 95, 95)'
                      : 'rgb(102, 133, 102)',
                    color: project.isCompleted ? 'black' : '#fff',
                  }}
                  onClick={() => handleEventClick({ event: { id: project._id } })}
                >
                  {project.customerId.FirstName} {project.customerId.LastName} ({project.details})
                </div>
              ))
            )}
          </div>
          <button
            onClick={() => {
              setFormData({ customerId: '', details: '', description: '', type: 'Big', projectId: undefined });
              setCustomerSearch('');
              setIsWaitingListProject(true);
              setIsFormOpen(true);
            }}
            style={{
              padding: '10px',
              backgroundColor: '#f37121',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontFamily: 'poppins',
              fontWeight: '600',
              width: '100%',
            }}
          >
            Add New Waiting List Project
          </button>
        </div>

        {/* Modal for Adding/Editing Projects */}
        {isFormOpen && (
          <div
            onClick={() => setIsFormOpen(false)}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1000,
            }}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              style={{
                background: isDark ? '#1e1e1e' : '#fff',
                borderRadius: '8px',
                padding: '30px',
                width: '90%',
                maxWidth: '800px',
                maxHeight: '80vh',
                overflowY: 'auto',
                boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                position: 'relative',
                color: isDark ? '#e0e0e0' : '#000',
              }}
            >
              {/* NEW: Toggle completion button */}
              {formData.projectId && !isWaitingListProject && (
                <button
                  onClick={() => {
                    if (selectedProject && !selectedProject.isCompleted) {
                      setShowCompletionConfirmModal(true);
                    } else {
                      handleToggleCompleted();
                    }
                  }}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    width: 30,
                    background: 'transparent',
                    color: isDark ? '#e0e0e0' : '#000',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  title={selectedProject && selectedProject.isCompleted ? "Reactivate project" : "Mark as completed"}
                >
                  {selectedProject && selectedProject.isCompleted ? <AddIcon /> : <CheckCircleIcon />}
                </button>
              )}
              <button
                onClick={() => setIsFormOpen(false)}
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '20px',
                  width: 30,
                  background: 'transparent',
                  border: 'none',
                  fontSize: '1.5rem',
                  cursor: 'pointer',
                  color: isDark ? '#e0e0e0' : '#000',
                }}
                aria-label="Close"
              >
                ×
              </button>
              <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>
                {formData.projectId
                  ? 'Edit Project'
                  : isWaitingListProject
                  ? 'Add New Waiting List Project'
                  : 'New Project'}
              </h2>
              <form onSubmit={handleSubmit}>
                {!isWaitingListProject && (
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Date:
                    </label>
                    <input
                      type="date"
                      value={selectedDate || ''}
                      onChange={(e) => setSelectedDate(e.target.value)}
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                )}
                <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '90%' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Customer:
                    </label>
                    <input
                      type="text"
                      value={customerSearch}
                      onChange={(e) => setCustomerSearch(e.target.value)}
                      placeholder="Search customers"
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                    {customerSearch.length >= 3 && (
                      <div
                        style={{
                          maxHeight: '200px',
                          overflowY: 'auto',
                          border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                          marginTop: '5px',
                        }}
                      >
                        {filteredCustomers.map((customer) => (
                          <div
                            key={customer._id}
                            onClick={() => {
                              setFormData({ ...formData, customerId: customer._id });
                              setCustomerSearch(`${customer.FirstName} ${customer.LastName}`);
                            }}
                            style={{
                              padding: '5px',
                              cursor: 'pointer',
                              background:
                                formData.customerId === customer._id
                                  ? isDark
                                    ? '#444'
                                    : '#e0e0e0'
                                  : 'transparent',
                            }}
                          >
                            {customer.FirstName} {customer.LastName}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div style={{ width: '10%', marginLeft: '10px' }}>
                    <button
                      type="button"
                      onClick={() => setIsNewCustomerModalOpen(true)}
                      style={{
                        backgroundColor: isDark ? '#FFA725' : '#f37121',
                        border: 'none',
                        color: isDark ? '#000' : '#fff',
                        marginTop: '28px',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                        padding: '10px',
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                   Hours:
                  </label>
                  <input
                    type="text"
                    value={formData.details}
                    onChange={(e) =>
                      setFormData({ ...formData, details: e.target.value })
                    }
                    required
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                      borderRadius: '4px',
                      background: isDark ? '#2c2c2c' : '#fff',
                      color: isDark ? '#e0e0e0' : '#000',
                    }}
                  />
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                    Project Description:
                  </label>
                  <div className="rich-text-editor-wrapper">
                    <ReactQuill
                      value={formData.description}
                      onChange={(value) =>
                        setFormData({ ...formData, description: value })
                      }
                      modules={quillModules}
                      formats={quillFormats}
                    />
                  </div>
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                    Project Type:
                  </label>
                  <select
                    value={formData.type}
                    onChange={(e) =>
                      setFormData({ ...formData, type: e.target.value as 'Big' | 'Small' })
                    }
                    required
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                      borderRadius: '4px',
                      background: isDark ? '#2c2c2c' : '#fff',
                      color: isDark ? '#e0e0e0' : '#000',
                    }}
                  >
                    <option value="Big">Big Job</option>
                    <option value="Small">Small Job</option>
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', gap: '10px' }}>
                  <button
                    type="button"
                    onClick={() => setIsFormOpen(false)}
                    style={{
                      padding: '10px 20px',
                      border: 'none',
                      borderRadius: '4px',
                      backgroundColor: '#FFA725',
                      color: '#000',
                      cursor: 'pointer',
                      fontFamily: 'poppins',
                      width: formData.projectId ? '33%' : '50%',
                    }}
                  >
                    Cancel
                  </button>
                  {formData.projectId && (
                    <button
                      type="button"
                      onClick={handleDelete}
                      style={{
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '4px',
                        backgroundColor: '#dc3545',
                        color: '#fff',
                        cursor: 'pointer',
                        fontFamily: 'poppins',
                        width: '33%',
                      }}
                    >
                      Delete
                    </button>
                  )}
                  <button
                    type="submit"
                    style={{
                      padding: '10px 20px',
                      border: 'none',
                      borderRadius: '4px',
                      backgroundColor: '#064454',
                      color: '#fff',
                      cursor: 'pointer',
                      fontFamily: 'poppins',
                      width: formData.projectId ? '33%' : '50%',
                    }}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      {/* New Customer Modal */}
      {isNewCustomerModalOpen && (
        <div
          onClick={() => setIsNewCustomerModalOpen(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1100,
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              background: isDark ? '#1e1e1e' : '#fff',
              borderRadius: '8px',
              padding: '30px',
              width: '90%',
              maxWidth: '800px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
              position: 'relative',
              color: isDark ? '#e0e0e0' : '#000',
              maxHeight: '80vh',
              overflowY: 'auto',
            }}
          >
            <button
              onClick={() => setIsNewCustomerModalOpen(false)}
              style={{
                position: 'absolute',
                top: '5px',
                right: '20px',
                width: 30,
                background: 'transparent',
                border: 'none',
                fontSize: '1.5rem',
                cursor: 'pointer',
                color: isDark ? '#e0e0e0' : '#000',
              }}
              aria-label="Close"
            >
              ×
            </button>
            <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>New Customer</h2>
            <form onSubmit={handleNewCustomerSubmit}>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                <div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      First Name:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.FirstName}
                      onChange={(e) => setNewCustomerForm({ ...newCustomerForm, FirstName: e.target.value })}
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Email:
                    </label>
                    <input
                      type="email"
                      value={newCustomerForm.Email}
                      onChange={(e) => setNewCustomerForm({ ...newCustomerForm, Email: e.target.value })}
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Physical Address:
                    </label>
                    <input
                      type="text"
                      value={newCustomerAddressInput}
                      onChange={(e) => {
                        setNewCustomerAddressInput(e.target.value);
                        debouncedFetchCustomerAddressSuggestions(e.target.value);
                      }}
                      placeholder="Start typing your address"
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                    {newCustomerAddressInput.length >= 3 && (
                      <div
                        style={{
                          maxHeight: '200px',
                          overflowY: 'auto',
                          border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                          marginTop: '5px',
                          background: isDark ? '#2c2c2c' : '#fff',
                        }}
                      >
                        {newCustomerAddressLoading && <div style={{ padding: '5px' }}>Loading...</div>}
                        {!newCustomerAddressLoading &&
                          newCustomerAddressSuggestions.map((suggestion: any, index: number) => (
                            <div
                              key={`${suggestion.display_name}-${index}`}
                              onClick={() => {
                                const addr = suggestion.address;
                                setNewCustomerForm({
                                  ...newCustomerForm,
                                  PhysicalAddress:
                                    `${addr.house_number || ''} ${addr.road || addr.street || addr.neighbourhood || ''}`.trim() ||
                                    suggestion.display_name ||
                                    '',
                                  PhysicalCity: addr.city || addr.town || addr.village || addr.hamlet || '',
                                  PhysicalState: addr.state || '',
                                  PhysicalZip: addr.postcode || '',
                                  Longitude: parseFloat(suggestion.lon),
                                  Latitude: parseFloat(suggestion.lat),
                                });
                                setNewCustomerAddressInput(suggestion.display_name || '');
                                setNewCustomerAddressSuggestions([]);
                              }}
                              style={{
                                padding: '5px',
                                cursor: 'pointer',
                                background: 'transparent',
                              }}
                            >
                              {suggestion.display_name}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      City:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.PhysicalCity}
                      onChange={(e) => setNewCustomerForm({ ...newCustomerForm, PhysicalCity: e.target.value })}
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      State:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.PhysicalState}
                      onChange={(e) => setNewCustomerForm({ ...newCustomerForm, PhysicalState: e.target.value })}
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      ZIP:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.PhysicalZip}
                      onChange={(e) => setNewCustomerForm({ ...newCustomerForm, PhysicalZip: e.target.value })}
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Last Name:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.LastName}
                      onChange={(e) => setNewCustomerForm({ ...newCustomerForm, LastName: e.target.value })}
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Phone:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.CellPhone}
                      onChange={(e) =>
                        setNewCustomerForm({ ...newCustomerForm, CellPhone: e.target.value })
                      }
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Name on Invoice:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.NameOnInvoice}
                      onChange={(e) => setNewCustomerForm({ ...newCustomerForm, NameOnInvoice: e.target.value })}
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: '15px' }}>
                <div style={{ display: 'flex', alignItems: 'left' }}>
                  <label style={{ display: 'block', marginBottom: '5px', fontStyle: 'italic', color: isDark ? '#FFA725' : '#f37121', fontSize: '14px', whiteSpace: 'nowrap' }}>
                    Billing address is different than Physical Address:
                  </label>
                  <input
                    type="checkbox"
                    checked={billingDiff}
                    onChange={(e) => setBillingDiff(e.target.checked)}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              </div>
              {billingDiff && (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Billing Address:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.BillingAddress}
                      onChange={(e) =>
                        setNewCustomerForm({ ...newCustomerForm, BillingAddress: e.target.value })
                      }
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Billing City:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.BillingCity}
                      onChange={(e) =>
                        setNewCustomerForm({ ...newCustomerForm, BillingCity: e.target.value })
                      }
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Billing State:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.BillingState}
                      onChange={(e) =>
                        setNewCustomerForm({ ...newCustomerForm, BillingState: e.target.value })
                      }
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <label style={{ color: isDark ? '#FFA725' : '#f37121', display: 'block', marginBottom: '5px' }}>
                      Billing ZIP:
                    </label>
                    <input
                      type="text"
                      value={newCustomerForm.BillingZip}
                      onChange={(e) =>
                        setNewCustomerForm({ ...newCustomerForm, BillingZip: e.target.value })
                      }
                      required
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid ' + (isDark ? '#444' : '#ccc'),
                        borderRadius: '4px',
                        background: isDark ? '#2c2c2c' : '#fff',
                        color: isDark ? '#e0e0e0' : '#000',
                      }}
                    />
                  </div>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                <button
                  type="button"
                  onClick={() => setIsNewCustomerModalOpen(false)}
                  style={{
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '4px',
                    backgroundColor: '#FFA725',
                    color: '#000',
                    fontFamily: 'poppins',
                    cursor: 'pointer',
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '4px',
                    backgroundColor: '#064454',
                    color: '#fff',
                    fontFamily: 'poppins',
                    cursor: 'pointer',
                  }}
                >
                  Save Customer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Updated Project Panel */}
      {selectedProject && (
        <div
          className="project-panel"
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            width: '400px',
            height: '100%',
            background: isDark ? '#2c2c2d' : '#f9f9f9',
            boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
            zIndex: 100,
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            willChange: 'transform, opacity',
            transform: projectPanelActive ? 'translateX(0)' : 'translateX(100%)',
            opacity: projectPanelActive ? 1 : 0,
            pointerEvents: projectPanelActive ? 'auto' : 'none',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            color: 'white'
          }}
        >
          <div style={{ position: 'relative', marginBottom: '20px' }}>
            <button
              onClick={() => {
                setProjectPanelActive(false);
                setTimeout(() => {
                  setSelectedProject(null);
                  if (calendarRef.current) {
                    calendarRef.current.getApi().updateSize();
                  }
                }, 300);
              }}
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                width: 30,
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                color: isDark ? '#e0e0e0' : '#000',
                zIndex: 101,
              }}
              aria-label="Close Project Panel"
            >
              <ChevronRightIcon />
            </button>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '80px' }}>
              <h3 style={{ margin: 0, color: isDark ? '#e0e0e0' : '#000', }}>Project Details</h3>
              <button
                onClick={handleOpenEditFromPanel}
                style={{
                  width: '30px',
                  marginLeft: '10px',
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  color: isDark ? '#FFA725' : '#B4BAB5',
                }}
                aria-label="Edit Project"
              >
                <EditIcon fontSize="small" />
              </button>
            </div>
          </div>
          <div style={{ overflowY: 'auto', flex: 1 }}>
            <div style={{ marginBottom: '15px', color: isDark ? '#e0e0e0' : '#605E64' }}>
              <strong>Customer:</strong> {selectedProject.customerId.FirstName} {selectedProject.customerId.LastName}
            </div>
            <div style={{ marginBottom: '15px', color: isDark ? '#e0e0e0' : '#605E64'  }}>
              <strong>Date:</strong> {selectedProject.date ? new Date(selectedProject.date).toLocaleDateString() : 'N/A'}
            </div>
            <div style={{ marginBottom: '15px', color: isDark ? '#e0e0e0' : '#605E64'  }}>
              <strong>Hours:</strong> {selectedProject.details}
            </div>
            <div style={{ marginBottom: '15px', color: isDark ? '#e0e0e0' : '#605E64'  }}>
              <strong>Project Type:</strong> {selectedProject.type}
            </div>
            <div style={{ marginBottom: '15px', color: isDark ? '#e0e0e0' : '#605E64'  }}>
              <strong>Description:</strong>
              <div
                className="scrollable-description"
                dangerouslySetInnerHTML={{ __html: selectedProject.description }}
              ></div>
            </div>
          </div>
          {selectedProject.customerId.Latitude && selectedProject.customerId.Longitude && (
            <div style={{ marginTop: '15px', height: '300px' }}>
              <MapContainer
                key={isDark ? 'dark' : 'light'}
                center={[selectedProject.customerId.Latitude, selectedProject.customerId.Longitude]}
                zoom={11}
                scrollWheelZoom={false}
                style={{ height: '100%', width: '100%' }}
              >
                <TileLayer
                  url={
                    isDark
                      ? 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
                      : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  }
                  attribution={
                    isDark
                      ? '© CARTO | OpenStreetMap contributors'
                      : '© OpenStreetMap contributors'
                  }
                />
                <RecenterAutomatically
                  lat={selectedProject.customerId.Latitude}
                  lng={selectedProject.customerId.Longitude}
                  zoom={11}
                />
                <Marker position={[selectedProject.customerId.Latitude, selectedProject.customerId.Longitude]}>
                  <Popup>
                    {selectedProject.customerId.FirstName} {selectedProject.customerId.LastName}
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          )}
        </div>
      )}

      {/* Confirmation Modal for Marking Project as Completed */}
      {showCompletionConfirmModal && (
        <div
          onClick={() => setShowCompletionConfirmModal(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1200,
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              background: isDark ? '#1e1e1e' : '#fff',
              borderRadius: '8px',
              padding: '30px',
              width: '90%',
              maxWidth: '400px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
              textAlign: 'center',
            }}
          >
            <h2>Confirm Completion</h2>
            <p>Are you sure you want to mark this project as completed?</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button
                onClick={() => setShowCompletionConfirmModal(false)}
                style={{
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '4px',
                  backgroundColor: '#FFA725',
                  color: '#000',
                  cursor: 'pointer',
                  fontFamily: 'poppins',
                  width: '45%',
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleCompletionConfirm}
                style={{
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '4px',
                  backgroundColor: '#064454',
                  color: '#fff',
                  cursor: 'pointer',
                  fontFamily: 'poppins',
                  width: '45%',
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      <style>{`
        .custom-header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;
          gap: 20px;
        }
        .navigation {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .navigation button {
          background: none;
          border: none;
          font-size: 1.2rem;
          color: #f37121;
          cursor: pointer;
          transition: color 0.3s;
          padding: 5px 10px;
        }
        .navigation button:hover {
          color: #d65f1a;
        }
        .toggle-switch {
          position: relative;
          width: 80px;
          height: 40px;
          background: #f0f0f0;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
        }
        .toggle-option {
          z-index: 1;
          cursor: pointer;
          color: #666;
          transition: color 0.3s;
        }
        .toggle-option:hover {
          color: #000;
        }
        .toggle-slider {
          position: absolute;
          top: 2px;
          left: 2px;
          width: 36px;
          height: 36px;
          background: #f37121;
          border-radius: 18px;
          transition: transform 0.3s ease-in-out;
          box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        }
        .toggle-slider.kanban {
          transform: translateX(40px);
        }
        .project-chip {
          padding: 10px;
          color: #fff;
          margin: 5px 0;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          cursor: default;
          transition: transform 0.2s ease-in-out;
        }
        .fc-event-title {
          color: #fff;
        }
        .project-chip:hover {
          transform: scale(1.05);
        }
        .big-job {
          background-color: rgb(171, 95, 95);
        }
        .small-job {
          background-color: rgb(102, 133, 102);
        }
        /* NEW: Completed project style overrides */
        .completed-project {
          background-color: #A9A9A9 !important;
          color: black !important;
        }
        .week-column {
          transition: background 0.3s;
        }
        .fc .fc-daygrid-day-frame {
          min-height: 110px !important;
        }
        .dark-calendar .fc .fc-col-header-cell,
        .dark-calendar .fc .fc-col-header-cell-cushion {
          background-color: #00647A;
          color: #e0e0e0;
        }
        .scrollable-description {
          margin-top: 5px;
          max-height: 250px;
          overflow-y: auto;
          padding-right: 5px;
        }
        .scrollable-description::-webkit-scrollbar {
          width: 6px;
        }
        .scrollable-description::-webkit-scrollbar-track {
          background: transparent;
        }
        .scrollable-description::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 3px;
        }
        .rich-text-editor-wrapper .ql-editor {
          min-height: 200px;
        }
        .colorLabel {
          color: isDark ? '#FFA725' : '#f37121'
        }
      `}</style>
    </>
  );
};

export default ProjectScheduler;