import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { UserOptions } from "jspdf-autotable";
import html2canvas from 'html2canvas';

declare module 'jspdf' {
  interface jsPDF {
    autoTable: (options: UserOptions) => jsPDF;
  }
}

// --- Helper function for merge tag replacement ---
function replaceMergeTags(
  text: string,
  serviceName: string,
  selectedServiceOptions: Record<string, Record<string, string>> | undefined
): string {
  if (!selectedServiceOptions || !selectedServiceOptions[serviceName]) {
    return text;
  }
  const serviceOptions = selectedServiceOptions[serviceName];
  return text.replace(/{([^}]+)}/g, (match, key) => {
    return serviceOptions[key] !== undefined ? serviceOptions[key] : match;
  });
}

interface Service {
  name: string;
  displayName?: string;
  description: string;
  quotePageDesc?: string;
  fineText?: string;
  priceType?: string; // NEW: priceType field
}

interface Quote {
  quoteId: string;
  services: string[];
  serviceValues: string[];
  totalValue: number;
  status: string;
  acceptedTime?: string;
  // New field for disabled services
  disabledServices?: string[];
  // Optionally includes selectedServiceOptions for merge tags
  selectedServiceOptions?: Record<string, Record<string, string>>;
}

interface QuoteTemplate {
  name: string;
  introParagraph: string;
  companyName: string;
  customServices: Service[];
  predefinedServiceIds: string[];
  customization: {
    primaryColor: string;
    secondaryColor: string;
    fontFamily: string;
    layout: 'list' | 'grid';
    showLogo: boolean;
    showIntro: boolean;
  };
}

interface CustomerData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  signature?: string;
}

export const generateQuotePDF = async (
  quote: Quote,
  template: QuoteTemplate,
  customerData: CustomerData,
  viewportRatio: number,
  companyLogo?: string,
  predefinedServices?: Service[]
): Promise<Blob> => {
  console.log("Starting PDF generation...");
  console.log("Quote Data:", quote);
  console.log("Template Data:", template);
  console.log("Customer Data:", customerData);
  console.log("Viewport Ratio:", viewportRatio);
  console.log("Company Logo URL:", companyLogo);
  console.log("Predefined Services:", predefinedServices);

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "pt",
    format: "a4",
  });
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  console.log(`Page dimensions: ${pageWidth}pt x ${pageHeight}pt`);

  // Define margins
  const margin = 60;
  const bottomMargin = 60;

  // Define padding between logo and next content
  const logoPadding = 40;

  // Function to draw dashed line
  const drawDashedLine = (startX: number, endX: number, y: number, dashLength: number = 3) => {
    console.log(`Drawing dashed line from (${startX}, ${y}) to (${endX}, ${y})`);
    doc.setLineDashPattern([dashLength], 0);
    doc.setLineWidth(0.5);
    doc.setDrawColor(200, 200, 200);
    doc.line(startX, y - 2, endX, y - 2);
    doc.setLineDashPattern([], 0);
  };

  try {
    // Set font
    const font = template.customization.fontFamily?.toLowerCase() === 'helvetica' ? 'helvetica' : 'helvetica';
    console.log(`Setting font to: ${font}`);
    doc.setFont(font);

    // Initialize yPos with default value
    let yPos = 150;
    console.log(`Initial yPos set to: ${yPos}`);

    // Add company logo
    if (companyLogo) {
      console.log("Company logo provided. Attempting to add to PDF...");
      try {
        const logoWidth = 120;
        const logoX = (pageWidth - logoWidth) / 2;
        const logoY = 50;

        console.log(`Logo dimensions and position: Width=${logoWidth}, X=${logoX}, Y=${logoY}`);

        const response = await fetch(companyLogo);
        if (!response.ok) {
          throw new Error(`Failed to fetch logo image: ${response.statusText}`);
        }

        const blob = await response.blob();
        const dataURL = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (typeof reader.result === "string") {
              resolve(reader.result);
            } else {
              reject(new Error("Failed to convert logo image to Data URL"));
            }
          };
          reader.onerror = () => {
            reject(new Error("Error reading logo image blob"));
          };
          reader.readAsDataURL(blob);
        });

        const img = new Image();
        img.src = dataURL;

        await new Promise<void>((resolve, reject) => {
          img.onload = () => resolve();
          img.onerror = () =>
            reject(new Error("Failed to load image for dimension calculation"));
        });

        const aspectRatio = img.naturalHeight / img.naturalWidth;
        const logoHeight = logoWidth * aspectRatio;
        doc.addImage(dataURL, "PNG", logoX, logoY, logoWidth, logoHeight);

        yPos = logoY + logoHeight + logoPadding;
      } catch (error) {
        console.error("Error loading logo from URL:", error);
        yPos = 150;
      }
    } else {
      console.log("No company logo provided. Skipping logo addition.");
    }

    // Determine device type based on viewportRatio
    const isBrowser = viewportRatio < 2.5;

    // Variables to compute if all rendered services are priced per month
    let allServicesPerMonth = true;

    // Add services using an indexed loop
    console.log("Starting to add services to PDF...");
    for (let i = 0; i < quote.services.length; i++) {
      const serviceName = quote.services[i];

      // Skip if the service is disabled
      if (quote.disabledServices && quote.disabledServices.includes(serviceName)) {
        console.log(`Skipping disabled service "${serviceName}"`);
        continue;
      }

      const serviceValue = quote.serviceValues[i];
      console.log(`Processing service: ${serviceName}`);

      // Check if service value is empty or zero
      const trimmedServiceValue = serviceValue.trim();
      if (trimmedServiceValue === '' || Number(trimmedServiceValue) === 0) {
        console.log(`Skipping service "${serviceName}" with value "${serviceValue}"`);
        continue;
      }

      let serviceDetails: Service = {
        name: serviceName,
        description: '',
        fineText: ''
      };

      const customService = template.customServices.find((s) => s.displayName === serviceName);
      if (customService) {
        serviceDetails = customService;
      } else if (predefinedServices) {
        const predefinedService = predefinedServices.find((s) => s.displayName === serviceName);
        if (predefinedService) {
          serviceDetails = predefinedService;
        } else {
          const fallbackCustomService = template.customServices.find((s) => s.name === serviceName);
          const fallbackPredefinedService = predefinedServices.find((s) => s.name === serviceName);
          if (fallbackCustomService) {
            serviceDetails = fallbackCustomService;
          } else if (fallbackPredefinedService) {
            serviceDetails = fallbackPredefinedService;
          }
        }
      }

      const displayServiceName = serviceDetails.displayName || serviceDetails.name;

      // Add service name
      doc.setFontSize(16);
      doc.setTextColor(template.customization.secondaryColor || '#000000');
      const serviceNameWidth = doc.getTextWidth(displayServiceName);
      doc.text(displayServiceName, margin, yPos);

      // Add service value with priceType logic
      const valueText = `$${serviceValue}${serviceDetails.priceType === 'per month' ? ' / month' : ''}`;
      doc.setFontSize(12);
      const valueWidth = doc.getTextWidth(valueText);
      doc.setTextColor('#000000');
      doc.text(valueText, pageWidth - margin, yPos, { align: 'right' });

      // If this service is not priced per month, then the overall total should not be marked as per month
      if (!(serviceDetails.priceType && serviceDetails.priceType === 'per month')) {
        allServicesPerMonth = false;
      }

      // Draw dashed line between service name and value
      const lineStartX = margin + serviceNameWidth + 10;
      const lineEndX = pageWidth - margin - valueWidth - 10;
      drawDashedLine(lineStartX, lineEndX, yPos);

      yPos += 20;

      // Add service description
      let serviceDescription = serviceDetails.quotePageDesc || serviceDetails.description;
      // --- Merge tag replacement (only addition) ---
      if (serviceDescription && quote.selectedServiceOptions) {
        serviceDescription = replaceMergeTags(serviceDescription, serviceName, quote.selectedServiceOptions);
      }

      if (serviceDescription) {
        const usingQuotePageDesc = Boolean(serviceDetails.quotePageDesc);
        const descriptionFontSize = usingQuotePageDesc
          ? (isBrowser ? '34px' : '8px')
          : (isBrowser ? '30px' : '8px');

        doc.setFontSize(14);
        const serviceDescriptionHTML = `
          <div style="font-size:${descriptionFontSize}; color:#1E1F20;">
            ${serviceDescription}
          </div>
        `;

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = serviceDescriptionHTML;
        document.body.appendChild(tempDiv);

        const canvas = await html2canvas(tempDiv, { scale: 3 });
        const imgData = canvas.toDataURL('image/jpeg', 0.7);

        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        doc.addImage(imgData, 'JPEG', margin, yPos, imgWidth, imgHeight);
        yPos += imgHeight + 40;

        document.body.removeChild(tempDiv);
      }
    }

    // Add total with dashed line
    doc.setFontSize(14);
    doc.setTextColor(template.customization.primaryColor || '#000000');
    const totalText = 'Total:';
    // Append '/ month' if all rendered services are per month
    const totalValue = `$${quote.totalValue.toFixed(2)}${allServicesPerMonth ? ' / month' : ''}`;
    doc.text(totalText, margin, yPos + 10);
    doc.text(totalValue, pageWidth - margin, yPos + 10, { align: 'right' });
    yPos += 30;

    // Define positions for signature and terms
    const termsY = pageHeight - bottomMargin;
    const signatureY = termsY - 200;
    const signatureWidth = 150;
    const signatureHeight = 50;
    const signatureX = pageWidth - margin - signatureWidth;

    // Add signature if quote is accepted
    if (quote.status === 'Accepted' && customerData.signature) {
      // Move signature image to the same y value as the signed by text, but over to the left
      const signatureImageX = margin;
      const signatureImageY = signatureY + signatureHeight-5;
      doc.addImage(customerData.signature, 'PNG', signatureImageX, signatureImageY, signatureWidth, signatureHeight);
      doc.setFontSize(10);
      doc.setTextColor('#000000');
      const signedByText = `Signed by ${customerData.firstName} ${customerData.lastName}`;
      const acceptedOnText = `Accepted on ${new Date(quote.acceptedTime!).toLocaleString()}`;
      doc.text(signedByText, pageWidth - margin, signatureImageY, { align: 'right' });
      doc.text(acceptedOnText, pageWidth - margin, signatureImageY + 15, { align: 'right' });
    }

    // Add terms and conditions
    if (quote.services.length > 0) {
      let termsContent = '';
      quote.services.forEach((serviceName) => {
        // Skip disabled services for terms & conditions
        if (quote.disabledServices && quote.disabledServices.includes(serviceName)) {
          console.log(`Skipping disabled service "${serviceName}" for terms and conditions.`);
          return;
        }
        const serviceDetails = template.customServices.find((s) => s.displayName === serviceName || s.name === serviceName) ||
                               predefinedServices?.find((s) => s.displayName === serviceName || s.name === serviceName) || {
          name: serviceName,
          description: '',
          fineText: ''
        };
        if (serviceDetails.fineText) {
          termsContent += `${serviceDetails.fineText}\n\n`;
        }
      });

      if (termsContent) {
        doc.setFontSize(10);
        doc.setTextColor('#000000');
        doc.text('Terms and Conditions:', margin, termsY - 80);
        doc.setFontSize(7);
        const fineTextLines = doc.splitTextToSize(termsContent, pageWidth - 2 * margin);
        doc.text(fineTextLines, margin, termsY - 60);
        doc.setLineWidth(0.5);
        doc.line(margin, termsY - 110, pageWidth - margin, termsY - 110);
      }
    }

    console.log("PDF generation completed successfully.");
    return doc.output('blob');
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Failed to generate PDF');
  }
};
