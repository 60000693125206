import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Divider,
  Stack,
  Tabs,
  Tab,
  GlobalStyles,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import axiosInstance from '../utils/axiosConfig';
import { useSnackbar } from 'notistack';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTheme, alpha } from '@mui/material/styles';

interface ServiceOptionSub {
  label: string;
  isDefault: boolean;
}

interface ServiceOption {
  name: string;
  mergeTag: string;
  options: ServiceOptionSub[];
  editable?: boolean;
}

interface Service {
  _id: string;
  name: string;
  displayName: string;
  description: string;
  quotePageDesc: string;
  fineText: string;
  serviceOptions?: ServiceOption[];
  isAddon: boolean;
  priceType: string;
}

const quillFormats = ['bold', 'italic', 'underline', 'list', 'bullet'];

const emptyService: Service = {
  _id: '',
  name: '',
  displayName: '',
  description: '',
  quotePageDesc: '',
  fineText: '',
  serviceOptions: [],
  isAddon: false,
  priceType: 'one time',
};

const PredefinedServices: React.FC = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [services, setServices] = useState<Service[]>([]);
  const [open, setOpen] = useState(false);
  const [editingService, setEditingService] = useState<Service>(emptyService);
  const [activeTab, setActiveTab] = useState<number>(0);
  const quillRef = useRef<ReactQuill>(null);

  // Global styles to force the merge tag text to display via pseudo-elements.
  // Updated to be dark mode responsive.
  const globalStyles = (
    <GlobalStyles
      styles={{
        '.ql-merge-tags .ql-picker': {
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.divider,
        },
        '.ql-merge-tags .ql-picker-label': {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
        },
        '.ql-merge-tags .ql-picker-label::before': {
          content: 'attr(data-label)',
        },
        '.ql-merge-tags .ql-picker-item': {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
        },
        '.ql-merge-tags .ql-picker-item::before': {
          content: 'attr(data-label)',
        },
      }}
    />
  );

  // Custom toolbar configuration.
  const quillModules = useMemo(() => {
    return {
      toolbar: {
        container: '#toolbar',
        handlers: {
          'merge-tags': function (this: { quill: any }, value: string) {
            if (value && value !== '') {
              const quill = this.quill;
              const range = quill.getSelection();
              if (range) {
                quill.insertText(range.index, `{${value}}`);
              }
            }
            // Reset the dropdown to its default option.
            setTimeout(() => {
              const select = document.querySelector('.ql-merge-tags') as HTMLSelectElement;
              if (select) {
                select.value = '';
                select.selectedIndex = 0;
              }
            }, 0);
          },
        },
      },
    };
  }, [editingService.serviceOptions]);

  // Fetch services on mount.
  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/api/predefinedServices');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching predefined services:', error);
      enqueueSnackbar('Failed to fetch predefined services', { variant: 'error' });
    }
  };

  const handleOpen = (service: Service | null = null) => {
    if (service) {
      // Mark loaded service options as non-editable.
      const serviceOptions = (service.serviceOptions || []).map((option) => ({
        ...option,
        editable: false,
      }));
      setEditingService({ ...service, serviceOptions });
    } else {
      setEditingService(emptyService);
    }
    setActiveTab(0);
    setOpen(true);
  };

  // Before closing, blur the ReactQuill editor to avoid errors.
  const handleClose = () => {
    if (quillRef.current && quillRef.current.getEditor()) {
      quillRef.current.getEditor().blur();
    }
    setTimeout(() => {
      setEditingService(emptyService);
      setOpen(false);
    }, 0);
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Use the editingService state for all fields so that required fields like "name" are always provided
    const serviceData = {
      name: editingService.name,
      displayName: editingService.displayName,
      description: editingService.description,
      quotePageDesc: editingService.quotePageDesc.trim(),
      fineText: editingService.fineText,
      isAddon: editingService.isAddon,
      priceType: editingService.priceType,
      serviceOptions: editingService.serviceOptions,
    };

    try {
      if (editingService._id) {
        await axiosInstance.put(`/api/predefinedServices/${editingService._id}`, serviceData);
        enqueueSnackbar('Service updated successfully', { variant: 'success' });
      } else {
        await axiosInstance.post('/api/predefinedServices', serviceData);
        enqueueSnackbar('Service created successfully', { variant: 'success' });
      }
      fetchServices();
      handleClose();
    } catch (error) {
      console.error('Error saving predefined service:', error);
      enqueueSnackbar('Failed to save service', { variant: 'error' });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axiosInstance.delete(`/api/predefinedServices/${id}`);
      enqueueSnackbar('Service deleted successfully', { variant: 'success' });
      fetchServices();
    } catch (error) {
      console.error('Error deleting predefined service:', error);
      enqueueSnackbar('Failed to delete service', { variant: 'error' });
    }
  };

  const handleServiceChange = (field: keyof Service, value: string) => {
    setEditingService({
      ...editingService,
      [field]: field === 'quotePageDesc' ? value.trim() : value,
    });
  };

  // Service Options Handlers
  const handleAddServiceOption = () => {
    const newOption: ServiceOption = { name: '', mergeTag: '', options: [], editable: true };
    setEditingService({
      ...editingService,
      serviceOptions: [...(editingService.serviceOptions || []), newOption],
    });
  };

  const handleRemoveServiceOption = (sIndex: number) => {
    const newServiceOptions = (editingService.serviceOptions || []).filter(
      (_, index) => index !== sIndex
    );
    setEditingService({ ...editingService, serviceOptions: newServiceOptions });
  };

  const handleServiceOptionChange = (
    sIndex: number,
    field: keyof ServiceOption,
    value: string
  ) => {
    const newServiceOptions = [...(editingService.serviceOptions || [])];
    newServiceOptions[sIndex] = { ...newServiceOptions[sIndex], [field]: value };
    setEditingService({ ...editingService, serviceOptions: newServiceOptions });
  };

  const handleAddSubOption = (sIndex: number) => {
    const currentOptions = editingService.serviceOptions
      ? editingService.serviceOptions[sIndex].options
      : [];
    if (currentOptions.length >= 5) return;
    const newSubOption: ServiceOptionSub = { label: '', isDefault: false };
    const newServiceOptions = [...(editingService.serviceOptions || [])];
    newServiceOptions[sIndex].options = [...currentOptions, newSubOption];
    setEditingService({ ...editingService, serviceOptions: newServiceOptions });
  };

  const handleRemoveSubOption = (sIndex: number, subIndex: number) => {
    const newServiceOptions = [...(editingService.serviceOptions || [])];
    newServiceOptions[sIndex].options = newServiceOptions[sIndex].options.filter(
      (_, index) => index !== subIndex
    );
    setEditingService({ ...editingService, serviceOptions: newServiceOptions });
  };

  const handleSubOptionChange = (
    sIndex: number,
    subIndex: number,
    field: keyof ServiceOptionSub,
    value: string | boolean
  ) => {
    const newServiceOptions = [...(editingService.serviceOptions || [])];
    const subOptions = [...newServiceOptions[sIndex].options];
    subOptions[subIndex] = { ...subOptions[subIndex], [field]: value };
    newServiceOptions[sIndex].options = subOptions;
    setEditingService({ ...editingService, serviceOptions: newServiceOptions });
  };

  const handleSetDefault = (sIndex: number, subIndex: number) => {
    const newServiceOptions = [...(editingService.serviceOptions || [])];
    const updatedSubOptions = newServiceOptions[sIndex].options.map((opt, index) => ({
      ...opt,
      isDefault: index === subIndex,
    }));
    newServiceOptions[sIndex].options = updatedSubOptions;
    setEditingService({ ...editingService, serviceOptions: newServiceOptions });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 64px)',
        bgcolor: 'background.default',
        overflowY: 'auto',
      }}
    >
      {globalStyles}
      <Box sx={{ width: '100%', p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Predefined Services
        </Typography>

        {/* Add New Service Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            startIcon={<Add />}
            onClick={() => handleOpen()}
            variant="contained"
            color="warning"
          >
            Add New Service
          </Button>
        </Box>

        {/* Services List */}
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <List>
            {services.map((service) => (
              <React.Fragment key={service._id}>
                <ListItem>
                  <ListItemText
                    primary={service.name}
                    secondary={service.description}
                    primaryTypographyProps={{ fontWeight: 'bold' }}
                    secondaryTypographyProps={{ whiteSpace: 'pre-line' }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleOpen(service)}
                      sx={{ mr: 1, width: 40 }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      sx={{ width: 40, mr: 2 }}
                      onClick={() => handleDelete(service._id)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>

        {/* Dialog for Add/Edit Service */}
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>
            {editingService._id ? 'Edit Service' : 'Add New Service'}
          </DialogTitle>
          <form onSubmit={handleSave}>
            <DialogContent>
              {/* Tabs */}
              <Tabs
                value={activeTab}
                onChange={(e, newValue) => setActiveTab(newValue)}
                centered
                sx={{ mb: 3, '& .MuiTabs-indicator': { backgroundColor: 'warning.main' } }}
                textColor="inherit"
              >
                <Tab
                  label="General"
                  sx={(theme) => ({
                    color: 'warning.main',
                    '&.Mui-selected': { color: 'warning.main' },
                    '&:hover': {
                      color: alpha(theme.palette.common.white, 1),
                      backgroundColor: alpha(theme.palette.warning.main, 0.8),
                      cursor: 'pointer',
                    },
                  })}
                />
                <Tab
                  label="Service Options"
                  sx={(theme) => ({
                    color: 'warning.main',
                    '&.Mui-selected': { color: 'warning.main' },
                    '&:hover': {
                      color: alpha(theme.palette.common.white, 1),
                      backgroundColor: alpha(theme.palette.warning.main, 0.8),
                      cursor: 'pointer',
                    },
                  })}
                />
              </Tabs>
              {activeTab === 0 && (
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Service Name (Internal)"
                    type="text"
                    fullWidth
                    value={editingService.name}
                    onChange={(e) => handleServiceChange('name', e.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                      },
                      '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                    }}
                  />
                  <TextField
                    margin="dense"
                    name="displayName"
                    label="Display Name (Customer Facing)"
                    type="text"
                    fullWidth
                    value={editingService.displayName}
                    onChange={(e) => handleServiceChange('displayName', e.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                      },
                      '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                    }}
                  />
                  <TextField
                    margin="dense"
                    name="description"
                    label="Description"
                    type="text"
                    fullWidth
                    multiline
                    rows={2}
                    value={editingService.description}
                    onChange={(e) => handleServiceChange('description', e.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                      },
                      '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                    }}
                  />
                  <Box
                    sx={{
                      '& .ql-toolbar': {
                        backgroundColor: theme.palette.background.default,
                        borderColor: theme.palette.divider,
                        '& button, & .ql-picker': { color: theme.palette.text.primary },
                        '& button:hover, & .ql-picker:hover': { color: theme.palette.warning.main },
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      },
                      '& .ql-container': {
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.divider,
                        '& .ql-editor': {
                          minHeight: '150px',
                          '& .ql-placeholder': { color: theme.palette.text.disabled },
                        },
                      },
                      '& .ql-toolbar, & .ql-container': {
                        borderRadius: theme.shape.borderRadius,
                      },
                    }}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      Quote Page Description:
                    </Typography>
                    {/* Custom Toolbar */}
                    <div id="toolbar">
                      <span className="ql-formats">
                        <button className="ql-bold" />
                        <button className="ql-italic" />
                        <button className="ql-underline" />
                      </span>
                      <span className="ql-formats">
                        <button className="ql-list" value="ordered" />
                        <button className="ql-list" value="bullet" />
                      </span>
                      <span className="ql-formats">
                        <select className="ql-merge-tags" style={{ minWidth: '150px' }}>
                          <option value="">Insert Merge Tag</option>
                          {(editingService.serviceOptions || []).map((option) => (
                            <option key={option.mergeTag} value={option.mergeTag}>
                              {`{${option.mergeTag}}`}
                            </option>
                          ))}
                        </select>
                      </span>
                      <span className="ql-formats">
                        <button className="ql-clean" />
                      </span>
                    </div>
                    <ReactQuill
                      ref={quillRef}
                      value={editingService.quotePageDesc}
                      onChange={(content: string) =>
                        handleServiceChange('quotePageDesc', content)
                      }
                      modules={quillModules}
                      formats={quillFormats}
                    />
                  </Box>
                  <TextField
                    margin="dense"
                    name="fineText"
                    label="Fine Text"
                    type="text"
                    fullWidth
                    multiline
                    rows={3}
                    value={editingService.fineText}
                    onChange={(e) => handleServiceChange('fineText', e.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                      },
                      '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                    }}
                  />
                  <TextField
                      margin="dense"
                      name="priceType"
                      label="Price Type"
                      select
                      fullWidth
                      value={editingService.priceType}
                      onChange={(e) => handleServiceChange('priceType', e.target.value)}
                      SelectProps={{ native: true }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                      }}
                    >
                      <option value="one time">One Time</option>
                      <option value="per month">Per Month</option>
                    </TextField>
                  {/* Add On Service Checkbox with Description */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editingService.isAddon}
                        onChange={(e) =>
                          setEditingService({ ...editingService, isAddon: e.target.checked })
                        }
                        color="warning"
                      />
                    }
                    label={
                      <Typography sx={{ color: theme.palette.warning.main }}>
                        Add On Service
                      </Typography>
                    }
                  />
                  <Typography variant="caption" color="textSecondary">
                    When checked, this service is treated as an add-on. A quote containing only add-on services cannot be accepted.
                  </Typography>
                </Stack>
              )}
              {activeTab === 1 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">Service Options</Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Define custom variations for this service. Each option lets you add up to five
                    sub-options, designate one as the default, and assign a merge tag that
                    dynamically updates your quote description.
                  </Typography>
                  {(editingService.serviceOptions || []).map((option, sIndex) => (
                    <Paper
                      key={sIndex}
                      variant="outlined"
                      sx={{
                        p: 2,
                        pt: 3,
                        mt: 1,
                        backgroundColor: theme.palette.background.default,
                        borderRadius: 5,
                        position: 'relative',
                      }}
                    >
                      <Tooltip title="Delete Service Option">
                        <IconButton
                          onClick={() => handleRemoveServiceOption(sIndex)}
                          sx={{
                            width: 40,
                            position: 'absolute',
                            top: 4,
                            right: 4,
                            color: 'warning.main',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      <Stack spacing={2}>
                        <TextField
                          label="Option Name"
                          value={option.name}
                          onChange={(e) =>
                            handleServiceOptionChange(sIndex, 'name', e.target.value)
                          }
                          disabled={!option.editable}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                            },
                            '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                          }}
                        />
                        <TextField
                          label="Merge Tag"
                          value={option.mergeTag}
                          onChange={(e) =>
                            handleServiceOptionChange(sIndex, 'mergeTag', e.target.value)
                          }
                          helperText="Enter a key (e.g., frequency). When activated via the mobile app, this key will be replaced in the quote description."
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                            },
                            '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                          }}
                        />
                        <Typography variant="subtitle1">Options</Typography>
                        {option.options.map((subOption, subIndex) => (
                          <Box
                            key={subIndex}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              mb: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                gap: 2,
                                alignItems: 'center',
                              }}
                            >
                              <TextField
                                label="Option Value"
                                value={subOption.label}
                                onChange={(e) =>
                                  handleSubOptionChange(sIndex, subIndex, 'label', e.target.value)
                                }
                                sx={{
                                  width: '75%',
                                  '& .MuiOutlinedInput-root': {
                                    height: '56px',
                                    '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                                }}
                              />
                              <Button
                                variant="outlined"
                                color={subOption.isDefault ? 'warning' : 'inherit'}
                                onClick={() => handleSetDefault(sIndex, subIndex)}
                                sx={{
                                  width: '25%',
                                  height: '56px',
                                  minHeight: '56px',
                                  padding: 0,
                                  mb: 2,
                                }}
                              >
                                {subOption.isDefault ? 'Default' : 'Set Default'}
                              </Button>
                            </Box>
                            <IconButton
                              sx={{ width: 40, mb: 4 }}
                              onClick={() => handleRemoveSubOption(sIndex, subIndex)}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        ))}
                        {option.options.length < 5 && (
                          <Button
                            onClick={() => handleAddSubOption(sIndex)}
                            variant="outlined"
                            color="info"
                          >
                            Add Option Value
                          </Button>
                        )}
                      </Stack>
                    </Paper>
                  ))}
                  <Button
                    onClick={handleAddServiceOption}
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 2 }}
                  >
                    Add Service Option
                  </Button>
                </Box>
              )}
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button
                onClick={handleClose}
                variant="contained"
                color="info"
                sx={{
                  mr: 1,
                  borderColor: 'info.main',
                  color: 'text',
                  '&:hover': {
                    borderColor: 'info.dark',
                    backgroundColor: 'info.light',
                  },
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="warning" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
};

export default PredefinedServices;
