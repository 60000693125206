// frontend/src/components/AdminDashboard.tsx

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box, Typography, CircularProgress, Tooltip, Button,
  IconButton
} from '@mui/material';
import {
  Dashboard as DashboardIcon, People, Settings as SettingsIcon, Logout as LogoutIcon,
  Brightness4, Brightness7, Description, Notifications as NotificationsIcon
} from '@mui/icons-material';
import adminAxiosInstance from '../utils/adminAxiosConfig';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import AdminHome from './AdminHome';
import ViewCompany from './ViewCompany';
import NotificationsManager from './NotificationsManager';
import CountyManager from './CountyManager';
import { ColorModeContext } from '../App';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../AuthContext';

const AdminDashboard: React.FC = () => {
  console.log('AdminDashboard: Rendering AdminDashboard component');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const colorMode = useContext(ColorModeContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated, isAdmin } = useAuth();

  const hasFetched = useRef(false);

  useEffect(() => {
    if (isAuthenticated === undefined || isAdmin === undefined) {
      // Authentication status is not yet determined
      return;
    }

    // If not authenticated or not admin, redirect to admin login
    if (!isAuthenticated || !isAdmin) {
      console.log('AdminDashboard: Not authenticated or not admin. Redirecting to /admin/login');
      navigate('/admin/login');
      return;
    }

    if (hasFetched.current) {
      return;
    }

    hasFetched.current = true;

    const fetchAdminData = async () => {
      try {
        setLoading(true);
        console.log('AdminDashboard: Fetching admin dashboard data...');
        // Fetch any admin-specific data if necessary
        const response = await adminAxiosInstance.get('/api/admin/dashboard-data');
        console.log('AdminDashboard: Received admin dashboard data:', response.data);
        // Handle response as needed
        setLoading(false);
      } catch (error: any) {
        console.error('AdminDashboard: Error fetching admin dashboard data:', error.response?.data || error.message);
        setError('Failed to load admin dashboard data. Please try again.');
        setLoading(false);
      }
    };

    fetchAdminData();
  }, [isAuthenticated, isAdmin, navigate]);

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
    console.log('AdminDashboard: Toggled sidebar to', !sidebarExpanded ? 'collapsed' : 'expanded');
  };

  // Loading State
  if (isAuthenticated === undefined || isAdmin === undefined || loading) {
    console.log('AdminDashboard: Loading admin dashboard data...');
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Error State
  if (error) {
    console.log('AdminDashboard: Error state triggered');
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error" variant="h6" gutterBottom>
          {error}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </Box>
    );
  }

  // Main Admin Dashboard Layout
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* Sidebar */}
      <Box
        sx={{
          width: sidebarExpanded ? 220 : 60,
          flexShrink: 0,
          color: 'white',
          p: 2,
          transition: 'width 0.3s, box-shadow 0.3s',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'primary.main',
          boxShadow: sidebarExpanded
            ? '2px 0 10px rgba(0, 0, 0, 0.4)'
            : '2px 0 5px rgba(0, 0, 0, 0.4)',
          zIndex: 1000,
        }}
      >
        {/* Logo and Toggle Button */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '50%',
              p: 0.5,
              transition: 'transform 0.3s',
              '&:hover': {
                transform: sidebarExpanded ? 'scale(1)' : 'scale(1.1)',
              },
            }}
            onClick={toggleSidebar}
          >
            <img
              src="/logo192.png" // Replace with your admin logo if different
              alt="Admin Logo"
              style={{
                width: 30,
                height: 30,
                marginRight: sidebarExpanded ? 5 : 0,
                marginLeft: -5,
                marginTop: 5,
                transition: 'transform 0.3s',
                transform: sidebarExpanded ? 'scale(1)' : 'scale(1.1)',
              }}
            />
            {sidebarExpanded && (
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1 }}>
                Admin Panel
              </Typography>
            )}
          </Box>
        </Box>

        {/* Navigation Links */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flexGrow: 1 }}>
          {[
            { path: '/admin/dashboard', icon: <DashboardIcon />, label: 'Dashboard' },
            { path: '/admin/dashboard/notifications', icon: <NotificationsIcon />, label: 'Notifications' },
            { path: '/admin/dashboard/users', icon: <People />, label: 'Manage Users' },
            { path: '/admin/dashboard/countymanager', icon: <SettingsIcon />, label: 'County Manager' },
            { path: '/admin/dashboard/settings', icon: <SettingsIcon />, label: 'Settings' },
            
            // Add more admin routes as needed
          ].map((item) => (
            <Link key={item.path} to={item.path} style={{ textDecoration: 'none', color: 'white' }}>
              <Tooltip title={!sidebarExpanded ? item.label : ''} placement="right" arrow>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: sidebarExpanded ? 'flex-start' : 'center',
                    gap: 2,
                    px: sidebarExpanded ? 1 : 0,
                    minHeight: 40,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      '& .MuiSvgIcon-root': {
                        color: 'warning.main',
                        transform: 'scale(1.1)',
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '& .MuiSvgIcon-root': {
                        transition: 'all 0.2s ease',
                        fontSize: 24,
                      },
                    }}
                  >
                    {item.icon}
                  </Box>
                  {sidebarExpanded && (
                    <Typography
                      sx={{
                        transition: 'opacity 0.2s ease',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.label}
                    </Typography>
                  )}
                </Box>
              </Tooltip>
            </Link>
          ))}
        </Box>

        {/* Logout Button */}
        <Link to="/admin/logout" style={{ textDecoration: 'none', color: 'white' }}>
          <Box
            sx={{
              mt: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: sidebarExpanded ? 'flex-start' : 'center',
              width: '100%',
              pb: 1,
              borderTop: 0,
              borderColor: 'rgba(255, 255, 255, 0.12)',
            }}
          >
            <IconButton
              color="inherit"
              sx={{
                width: 40,
                height: 40,
                mr: sidebarExpanded ? 1 : 0,
              }}
            >
              <LogoutIcon />
            </IconButton>
            {sidebarExpanded && (
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                Logout
              </Typography>
            )}
          </Box>
        </Link>

        {/* Night Mode Toggle */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: sidebarExpanded ? 'flex-start' : 'center',
            width: '100%',
            pt: 2,
            borderTop: 1,
            borderColor: 'rgba(255, 255, 255, 0.12)',
          }}
        >
          <IconButton
            onClick={colorMode.toggleColorMode}
            color="inherit"
            sx={{
              width: 40,
              height: 40,
              mr: sidebarExpanded ? 1 : 0,
            }}
          >
            {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
          {sidebarExpanded && (
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {theme.palette.mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
            </Typography>
          )}
        </Box>
      </Box>

      {/* Main Content Area */}
      <Box sx={{ flexGrow: 1, p: 3, backgroundColor: 'background.default', overflowY: 'auto' }}>
        <Routes>
          <Route path="/" element={<AdminHome />} />
           <Route path="viewcompany/:companyId" element={<ViewCompany />} />
           <Route path="/notifications" element={<NotificationsManager />} />
           <Route path="/countymanager" element={<CountyManager />} />
         {/*  <Route path="settings" element={<AdminSettings />} />  */}
          {/* Add more admin routes as needed */}
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminDashboard;